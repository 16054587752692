import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { hexToRGBA } from '../../../models/UiUtils';
import { DangerIcon } from '../../components/svgs/icons/DangerIcon';
import { destructive, label } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	errorBanner: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
		background: hexToRGBA(destructive, 0.1),
		padding: 14,
		border: `1px solid ${destructive}`,
		borderRadius: 8,
		maxWidth: '96%',
		margin: '0.75rem auto 0',
	},
	errorText: {
		color: label,
		margin: '0 0 0 0.5rem',
		fontSize: 14,
	},
	errorWarning: {
		display: 'flex',
		alignItems: 'center',
	},
});

export const WarningBanner = ({ children }: React.PropsWithChildren<any>) => {
	return <div className={css(styleSheet.errorBanner)}>{children}</div>;
};

export const WarningBannerMessage = ({ children }: React.PropsWithChildren<any>) => {
	return (
		<div className={css(styleSheet.errorWarning)}>
			<DangerIcon />
			<p className={css(styleSheet.errorText)}>{children}</p>
		</div>
	);
};
