import * as React from 'react';
import { useFullscreenModal } from '../../../../../../models/hooks/appStateHooks';
import { SendSchedulerComplete } from '../../../../../components/email/SendSchedulerComplete';
import { MultiContainerHeader } from '../../../../../components/MultiContainerHeader';
import { styleSheet } from './styles';

export const MultiEmailSuggestionsScheduleComplete: React.FC = () => {
	const fullscreenModal = useFullscreenModal();
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='Schedule Campaigns'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<SendSchedulerComplete styles={[styleSheet.emailSuccess]} />
		</>
	);
};
