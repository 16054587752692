import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DroppableProvidedProps } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { Noop } from '../../../../extViewmodels/Utils';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { IEventLoggingComponentProps, useEventLogging, withEventLogging } from '../../../../models/Logging';
import {
	getCampaignCalendarDateRangeValue,
	getDisplayNamePreferFirstNameOverEmail,
	getSocialMediaPlatformLogoUrl,
	getUniqueIdForSuggestion,
} from '../../../../models/UiUtils';
import { useTemplateCategorySocialQuery } from '../../../../queries';
import { ITemplateCard, KnownCategories } from '../../../../viewmodels/AppViewModels';
import { useEmailCampaignBrowserData } from '../../../containers/Campaigns/hooks';
import { CalendarType, CalendarViewGroupOptions } from '../../../containers/ContentCalendar/models';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { GhostCalendarLogoGraphic } from '../../svgs/graphics/GhostCalendarLogoGraphic';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { CampaignCalendarCard } from '../CampaignCalendarEventCard';
import {
	CalendarEventCardIsNotifyClientButton,
	CalendarEventCardPendingDecorator,
	CampaignFooter,
	HeaderDate,
	SuggestionFooter,
} from '../CampaignCalendarEventCard/presentation';
import { useCampaignCalendarSuggestionsContext } from './context';
import {
	EventDataType,
	getSuggestionCampaignType,
	ICalendarTimeSpan,
	ICampaignCalendarEventOptions,
	ICampaignCalendarEventPlaceholder,
	ICampaignCalendarTimeSpanOptions,
	SelectedPostStatuses,
} from './models';
import { styleSheet } from './styles';

export const MAX_DAYS_IN_WEEK = 7;
export const MONTH = 'month';
export const WEEK = 'week';
export const DAY = 'day';
export const MONTH_FORMAT = 'MMMM';
export const DAY_FORMAT = 'dddd';

interface ICampaignCalendarEventProps extends IImpersonationContextComponentProps, IEventLoggingComponentProps {
	campaignOrPlaceholder: Api.CampaignViewModel | ICampaignCalendarEventPlaceholder | Api.CampaignViewModel[];
	calendarTimeSpan?: ICalendarTimeSpan;
	onApproveCampaignClicked?(e: React.MouseEvent<HTMLElement>): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	options?: ICampaignCalendarEventOptions;
	style?: React.CSSProperties;
	userSession?: Api.UserSessionContext;
	onNotifyClientClicked?(e: React.MouseEvent<HTMLElement>): void;
	calendarType?: CalendarType;
}

export const isWeekly = (type: CalendarType) => type === CalendarType.Weekly;

export const renderDateRange = (schedule?: Api.IScheduledSend, completedDate?: string | Date) => {
	return (
		(schedule || completedDate) && (
			<time className={css(styleSheet.calendarEventDate)}>
				{getCampaignCalendarDateRangeValue(schedule?.startDate, completedDate)}
			</time>
		)
	);
};

export const CampaignCalendarEvent = withEventLogging(
	inject(ImpersonationContextKey)(
		observer((props: ICampaignCalendarEventProps) => {
			const h = useHistory();
			const account = props.impersonationContext?.account ?? props.userSession?.account;

			let sender: Api.IContact = null;
			let sendStatus: Api.EmailSendStatus | Api.PostStatus | Api.BlogStatus = Api.EmailSendStatus.Unknown;
			let subject: string = null;
			let dateRangeNode: React.ReactNode = null;
			let scheduleDateString = '';

			if (Array.isArray(props.campaignOrPlaceholder)) {
				const campaigns = props.campaignOrPlaceholder;
				if (campaigns.length === 0) {
					return null;
				}
				sendStatus = campaigns.reduce((prev, campaign) => {
					if (
						prev === Api.EmailSendStatus.Queued ||
						prev === Api.EmailSendStatus.Ready ||
						prev === Api.EmailSendStatus.Sending ||
						prev === Api.EmailSendStatus.WaitingForApproval
					) {
						return prev;
					}

					return campaign.status;
				}, campaigns[0].status);
				sender = campaigns[0].creator;
				const firstCampaign = campaigns[0];
				subject = firstCampaign.templateReference?.name || firstCampaign.subject;
				scheduleDateString = firstCampaign.schedule?.startDate;
			} else {
				const campaign = props.campaignOrPlaceholder;
				sendStatus = campaign.status;
				// FOLLOWUP: Fix up the type with a templateReference
				// @ts-ignore
				subject = campaign.templateReference?.name || campaign.subject;
				dateRangeNode = renderDateRange(campaign.schedule, campaign.completedDate);
				scheduleDateString = campaign.schedule?.startDate;
			}

			if (props.campaignOrPlaceholder instanceof Api.CampaignViewModel) {
				sender = props.campaignOrPlaceholder?.creator;
			}

			const isPersonalAccount = account?.planDetails?.planId === 0;
			const campaignId =
				Object.prototype.hasOwnProperty.call(props.campaignOrPlaceholder, 'id') && !isPersonalAccount
					? (props.campaignOrPlaceholder as Api.CampaignViewModel).id
					: null;
			const onClick = Array.isArray(props.campaignOrPlaceholder)
				? (e: React.MouseEvent<HTMLElement>) => {
						props.onCampaignGroupClicked?.(e, props.campaignOrPlaceholder as Api.CampaignViewModel[]);
					}
				: campaignId
					? (e: React.MouseEvent<HTMLElement>) => {
							if (campaignId) {
								props.onClick?.(e);
								if (e.defaultPrevented) {
									return;
								}
								if (sendStatus === Api.EmailSendStatus.WaitingForApproval) {
									props.onApproveCampaignClicked(e);
								} else {
									h.push(`/reporting/group-email/${campaignId}`);
								}
							}
						}
					: undefined;
			const isPending = sendStatus === Api.EmailSendStatus.WaitingForApproval;
			const isNotifyClient = props?.options?.hideApprovalCtas && sendStatus === Api.EmailSendStatus.WaitingForApproval;
			return (
				<CampaignCalendarCard
					type={CampaignType.Email}
					title={subject}
					onCardClick={onClick}
					calendarType={props.calendarType}
					eventDate={<HeaderDate date={dateRangeNode ?? scheduleDateString} calendarType={props.calendarType} />}
					footer={
						<CampaignFooter
							sendStatus={sendStatus}
							name={getDisplayNamePreferFirstNameOverEmail(sender)}
							calendarType={props.calendarType}
						/>
					}
				>
					{isPending ? (
						<div>
							{!isNotifyClient ? (
								<CalendarEventCardPendingDecorator
									message={`Approve ${props.calendarType === CalendarType.Quarterly ? 'Campaign' : ''}`}
									calendarType={props.calendarType}
								/>
							) : (
								<CalendarEventCardIsNotifyClientButton
									calendarType={props.calendarType}
									message='Notify'
									onNotifyClientClicked={props.onNotifyClientClicked}
								/>
							)}
						</div>
					) : null}
				</CampaignCalendarCard>
			);
		})
	),
	'CampaignCalendarEvent'
);

interface IEmailCalendarEventPlaceholderProps {
	campaignOrPlaceholder: ICampaignCalendarEventPlaceholder<ITemplateCard>;
	isCalendarTypeDay?: boolean;
	subject?: string;
	date?: string;
	calendarType?: CalendarType;
}

export const EmailCalendarEventPlaceholder = observer((props: IEmailCalendarEventPlaceholderProps) => {
	return (
		<>
			<CampaignCalendarCard
				type={CampaignType.Email}
				title={props.subject || props.campaignOrPlaceholder?.dataContext?.name}
				onCardClick={Noop}
				eventDate={<HeaderDate date={props.date} calendarType={props.calendarType} />}
				calendarType={props.calendarType}
				footer={<SuggestionFooter value={false} onClick={Noop} />}
			/>
		</>
	);
});

interface ISocialCalendarProps {
	onSocialClicked?(
		e: React.MouseEvent<HTMLElement>,
		socialMediaPost: Api.SocialMediaPostReportViewModel
	): Promise<any> | void;
	socialMediaPost: Api.SocialMediaPostReportViewModel;
}

interface ISocialCalendarEventProps
	extends ISocialCalendarProps,
		IImpersonationContextComponentProps,
		IEventLoggingComponentProps {
	calendarTimeSpan?: ICalendarTimeSpan;
	campaignOrPlaceholder:
		| Api.SocialMediaPostReportViewModel
		| ICampaignCalendarEventPlaceholder
		| Api.SocialMediaPostReportViewModel[];
	onApproveSocialCampaignClicked?(e: React.MouseEvent<HTMLElement>): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRequestUser?(id: string): Api.UserViewModel | null;
	options?: ICampaignCalendarEventOptions;
	style?: React.CSSProperties;
	userSession?: Api.UserSessionContext;
	onNotifyClientClicked?(e: React.MouseEvent<HTMLElement>): void;
	calendarType?: CalendarType;
}
interface ISocialCalendarEventPlaceholderProps {
	campaignOrPlaceholder: ICampaignCalendarEventPlaceholder;
	isCalendarTypeDay?: boolean;
	subject?: string;
	date?: string;
	calendarType?: CalendarType;
}

export const SocialCalendarEventPlaceholder = observer((props: ISocialCalendarEventPlaceholderProps) => {
	return (
		<CampaignCalendarCard
			type={CampaignType.Social}
			title={props.subject}
			onCardClick={Noop}
			eventDate={<HeaderDate date={props.date} calendarType={props.calendarType} />}
			calendarType={props.calendarType}
			footer={<SuggestionFooter value={false} onClick={Noop} />}
		/>
	);
});

export const SocialCalendarEvent = withEventLogging(
	inject(ImpersonationContextKey)(
		observer((props: ISocialCalendarEventProps) => {
			const { socialMediaPost, onSocialClicked, calendarType } = props;
			const onAddSuggestionToCalendarClicked = React.useCallback(
				async (e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();
					await onSocialClicked(e, socialMediaPost);
				},
				[onSocialClicked, socialMediaPost]
			);
			const isPending = socialMediaPost.status === Api.PostStatus.Pending;
			const isNotifyClient = props?.options?.hideApprovalCtas && socialMediaPost.status === Api.PostStatus.Pending;
			let nonDuplicateTargets =
				socialMediaPost.toJs()?.designatedTargets?.reduce<Api.IPostTarget[]>((acc, target) => {
					if (acc.some(t => t.provider === target.provider)) {
						return acc;
					}
					return [...acc, target];
				}, []) || [];
			/**
			 * @NOTE Older posts may not have designated targets
			 */
			if (!nonDuplicateTargets.length) {
				nonDuplicateTargets = socialMediaPost.toJs()?.targets || [];
			}

			const designatedTargets = [...nonDuplicateTargets];

			return SelectedPostStatuses.includes(socialMediaPost.status) ? (
				<CampaignCalendarCard
					type={CampaignType.Social}
					title={socialMediaPost.name}
					onCardClick={onAddSuggestionToCalendarClicked}
					calendarType={calendarType}
					eventDate={<HeaderDate date={socialMediaPost.dueDate} calendarType={calendarType} />}
					footer={
						<CampaignFooter
							sendStatus={socialMediaPost.status}
							name={getDisplayNamePreferFirstNameOverEmail(socialMediaPost.creator)}
							calendarType={props.calendarType}
						/>
					}
				>
					<>
						{calendarType !== CalendarType.Month ? (
							<>
								{designatedTargets?.map((t, i) => {
									const platform = t.provider;

									return (
										<img
											key={`provider-${t.provider}-${i}`}
											src={getSocialMediaPlatformLogoUrl(platform)}
											alt={t.provider}
											className={css(styleSheet.socialMediaIcon)}
										/>
									);
								})}
							</>
						) : null}
					</>
					{isPending ? (
						<div>
							{!isNotifyClient ? (
								<CalendarEventCardPendingDecorator
									message={`Approve ${calendarType === CalendarType.Quarterly ? 'Campaign' : ''}`}
									calendarType={calendarType}
								/>
							) : (
								<CalendarEventCardIsNotifyClientButton
									calendarType={calendarType}
									message='Notify'
									onNotifyClientClicked={props.onNotifyClientClicked}
								/>
							)}
						</div>
					) : null}
				</CampaignCalendarCard>
			) : null;
		})
	),
	'SocialMediaCalendarEvent'
);

interface IBlogCalendarEventPlaceholderProps {
	campaignOrPlaceholder: ICampaignCalendarEventPlaceholder<ITemplateCard>;
	isCalendarTypeDay?: boolean;
	subject?: string;
	date?: string;
	calendarType?: CalendarType;
}

export const BlogCalendarEventPlaceholder = observer((props: IBlogCalendarEventPlaceholderProps) => {
	return (
		<CampaignCalendarCard
			type={CampaignType.Blog}
			title={props.subject || props.campaignOrPlaceholder?.dataContext?.name}
			onCardClick={Noop}
			eventDate={<HeaderDate date={props.date} calendarType={props.calendarType} />}
			calendarType={props.calendarType}
			footer={<SuggestionFooter value={false} onClick={Noop} />}
		/>
	);
});

interface IBlogCalendarEventProps extends IImpersonationContextComponentProps, IEventLoggingComponentProps {
	blogReportView?: Api.IBlogReportView;
	placeholder?: ICampaignCalendarEventPlaceholder;
	calendarTimeSpan: ICalendarTimeSpan;
	onApproveClicked?(e: React.MouseEvent<HTMLElement>): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	options?: ICampaignCalendarEventOptions;
	onNotifyClientClicked?(e: React.MouseEvent<HTMLElement>): void;
	calendarType?: CalendarType;
}

export const BlogCalendarEvent = inject(ImpersonationContextKey)(
	({
		blogReportView,
		options,
		onClick,
		calendarType,
		onNotifyClientClicked,
		onApproveClicked,
		impersonationContext,
	}: IBlogCalendarEventProps) => {
		useEventLogging('BlogCalendarEvent');
		const userSession = useUserSession();
		const title = blogReportView?.title;
		const account = impersonationContext?.account ?? userSession?.account;

		const sendStatus: Api.BlogStatus = blogReportView?.status;
		const handleClick = (e: React.MouseEvent<HTMLElement>) => {
			if (account?.preferences?.complianceSettings?.enabled) {
				onApproveClicked(e);
				return;
			}
			if (onClick) {
				onClick(e);
			}
		};

		const isPending = sendStatus === Api.BlogStatus.Pending;
		const isNotifyClient = options?.hideApprovalCtas && sendStatus === Api.BlogStatus.Pending;
		return (
			<>
				<CampaignCalendarCard
					type={CampaignType.Blog}
					title={title}
					onCardClick={handleClick}
					eventDate={<HeaderDate date={blogReportView.scheduledSendDate} calendarType={calendarType} />}
					calendarType={calendarType}
					footer={
						<CampaignFooter
							sendStatus={blogReportView.status}
							name={getDisplayNamePreferFirstNameOverEmail(blogReportView.creator)}
							calendarType={calendarType}
						/>
					}
				>
					{isPending ? (
						<div>
							{!isNotifyClient ? (
								<CalendarEventCardPendingDecorator
									message={`Approve ${calendarType === CalendarType.Quarterly ? 'Blog post' : ''}`}
									calendarType={calendarType}
								/>
							) : (
								<CalendarEventCardIsNotifyClientButton
									calendarType={calendarType}
									message='Notify'
									onNotifyClientClicked={onNotifyClientClicked}
								/>
							)}
						</div>
					) : null}
				</CampaignCalendarCard>
			</>
		);
	}
);

const getStartDateFromEvent = (event: EventDataType) => {
	let result: moment.MomentInput = null;
	if (event instanceof Api.SocialMediaPostReportViewModel) {
		result = event.dueDate;
	} else if (event instanceof Api.CampaignViewModel) {
		result = event.schedule?.startDate;
	} else if (Array.isArray(event)) {
		result = event[0].schedule?.startDate;
	} else if (event._type === 'BlogReportView') {
		const reportView: Api.IBlogReportView = event as Api.IBlogReportView;
		result = reportView.scheduledSendDate;
	}
	return result;
};

export const Suggestions = ({
	suggestions,
	calendarType,
	isFeatured,
	suggestionClicked,
}: {
	suggestions: Api.IContentCalendarSuggestion[];
	calendarType: CalendarType;
	isFeatured?: boolean;
	suggestionClicked: (suggestion: Api.IContentCalendarSuggestion) => (e: React.MouseEvent<HTMLElement>) => Promise<any>;
}) => {
	const { hasSelectedSuggestion, supportsBulkScheduleSuggestions, deselectSuggestion, selectSuggestion } =
		useCampaignCalendarSuggestionsContext();

	return (
		<>
			{suggestions.map(suggestion => {
				const id = getUniqueIdForSuggestion(suggestion);
				const type = getSuggestionCampaignType(suggestion);
				const selected = hasSelectedSuggestion(suggestion);
				const startDate: string = suggestion.schedule.startDate;
				return (
					<CampaignCalendarCard
						key={`suggested-${id}`}
						type={type}
						title={suggestion.templateReference.name}
						onCardClick={suggestionClicked(suggestion)}
						calendarType={calendarType}
						eventDate={<HeaderDate date={startDate} calendarType={calendarType} />}
						footer={
							<SuggestionFooter
								canSelect={supportsBulkScheduleSuggestions}
								value={selected}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									if (selected) {
										deselectSuggestion(suggestion);
									} else {
										selectSuggestion(suggestion);
									}
								}}
							/>
						}
						isSuggestion
						isFeatured={isFeatured}
					>
						<>
							{type === CampaignType.Social && calendarType !== CalendarType.Month ? (
								<>
									{suggestion.targets?.map(targetType => {
										return (
											<img
												key={`${targetType}type`}
												src={getSocialMediaPlatformLogoUrl(targetType)}
												alt={targetType}
												className={css(styleSheet.socialMediaIcon)}
											/>
										);
									})}
								</>
							) : null}
						</>
					</CampaignCalendarCard>
				);
			})}
		</>
	);
};

export const CampaignCalendarTimeSpan: React.FunctionComponent<{
	calendarType: CalendarType;
	droppableProps: DroppableProvidedProps;
	hideCampaigns?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	isDraggingOver?: boolean;
	calendarTimeSpan: ICalendarTimeSpan;
	onApproveCampaignClicked?(campaign: Api.CampaignViewModel): void;
	onApproveSocialCampaignClicked?: (campaign: Api.SocialMediaPostReportViewModel) => void;
	onCampaignClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onBlogPostClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.IBlogReportView): void;
	onInnerRef?(element: HTMLElement | null): any;
	onNotifyClientClicked?(campaign: EventDataType): void;
	onRenderSuggestion?(suggestion: Api.IContentCalendarSuggestion): React.ReactNode;
	onRequestUser?(id: string): Api.UserViewModel | null;
	onSocialMediaEventClicked?(
		e: React.MouseEvent<HTMLElement>,
		social: Api.SocialMediaPostReportViewModel
	): Promise<any>;
	onSuggestedSocialMediaEventClicked?(
		e: React.MouseEvent<Element>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
	onSuggestionClicked?(e: React.MouseEvent<HTMLElement>, suggestion: Api.IContentCalendarSuggestion): Promise<any>;
	options?: ICampaignCalendarTimeSpanOptions;
	placeholders?: ICampaignCalendarEventPlaceholder[];
	socialMediaPosts?: Api.SocialMediaPostReportViewModel[];
	style?: React.CSSProperties;
	suggestions?: Api.IContentCalendarSuggestion[];
	userSession?: Api.UserSessionContext;
	styleSheet?: any;
	index?: number;
}> = inject(ImpersonationContextKey)(
	observer(props => {
		const { onSuggestionClicked, onSuggestedSocialMediaEventClicked } = props;
		const { dragDropSelectedSuggestions, getStartDateForSuggestion, supportsBulkScheduleSuggestions } =
			useCampaignCalendarSuggestionsContext();
		const onApproveCampaignClicked = (campaign: Api.CampaignViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			props.onApproveCampaignClicked?.(campaign);
		};
		const onApproveSocialCampaignClicked =
			(campaign: Api.SocialMediaPostReportViewModel) => (e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
				props.onApproveSocialCampaignClicked?.(campaign);
			};

		const onCampaignClicked = (campaign: Api.CampaignViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			props.onCampaignClicked?.(e, campaign);
		};
		const onBlogPostClicked = (campaign: Api.IBlogReportView) => (e: React.MouseEvent<HTMLElement>) => {
			props.onBlogPostClicked?.(e, campaign);
		};

		const onNotifyClientClicked = (campaign: EventDataType) => (e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			props.onNotifyClientClicked?.(campaign);
		};

		const account = props.impersonationContext?.account || props.userSession?.account;

		const featuredQuerySocial = useTemplateCategorySocialQuery({
			categoryName: KnownCategories.Featured,
			enabled: true,
			industry: account?.additionalInfo.industry,
		});

		const { featuredQuery: featuredQueryEmail } = useEmailCampaignBrowserData(account?.additionalInfo.industry);

		// TODO: missing blog featured query

		const { featuredSuggestions, suggestions } = React.useMemo(() => {
			const featured: Api.IContentCalendarSuggestion[] = [];
			const regular: Api.IContentCalendarSuggestion[] = [];

			const allFeaturedIds = new Set<string>(
				(featuredQueryEmail?.data || []).concat(featuredQuerySocial?.data || []).map(x => x.id)
			);

			props.suggestions?.forEach(el => {
				(allFeaturedIds.has(el.templateReference?.templateId) ? featured : regular).push(el);
			});

			// include suggestions that were dragged onto the calendar
			const createdSuggestionsInTimeSpan = dragDropSelectedSuggestions?.filter(x => {
				const startDate = getStartDateForSuggestion(x);
				return props.calendarTimeSpan.moment.isSame(startDate, props.calendarTimeSpan.unitOfTime);
			});
			if (createdSuggestionsInTimeSpan?.length) {
				regular.push(...createdSuggestionsInTimeSpan);
			}

			return { featuredSuggestions: featured, suggestions: regular } as const;
		}, [
			getStartDateForSuggestion,
			featuredQueryEmail?.data,
			featuredQuerySocial?.data,
			props.suggestions,
			props.calendarTimeSpan.moment,
			props.calendarTimeSpan.unitOfTime,
			dragDropSelectedSuggestions,
		]);

		const calendarEventItems: EventDataType[] = React.useMemo(() => {
			let items: EventDataType[] = (props?.calendarTimeSpan.campaigns || []).filter(x => {
				if (x.status === Api.EmailSendStatus.Cancelled) {
					return false;
				}
				if (props.calendarTimeSpan?.moment && x.schedule?.startDate) {
					if (!props.calendarTimeSpan.moment.isSame(x.schedule.startDate, props.calendarTimeSpan.unitOfTime)) {
						return false;
					}
				}
				return true;
			});
			const socialItems: EventDataType[] = (props?.calendarTimeSpan?.socialCampaigns || []).filter(x => {
				if (props.calendarTimeSpan?.moment && x.dueDate) {
					if (!props.calendarTimeSpan.moment.isSame(x.dueDate, props.calendarTimeSpan.unitOfTime)) {
						return false;
					}
				}
				return true;
			});

			const blogItems: Api.IBlogReportView[] = (props?.calendarTimeSpan?.blogReports || []).filter(x => {
				if (props.calendarTimeSpan?.moment && x.scheduledSendDate) {
					if (!props.calendarTimeSpan.moment.isSame(x.scheduledSendDate, props.calendarTimeSpan.unitOfTime)) {
						return false;
					}
				}
				return true;
			});
			items = [...items, ...socialItems, ...blogItems];
			items.sort((a, b) => {
				const aTime = getStartDateFromEvent(a);
				const bTime = getStartDateFromEvent(b);
				return moment(aTime).isBefore(bTime) ? -1 : 1;
			});
			if (props.options?.groupEventsByGroupId) {
				items = items.reduce<EventDataType[]>((res, x) => {
					if (!Array.isArray(x) && x instanceof Api.CampaignViewModel) {
						const groupedCampaigns = props.calendarTimeSpan.campaignsByGroupId?.[x.groupId];
						if (groupedCampaigns?.length > 0) {
							if (groupedCampaigns?.length === 1) {
								res.push(groupedCampaigns[0]);
							} else {
								if (res.indexOf(groupedCampaigns) < 0) {
									res.push(groupedCampaigns);
								}
							}
						} else {
							res.push(x);
						}
						return res;
					}

					res.push(x);
					return res;
				}, []);
			}
			return items;
		}, [
			props.calendarTimeSpan?.blogReports,
			props.calendarTimeSpan?.campaigns,
			props.calendarTimeSpan?.campaignsByGroupId,
			props.calendarTimeSpan?.moment,
			props.calendarTimeSpan?.socialCampaigns,
			props.calendarTimeSpan?.unitOfTime,
			props.options?.groupEventsByGroupId,
		]);

		const suggestionClicked = React.useCallback(
			(suggestion: Api.IContentCalendarSuggestion) => (e: React.MouseEvent<HTMLElement>) => {
				return suggestion?.targets?.length > 0
					? onSuggestedSocialMediaEventClicked?.(e, suggestion)
					: onSuggestionClicked?.(e, suggestion);
			},
			[onSuggestedSocialMediaEventClicked, onSuggestionClicked]
		);

		const isTodayOrActiveMonth =
			(isWeekly(props.calendarType) && props.calendarTimeSpan.moment.isSame(moment(), DAY)) ||
			(props.calendarType === CalendarType.Quarterly && props.calendarTimeSpan.moment.isSame(moment(), MONTH));

		const getTitleStyles = () => {
			const styles = [styleSheet.calendarMonthTitleText];
			if (props.calendarType === CalendarType.Quarterly) {
				styles.push(styleSheet.quarterTitle);

				if (props.calendarTimeSpan.moment.isBefore(moment(), MONTH)) {
					styles.push(styleSheet.pastQuarterTitle);
				}
			} else if (props.calendarType === CalendarType.Month) {
				styles.push(styleSheet.dayOfTheMonth);
				if (props.calendarTimeSpan.moment.isSame(moment(), DAY)) {
					styles.push(styleSheet.today);
				}
			}

			if (isWeekly(props.calendarType) && props.calendarTimeSpan.moment.isBefore(moment(), DAY)) {
				styles.push(styleSheet.pastWeekTitle);
			}
			if (isTodayOrActiveMonth) {
				styles.push(styleSheet.activeMonthOrDay);
			}
			return styles;
		};

		return (
			<div
				className={css(
					styleSheet.calendarMonth,
					props.calendarType === CalendarType.Month ? styleSheet.calendarMonthDay : undefined,
					props.calendarType === CalendarType.Month && props.calendarTimeSpan.moment.isSame(moment(), DAY)
						? styleSheet.calendarMonthDayToday
						: undefined,
					props.calendarType === CalendarType.Quarterly ? styleSheet.calendarMonthQuarterly : null,
					isWeekly(props.calendarType) ? styleSheet.calendarMonthWeekDay : null
				)}
				ref={props.onInnerRef}
				style={props?.style}
				{...props.droppableProps}
			>
				<div
					className={css(
						styleSheet.calendarMonthTitle,
						props.calendarType === CalendarType.Month && styleSheet.calendarMonthTitleDay,
						isWeekly(props.calendarType) ? styleSheet.calendarMonthWeekTitleDay : null
					)}
				>
					<time dateTime={`${props.calendarTimeSpan.title}`} className={css(getTitleStyles())}>
						{props.calendarTimeSpan.title || ''}
					</time>
				</div>
				<div
					className={`${css(
						styleSheet.calendarMonthEvents,
						props.calendarType === CalendarType.Quarterly ? styleSheet.calendarMonthQuarterlyEvents : undefined,
						props.calendarType === CalendarType.Quarterly && supportsBulkScheduleSuggestions
							? styleSheet.calendarMonthQuarterlyEventsWithSuggestions
							: undefined,
						isWeekly(props.calendarType) ? styleSheet.calendarMonthWeeklyEvents : undefined,
						isTodayOrActiveMonth ? styleSheet.calendarEventsInnerToday : undefined,
						(props.index === 0 && props.calendarType === CalendarType.Quarterly) ||
							(props.index === 0 && isWeekly(props.calendarType))
							? styleSheet.calendarMonthEventsFirst
							: null,
						(props.index === 2 && props.calendarType === CalendarType.Quarterly) ||
							(props.index === MAX_DAYS_IN_WEEK - 1 && isWeekly(props.calendarType))
							? styleSheet.calendarMonthEventsLast
							: null
					)}`}
				>
					{isWeekly(props.calendarType) ? (
						<div className={css(styleSheet.isWeeklyDayDate)}>
							<time
								dateTime={`${props.calendarTimeSpan.moment.format('dddd Do')}`}
								className={css(
									styleSheet.dayOfTheMonth,
									props.calendarTimeSpan.moment.isBefore(moment(), DAY) && styleSheet.pastWeekTitle,
									props.calendarTimeSpan.moment.isSame(moment(), DAY) && styleSheet.today
								)}
							>
								{`${props.calendarTimeSpan.moment.format('DD')}`}
							</time>
						</div>
					) : null}
					<div>
						{props.onRenderSuggestion ? (
							props.suggestions.map(x => {
								return props.onRenderSuggestion(x);
							})
						) : (
							<>
								{featuredSuggestions?.length > 0 ? (
									<>
										<Suggestions
											key='featured-suggestions'
											suggestions={featuredSuggestions}
											calendarType={props.calendarType}
											suggestionClicked={suggestionClicked}
											isFeatured
										/>
										<div
											className={css(
												styleSheet.notFeaturedSection,
												props.calendarType === CalendarType.Month && baseStyleSheet.hidden
											)}
										/>
									</>
								) : null}
								<Suggestions
									key='regular-suggestions'
									suggestions={suggestions}
									calendarType={props.calendarType}
									suggestionClicked={suggestionClicked}
								/>
							</>
						)}
					</div>
					{props.placeholders?.length > 0 &&
						props.placeholders.map((placeholder, i) => {
							const key = `${placeholder.moment?.toISOString() ?? ''}-${placeholder.status ?? ''}-${placeholder.subject || ''}-${i}`;
							return placeholder.campaignType === CampaignType.Email || !placeholder.campaignType ? (
								<EmailCalendarEventPlaceholder
									campaignOrPlaceholder={placeholder}
									isCalendarTypeDay={props.calendarType === CalendarType.Month}
									key={`email-${key}`}
									calendarType={props.calendarType}
								/>
							) : placeholder.campaignType === CampaignType.Blog ? (
								<BlogCalendarEventPlaceholder
									campaignOrPlaceholder={placeholder}
									isCalendarTypeDay={props.calendarType === CalendarType.Month}
									key={`blog-${key}`}
									calendarType={props.calendarType}
								/>
							) : (
								<SocialCalendarEventPlaceholder
									campaignOrPlaceholder={placeholder}
									isCalendarTypeDay={props.calendarType === CalendarType.Month}
									key={`social-media-${key}`}
									calendarType={props.calendarType}
									subject={placeholder.subject}
								/>
							);
						})}
					{!props.hideCampaigns ? (
						<>
							{calendarEventItems.map((calendarEventItem, i) => {
								if (calendarEventItem instanceof Api.SocialMediaPostReportViewModel) {
									const isSocialMediaEvent =
										calendarEventItem.status === Api.PostStatus.Succeeded ||
										calendarEventItem.status === Api.PostStatus.PartiallySucceeded ||
										calendarEventItem.status === Api.PostStatus.Started ||
										calendarEventItem.status === Api.PostStatus.Scheduled ||
										calendarEventItem.status === Api.PostStatus.Pending;
									return isSocialMediaEvent ? (
										<SocialCalendarEvent
											key={`social-media-post-report-${calendarEventItem.id}`}
											onSocialClicked={props.onSocialMediaEventClicked}
											socialMediaPost={calendarEventItem}
											onNotifyClientClicked={onNotifyClientClicked(calendarEventItem)}
											options={props.options?.eventOptions}
											onApproveSocialCampaignClicked={onApproveSocialCampaignClicked(calendarEventItem)}
											campaignOrPlaceholder={calendarEventItem}
											calendarTimeSpan={props.calendarTimeSpan}
											calendarType={props.calendarType}
										/>
									) : null;
								} else if (calendarEventItem instanceof Api.CampaignViewModel || Array.isArray(calendarEventItem)) {
									return (
										<CampaignCalendarEvent
											key={`campaign-${
												Array.isArray(calendarEventItem) ? calendarEventItem[0].groupId : calendarEventItem.id
											}-${i}`}
											campaignOrPlaceholder={calendarEventItem}
											calendarTimeSpan={props.calendarTimeSpan}
											onApproveCampaignClicked={
												Array.isArray(calendarEventItem) ? undefined : onApproveCampaignClicked(calendarEventItem)
											}
											onCampaignGroupClicked={props.onCampaignGroupClicked}
											onClick={Array.isArray(calendarEventItem) ? undefined : onCampaignClicked(calendarEventItem)}
											onNotifyClientClicked={onNotifyClientClicked(calendarEventItem)}
											options={props.options?.eventOptions}
											userSession={props.userSession}
											calendarType={props.calendarType}
										/>
									);
								} else if (calendarEventItem._type === 'BlogReportView') {
									return (
										<BlogCalendarEvent
											blogReportView={calendarEventItem}
											calendarTimeSpan={props.calendarTimeSpan}
											key={`blog-${calendarEventItem.id}-${i}`}
											onNotifyClientClicked={onNotifyClientClicked(calendarEventItem)}
											options={props.options?.eventOptions}
											onClick={onBlogPostClicked(calendarEventItem)}
											onApproveClicked={onBlogPostClicked(calendarEventItem)}
											calendarType={props.calendarType}
										/>
									);
								}

								return null;
							})}
						</>
					) : null}
				</div>
				{props.isDraggingOver ? <div className={css(styleSheet.calendarMonthDropTarget)} /> : null}
			</div>
		);
	})
);

interface ICampaignCalendarTitleBarProps {
	calendarType: CalendarType;
	onCalendarTypeChanged(selectedCalendarType: CalendarType): void;
	canGoBack?: boolean;
	canGoForward?: boolean;
	end: ICalendarTimeSpan | null;
	goBack(): Promise<any>;
	goForward(): Promise<any>;
	onRenderHeaderAccessory?(): React.ReactNode;
	showGhost?: boolean;
	start: ICalendarTimeSpan;
}

export const CampaignCalendarTitleBar = ({
	goForward,
	goBack,
	onRenderHeaderAccessory,
	end,
	showGhost,
	start,
	calendarType,
	onCalendarTypeChanged,
}: ICampaignCalendarTitleBarProps) => {
	const [disabled, setDisabled] = React.useState(false);
	const enable = () => setDisabled(false);
	const go = (forward: boolean) => () => {
		(forward ? goForward : goBack)().then(enable)?.catch(enable);
	};
	const accessory = onRenderHeaderAccessory?.();
	const getCalendarTitle = () => {
		return isWeekly(calendarType)
			? `${start.moment.format('MMM Do')} \u2014 ${end?.moment.format(moment(start.moment).isSame(end.moment, 'month') ? 'Do YYYY' : 'MMM Do YYYY')}`
			: calendarType === CalendarType.Month
				? `${start.title} \u2014 ${end?.title} ${end?.moment.format('YYYY')}`
				: `${start.moment.format('MMM')} \u2014 ${end?.moment.format('MMM YYYY')}`;
	};
	return (
		<div className={css(styleSheet.CampaignCalendarTitleBar)}>
			<div className={css(styleSheet.leftSection)}>
				<div className={css(styleSheet.calendarTypeContainer)}>
					{showGhost ? (
						<figure>
							<GhostCalendarLogoGraphic className={css(styleSheet.calendarGhostIcon)} />
						</figure>
					) : null}

					<menu className={css(styleSheet.calendarMenu)}>
						{CalendarViewGroupOptions.map(selectOption => {
							return (
								<li key={selectOption.id}>
									<button
										className={css(
											styleSheet.calendarOption,
											calendarType === selectOption.dataContext ? styleSheet.activeCalendarOption : undefined
										)}
										aria-label={selectOption.text}
										onClick={() => onCalendarTypeChanged(selectOption.dataContext)}
									>
										<span>{selectOption.text}</span>
									</button>
								</li>
							);
						})}
					</menu>
				</div>

				<div
					className={css(
						styleSheet.calendarTitleBar,
						isWeekly(calendarType) && styleSheet.calendarTitleBarWeekly,
						showGhost && styleSheet.calendarTitleBarGhost
					)}
				>
					<div
						className={css(
							styleSheet.calendarTitleBarContent,
							!accessory ? styleSheet.calendarTitleBarContentNoAccessory : null
						)}
					>
						<button
							disabled={disabled}
							onClick={go(false)}
							className={css(bs.relative, styleSheet.buttonChevron, styleSheet.buttonChevronLeft)}
						>
							<div tabIndex={-1} className={css(bs.absolute, styleSheet.calendarTitleBarIconWrap)}>
								<DisclosureIcon
									className={css(styleSheet.calendarTitleBarBackIcon)}
									fillColor={brandPrimary}
									type='chevron'
								/>
							</div>
							Previous {calendarType}
						</button>
						<div className={css(bs.horizontalStack)}>
							<div className={css(styleSheet.timeWrap)}>
								{!end ? (
									<time dateTime={`${start.title}, ${start.moment.format('YYYY')}`}>
										{`${start.title} ${start.moment.format('YYYY')}`}
									</time>
								) : (
									<time dateTime={`${start.title} \u2014 ${end?.title}, ${end?.moment.format('YYYY')}`}>
										{getCalendarTitle()}
									</time>
								)}
							</div>
						</div>
						<button
							disabled={disabled}
							onClick={go(true)}
							className={css(bs.relative, styleSheet.buttonChevron, styleSheet.buttonChevronRight)}
						>
							<div tabIndex={-1} className={css(bs.absolute, styleSheet.calendarTitleBarIconWrap)}>
								<DisclosureIcon
									className={css(styleSheet.calendarTitleBarForwardIcon)}
									fillColor={brandPrimary}
									type='chevron'
								/>
							</div>
							Next {calendarType}
						</button>
					</div>
				</div>
			</div>
			<div className={css(styleSheet.rightSection)}>{accessory}</div>
		</div>
	);
};
