import { css } from 'aphrodite';
import * as React from 'react';
import { CampaignType } from '../../../../models/AdminModels';
import { EDuplicateType } from '../../../../models/Campaigns';
import { Checkbox } from '../../Checkbox';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

const isDuplicateConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isCta: true,
		title: 'Yes, Continue',
	},
	{
		isCancel: true,
		title: 'Cancel',
	},
];

export interface IDuplicateWarningDialogProps {
	isOpen: boolean;
	onRequestClose: (suppress?: boolean, cancel?: boolean) => void;
	title: string;
	type: CampaignType;
	duplicateType: EDuplicateType;
}

export const DuplicateWarningDialog = ({
	isOpen,
	onRequestClose,
	title,
	type,
	duplicateType,
}: IDuplicateWarningDialogProps) => {
	const suppressCheckboxRef = React.useRef<HTMLInputElement>(null);
	let campaignTypeDisplayName = 'template';
	switch (type) {
		case CampaignType.Social:
		case CampaignType.Blog: {
			campaignTypeDisplayName = 'post';
			break;
		}
		case CampaignType.Email: {
			campaignTypeDisplayName = 'campaign';
			break;
		}
		default: {
			break;
		}
	}
	return (
		<ConfirmationDialog
			icon={<WarningIcon />}
			modalProps={{
				isOpen,
				onRequestClose: (_, canceled) => {
					const suppress = suppressCheckboxRef.current?.checked;
					onRequestClose(suppress, canceled);
				},
			}}
			className={css(styleSheet.container)}
			options={isDuplicateConfirmationOptions}
			title={title}
			footer={
				<div style={{ marginTop: 40, maxWidth: 300, width: '100%' }}>
					<Checkbox ref={suppressCheckboxRef} id='reminder' defaultChecked={false}>
						<div style={{ maxWidth: 300, width: '100%' }}>Don&apos;t remind me about this template again</div>
					</Checkbox>
				</div>
			}
		>
			<div className={css(styleSheet.content)}>
				<p>
					{`${duplicateType === EDuplicateType.User ? "You've already used this" : 'Someone on your account has already used this'} ${campaignTypeDisplayName}.`}
				</p>
				<p>Are you sure you want to continue?</p>
			</div>
		</ConfirmationDialog>
	);
};
