import { StyleDeclarationValue, css } from 'aphrodite';
import { Observer, observer } from 'mobx-react';
import * as React from 'react';
import EmailEditor, { Design, DesignContentUpdate } from 'react-email-editor';
import { Spring, animated } from 'react-spring-legacy/renderprops';
import { HtmlNewsletterEditor } from '.';
import { isIE11 } from '../../../models/Browser';
import { useEventLogging } from '../../../models/Logging';
import { useFileInput } from '../../../models/hooks/inputHooks';
import { useToggle } from '../../../models/hooks/useToggle';
import { IOperationResultNoValue, ITemplate, asApiError } from '../../../viewmodels/AppViewModels';
import { HtmlNewsletter } from '../../../viewmodels/HtmlNewletterViewModels';
import { baseStyleSheet } from '../../styles/styles';
import { Checkbox } from '../Checkbox';
import { HtmlNewsletterPreview } from '../HtmlNewsletterPreview';
import { TextInput } from '../TextInput';
import { PopoverType, TinyPopover } from '../TinyPopover';
import { EditItemPenIcon } from '../svgs/icons/EditItemPenIcon';
import { PaperPlaneIcon } from '../svgs/icons/PaperPlaneIcon';
import { styleSheet } from './styles';

export interface IHtmlNewsletterEditorTabsContext {
	newsletter?: HtmlNewsletter;
	showPreview?(show: boolean): void;
}

export interface IHtmlNewsletterEditorBodyContext {
	initialDesign?: Design;
	newsletter?: HtmlNewsletter;
	onEditorLoaded?(editor?: EmailEditor): void;
}

export interface IHtmlNewsletterEditorHeaderContext {
	newsletter?: HtmlNewsletter;
	onEditNameClicked?(e: React.MouseEvent<HTMLElement>): void;
	onSaveAsNewTemplateClicked?(e: React.MouseEvent<HTMLElement>): void;
	onSaveClicked(e: React.MouseEvent<HTMLElement>): void;
	onAutoSave(): Promise<ITemplate | null>;
	onSendClicked?(e: React.MouseEvent<HTMLElement>): void;
	onSendTestEmailClicked?(e: React.MouseEvent<HTMLElement>): void;
	saveDisabled?: boolean;
	autoSaveEnabled?: boolean;
	toggleAutoSave(state: boolean): void;
	subjectInput?: string;
	setSubjectInput(subject: string): void;
}

export const HtmlNewsletterEditorHeaderContext = React.createContext<IHtmlNewsletterEditorHeaderContext>({
	toggleAutoSave: () => {
		console.error('[Context Undefined Error]: toggleAutoSave must be defined');
	},
	onAutoSave: () => {
		console.error('[Context Undefined Error]: onAutoSave must be defined');
		return null;
	},
	onSaveClicked: () => {
		console.error('[Context Undefined Error]: onSaveClicked must be defined');
	},
	setSubjectInput: () => {
		console.error('[Context Undefined Error]: setSubjectInput must be defined');
	},
});
export const HtmlNewsletterEditorBodyContext = React.createContext<IHtmlNewsletterEditorBodyContext>({});

export interface IHtmlNewsletterEditorHeaderProps {
	styles?: StyleDeclarationValue[];
	isEditModal?: boolean;
}

export interface IHtmlNewsletterEditorBodyProps {
	style?: React.CSSProperties;
}

export const HtmlNewsletterEditorSaveButton: React.FC<
	{ styles?: StyleDeclarationValue[] } & React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
> = props => {
	const context = React.useContext(HtmlNewsletterEditorHeaderContext);
	const { styles = [], ...restProps } = props;
	if (!context.newsletter?.canEdit) {
		return null;
	}
	return (
		<button
			{...restProps}
			className={`${props.className || ''} ${css(baseStyleSheet.ctaButton, styles)}`}
			disabled={props.disabled || context?.saveDisabled || context?.autoSaveEnabled}
			onClick={props.onClick || context?.onSaveClicked}
		>
			<span>Save</span>
		</button>
	);
};

export const HtmlNewsletterEditorSaveAsNewButton: React.FC<
	{ styles?: StyleDeclarationValue[] } & React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
> = props => {
	const context = React.useContext(HtmlNewsletterEditorHeaderContext);
	const { styles = [], ...restProps } = props;
	return (
		<button
			{...restProps}
			className={`${props.className || ''} ${css(baseStyleSheet.ctaButton, styles)}`}
			disabled={props.disabled || context?.saveDisabled}
			onClick={props.onClick || context?.onSaveAsNewTemplateClicked}
		>
			<span>Save as New Template</span>
		</button>
	);
};

export const HtmlNewsletterEditorAutoSaveCheckbox: React.FC = () => {
	const { toggleAutoSave, autoSaveEnabled, saveDisabled } = React.useContext(HtmlNewsletterEditorHeaderContext);
	return (
		<Checkbox
			disabled={saveDisabled}
			checked={autoSaveEnabled}
			id='enable-html-autosave'
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => toggleAutoSave(e.target.checked)}
		>
			<div className={css(styleSheet.checkboxText)}>Autosave</div>
		</Checkbox>
	);
};

export const HtmlNewsletterEditorSubjectInput: React.FC = () => {
	const { subjectInput, setSubjectInput, newsletter } = React.useContext(HtmlNewsletterEditorHeaderContext);
	const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		setSubjectInput(value);
	};
	const label = (
		<span>
			Subject:
			<span className={css(baseStyleSheet.required)}>*</span>
			&nbsp;
		</span>
	);
	return (
		<div className={css(styleSheet.htmlNewsletterEditorSubjectHeader)}>
			<TextInput
				disabled={!newsletter.canEdit}
				inputId='html-newslettter-editor-subject'
				leftAccessory={label}
				onChange={onChange}
				type='text'
				value={subjectInput || ''}
			/>
		</div>
	);
};

export const HtmlNewsletterEditorSendButton: React.FC<
	{ styles?: StyleDeclarationValue[] } & React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
> = props => {
	const context = React.useContext(HtmlNewsletterEditorHeaderContext);
	const { styles = [], ...restProps } = props;
	const info = 'Save your template in order to send.';
	const disabled =
		context?.saveDisabled ||
		props.disabled ||
		(!!context.newsletter?.name && context.newsletter.name === 'Untitled Newsletter');
	const [isOpen, , setOpen] = useToggle(false);
	const sendNewsletterButton = (
		<button
			{...restProps}
			className={`${props.className || ''} ${css(styleSheet.sendButton, styles)}`}
			disabled={disabled}
			onClick={props.onClick || context?.onSendClicked}
		>
			<span>Send out HTML Newsletter</span>
		</button>
	);
	return (
		<div>
			{disabled ? (
				<div className={`${css(baseStyleSheet.truncateText)}`} onMouseEnter={setOpen(true)} onMouseOut={setOpen(false)}>
					<TinyPopover
						anchor={sendNewsletterButton}
						isOpen={isOpen}
						dismissOnOutsideAction={true}
						onRequestClose={setOpen(false)}
						align='start'
						placement={['top']}
						type={PopoverType.lightBlue}
						contentStyles={[styleSheet.infoMessageContainer]}
					>
						<div className={css(styleSheet.infoMessage)}>{info}</div>
					</TinyPopover>
				</div>
			) : (
				sendNewsletterButton
			)}
		</div>
	);
};

export const HtmlNewsletterEditorHeader: React.FC<IHtmlNewsletterEditorHeaderProps> = props => {
	const context = React.useContext(HtmlNewsletterEditorHeaderContext);
	if (!context) {
		return null;
	}
	return (
		<div className={css(styleSheet.header, ...(props.styles || []))}>
			<div className={css(styleSheet.headerRow)}>
				<div className={css(styleSheet.templateNameAndCTAButtons)}>
					<div className={css(styleSheet.nameContainer)}>
						<div className={css(baseStyleSheet.horizontalStack)}>
							<div className={css(styleSheet.name, baseStyleSheet.truncateText)}>
								<Observer>{() => <>{context.newsletter?.name || 'Untitled Newsletter'}</>}</Observer>
							</div>
							{context.newsletter?.canEdit && (
								<button className={css(styleSheet.editNameButton)} onClick={context.onEditNameClicked}>
									<EditItemPenIcon />
								</button>
							)}
						</div>
					</div>
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.headerActions)}>{props.children}</div>
				</div>
				{!props?.isEditModal ? (
					<button
						className={css(baseStyleSheet.horizontalStack, styleSheet.headerSendTestEmailCta)}
						onClick={context.onSendTestEmailClicked}
					>
						<PaperPlaneIcon />
						<span>Send a Test</span>
					</button>
				) : null}
			</div>
		</div>
	);
};

const HtmlNewsletterEditorReadOnlyBodyContent: React.FC<{
	newsletter?: HtmlNewsletter;
	style?: React.CSSProperties;
}> = observer(({ newsletter, style }) => {
	const previewStylesRef = React.useRef<StyleDeclarationValue[]>([styleSheet.editorPreviewer]);
	const [warningProps, setWarningProps] = React.useState({
		mount: true,
		show: true,
	});
	const onAnimationFinished = React.useCallback(() => {
		setWarningProps({ ...warningProps, mount: warningProps.show });
	}, [warningProps]);
	const onCtaClicked = React.useCallback(() => {
		setWarningProps({ ...warningProps, show: false });
	}, [warningProps]);
	return (
		<div className={css(styleSheet.editorPreviewerContainer)} style={style}>
			<HtmlNewsletterPreview styles={previewStylesRef.current} template={newsletter?.template} />
			{!!warningProps.mount && isIE11() && (
				<Spring
					from={{ opacity: warningProps.show ? 0 : 1 }}
					native={true}
					onRest={onAnimationFinished}
					to={{ opacity: warningProps.show ? 1 : 0 }}
				>
					{(animationStyle: React.CSSProperties) => (
						<animated.div style={animationStyle} className={css(styleSheet.editorPreviewerWarning)}>
							<div className={css(styleSheet.editorPreviewerWarningCard, baseStyleSheet.verticalStack)}>
								<div>
									<div className={css(styleSheet.editorPreviewerWarningCardTitle)}>
										Editing is not supported in this browser.
									</div>
									Please use Chrome, FireFox, MS Edge or Safari to enable editing.
								</div>
								<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={onCtaClicked}>
									<span>Ok</span>
								</button>
							</div>
						</animated.div>
					)}
				</Spring>
			)}
		</div>
	);
});

export const HtmlNewsletterEditorBody: React.FC<IHtmlNewsletterEditorBodyProps> = props => {
	const logger = useEventLogging('HtmlNewsletterEditorBody');
	const { onEditorLoaded, initialDesign: initDesign, newsletter } = React.useContext(HtmlNewsletterEditorBodyContext);
	const { autoSaveEnabled, onAutoSave } = React.useContext(HtmlNewsletterEditorHeaderContext);

	const editorRef = React.useRef<EmailEditor>(null);
	const [imageFileInputRef, onFileInputChanged, click] = useFileInput();
	const initialDesign = React.useRef<Design>(null);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const onLoaded = React.useCallback(() => {
		setIsLoaded(true);
	}, []);

	React.useEffect(() => {
		if (editorRef.current) {
			onEditorLoaded(editorRef.current);
			if (initDesign) {
				initialDesign.current = initDesign;
			}
		}
		if (isLoaded && editorRef.current) {
			const unlayer = editorRef.current.editor;
			unlayer.registerCallback<any, { url?: string; progress?: number }>('selectImage', (_, update) => {
				click()?.then(result => {
					if (result) {
						// Handle file upload here
						if (newsletter) {
							update({ progress: 1 });
							newsletter.files
								.uploadImages([result[0]])
								?.then(x => {
									update({ progress: 100, url: x.succeeded[0]?.url });
								})
								?.catch((err: IOperationResultNoValue) => {
									logger.logApiError('ImageUpload-Error', err);
									update({ progress: 0, url: null });
								});
							return;
						}
					}
					// just finish
					update({ url: null });
				});
			});

			if (initialDesign.current) {
				unlayer.loadDesign(initialDesign.current);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded]);

	React.useEffect(() => {
		if (isLoaded && editorRef.current) {
			const callback = async (designUpdate: DesignContentUpdate) => {
				// delete the image in lev. if removing from design
				if (
					!!newsletter &&
					designUpdate.type.toLocaleLowerCase() === 'content:removed' &&
					designUpdate.item.type?.toLocaleLowerCase() === 'image'
				) {
					const imageUrl = (designUpdate as DesignContentUpdate<{ url?: string }>).item.values?.src?.url;
					const fileAttachment = newsletter.files.images.find(x => x.url === imageUrl);
					if (fileAttachment) {
						try {
							await newsletter.files.delete(fileAttachment);
						} catch (err) {
							logger.logApiError('ImageDelete-Error', asApiError(err));
						}
					}
				}
				if (autoSaveEnabled) {
					onAutoSave();
				}
			};
			editorRef.current.editor.registerCallback<any, { url?: string }>('design:updated', callback);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded, autoSaveEnabled]);

	if (!newsletter.canEdit) {
		return <HtmlNewsletterEditorReadOnlyBodyContent newsletter={newsletter} style={props.style} />;
	}
	return (
		<>
			<EmailEditor
				onLoad={onLoaded}
				options={HtmlNewsletterEditor.DefaultEditorOptions}
				ref={editorRef}
				style={props.style}
			/>
			<input
				accept='.jpg,.jpeg,.gif,.png,.bmp'
				className={css(styleSheet.fileManageInput)}
				onChange={onFileInputChanged}
				ref={imageFileInputRef}
				type='file'
			/>
		</>
	);
};
