import { StyleSheet } from 'aphrodite';
import { Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		textAlign: 'center',
		...Layouts.verticalStack(20),
	},
	complianceInput: {
		marginBottom: 10,
		minWidth: 240,
	},
	detailMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		margin: '0 auto',
		marginTop: 16,
		maxWidth: 580,
	},
	icon: {},
	iconContainer: {
		margin: '100px auto 0',
		position: 'relative',
	},
	link: {
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
	stack: {
		display: 'inline-grid',
		marginTop: 16,
	},
});
