import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { getBlogReportsAsync } from '../../../../queries';
import { CalendarType } from '../../../containers/ContentCalendar/models';
import { baseStyleSheet } from '../../../styles/styles';
import { DayPicker, Weekday } from '../../DayPicker';
import { LoadingSpinner } from '../../LoadingSpinner';
import { DroppableObserver } from '../../helpers/dnd';
import {
	DefaultCampaignCalendarBlogStatuses,
	EventDataType,
	ICalendarDropTargetComponentProps,
	ICalendarTimeSpan,
	ICampaignCalendarEventPlaceholder,
	ICampaignCalendarOptions,
	SelectedPostStatuses,
} from '../CampaignCalendar/models';
import {
	CampaignCalendarTimeSpan,
	CampaignCalendarTitleBar,
	DAY,
	DAY_FORMAT,
	isWeekly,
	MAX_DAYS_IN_WEEK,
	MONTH,
	MONTH_FORMAT,
	WEEK,
} from '../CampaignCalendar/presentation';
import { styleSheet } from '../CampaignCalendar/styles';

interface IProps
	extends IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps,
		ICalendarDropTargetComponentProps {
	className?: string;
	hideCampaigns?: boolean;
	initialStartDate?: moment.MomentInput;
	numberOfMonthsToShow: number;
	onApproveCampaignClicked?(campaign: Api.CampaignViewModel): void;
	onApproveSocialCampaignClicked?: (campaign: Api.SocialMediaPostReportViewModel) => void;
	onCampaignClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onBlogPostClicked?(e: React.MouseEvent<HTMLElement>, blog: Api.IBlogReportView): void;
	onMonthsChange?(newMoment: moment.Moment): void;
	onNotifyClientClicked?(campaign: EventDataType): void;
	onRef?(ref?: ICampaignCalendarComponent): void;
	onRenderHeaderAccessory?(): React.ReactNode;
	onRenderSocial?(suggestion: Api.ISocialMediaPostReport): React.ReactNode;
	onRenderSuggestedEvent?(suggestion: Api.IContentCalendarSuggestion): React.ReactNode;
	onSocialMediaEventClicked?(
		e: React.MouseEvent<HTMLElement>,
		social: Api.SocialMediaPostReportViewModel
	): Promise<any>;
	onSuggestedEventClicked?(e: React.MouseEvent<HTMLElement>, suggestion: Api.IContentCalendarSuggestion): Promise<any>;
	onSuggestedSocialMediaEventClicked?(
		e: React.MouseEvent<Element>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
	onCalendarTypeChanged?(selectedType: CalendarType): void;
	options?: ICampaignCalendarOptions;
	placeholders?: ICampaignCalendarEventPlaceholder[];
	showGhost?: boolean;
	styles?: StyleDeclarationValue[];
	suggestions?: Api.IContentCalendarSuggestion[];
	selectedCampaignType?: CampaignType;
	calendarType?: CalendarType;
	campaignTypes?: CampaignType[];
}

const quarterlyAndMonthlyMomentToTimeSpan = (m: moment.Moment): ICalendarTimeSpan => {
	return {
		moment: m,
		title: m.format(MONTH_FORMAT),
		unitOfTime: MONTH,
	};
};

const weeklyMomentToTimeSpan = (m: moment.Moment): ICalendarTimeSpan => {
	return {
		moment: m,
		title: m.format(DAY_FORMAT),
		unitOfTime: DAY,
	};
};

/**
 * Initialize the calendar time spans
 * Updates triggered by the number of months to show, if we nned to hide the campaigns or the calendar type.
 * @If the number of months to show is 0, we show a weekly view starting wirh Sunday.
 * @If the number of months to show is 1, we show a monthly view.
 * @If the number of months to show is greater than 1, we show a quarterly view.
 */
function buildCalendarTimeSpans(calendarType: CalendarType, initialStartDate: moment.MomentInput) {
	const currentMonthMoment = isWeekly(calendarType)
		? moment(moment(initialStartDate).isSame(moment(), MONTH) ? new Date() : initialStartDate)
				.startOf(WEEK)
				.startOf(DAY)
		: moment(initialStartDate).startOf(MONTH).startOf(DAY);
	const timeSpans: ICalendarTimeSpan[] = [
		isWeekly(calendarType)
			? weeklyMomentToTimeSpan(currentMonthMoment)
			: quarterlyAndMonthlyMomentToTimeSpan(currentMonthMoment),
	];
	return timeSpans;
}

export interface ICampaignCalendarComponent {
	loadCampaigns?(): Promise<void>;
}

const CampaignCalendarBase = observer(
	React.forwardRef<ICampaignCalendarComponent, IProps>(function CampaignCalendarBase(props: IProps, ref) {
		const {
			onApproveCampaignClicked,
			selectedCampaignType,
			onApproveSocialCampaignClicked,
			onRequestDroppableId,
			onCampaignClicked,
			options,
			onCampaignGroupClicked,
			onBlogPostClicked,
			onNotifyClientClicked,
			onRenderSuggestedEvent,
			suggestions,
			onSuggestedEventClicked,
			onSocialMediaEventClicked,
			onSuggestedSocialMediaEventClicked,
			hideCampaigns,
			placeholders,
			numberOfMonthsToShow,
			onMonthsChange,
			calendarType: propsCalendarType,
			initialStartDate,
			impersonationContext,
			userSession,
			errorMessages,
			onCalendarTypeChanged,
			className,
			styles,
			droppableType,
			onRenderHeaderAccessory,
			showGhost,
			campaignTypes,
		} = props;

		const { logApiError } = useEventLogging('CampaignCalendar');

		const [calendarType, setCalendarType] = React.useState(() => {
			return propsCalendarType !== undefined
				? propsCalendarType
				: numberOfMonthsToShow === 0
					? CalendarType.Weekly
					: numberOfMonthsToShow > 1
						? CalendarType.Quarterly
						: CalendarType.Month;
		});

		const [calendarTimeSpans, setCalendarTimeSpans] = React.useState<ICalendarTimeSpan[]>(() => {
			const timeSpans: ICalendarTimeSpan[] = buildCalendarTimeSpans(calendarType, initialStartDate);
			if (numberOfMonthsToShow === 0) {
				let daysCount = MAX_DAYS_IN_WEEK - 1;
				while (daysCount > 0) {
					timeSpans.push(weeklyMomentToTimeSpan(moment(timeSpans[timeSpans.length - 1].moment).add(1, DAY)));
					daysCount--;
				}
				return timeSpans;
			}
			let count = numberOfMonthsToShow - 1;
			while (count > 0) {
				timeSpans.push(
					quarterlyAndMonthlyMomentToTimeSpan(moment(timeSpans[timeSpans.length - 1].moment).add(1, MONTH))
				);
				count--;
			}
			return timeSpans;
		});

		const [campaigns] = React.useState<Api.CampaignsReportingViewModel>(() => {
			const c = new Api.CampaignsReportingViewModel(userSession).impersonate(impersonationContext);
			c.pageSize = 500;
			const currentMonthMoment = moment(initialStartDate).startOf(MONTH).startOf(DAY);
			const endDate = moment(currentMonthMoment).add(numberOfMonthsToShow, MONTH).endOf(MONTH).endOf(DAY).toDate();
			const startDate = currentMonthMoment.toDate();
			c.dateRange = { startDate, endDate };
			return c;
		});

		const [socialCampaigns] = React.useState<Api.SocialMediaPostsReportingViewModel>(() => {
			const c = new Api.SocialMediaPostsReportingViewModel(userSession).impersonate(impersonationContext);
			c.pageSize = 200;
			c.setSelectedPostStatus(SelectedPostStatuses);
			const currentMonthMoment = moment(initialStartDate).startOf(MONTH).startOf(DAY);
			const endDate = moment(currentMonthMoment).add(numberOfMonthsToShow, MONTH).endOf(MONTH).endOf(DAY).toDate();
			const startDate = currentMonthMoment.toDate();
			c.dateRange = { startDate, endDate };
			return c;
		});

		const userCacheRef = React.useRef<Api.IDictionary<Api.UserViewModel>>({});

		const calendarTimeSpanStyleRef = React.useRef<React.CSSProperties>({
			width: numberOfMonthsToShow === 0 ? `100%` : `${100.0 / Math.max(numberOfMonthsToShow, 1)}%`,
		});

		const account = impersonationContext?.isValid ? impersonationContext.account : userSession.account;
		const sendEmailAccountFeature = account?.features?.sendEmail;

		const outsideBusinessDays = React.useMemo(() => {
			const businessHours = sendEmailAccountFeature;
			const days = [];
			if (businessHours?.observeSendIntervals) {
				if (!businessHours?.saturdayInterval?.startMinutes && !businessHours?.saturdayInterval?.endMinutes) {
					days.push(6);
				}
				if (!businessHours?.sundayInterval?.startMinutes && !businessHours?.sundayInterval?.endMinutes) {
					days.push(0);
				}
				if (!businessHours?.fridayInterval?.startMinutes && !businessHours?.fridayInterval?.endMinutes) {
					days.push(5);
				}
			}
			return days;
		}, [sendEmailAccountFeature]);

		React.useEffect(() => {
			const timeSpans: ICalendarTimeSpan[] = buildCalendarTimeSpans(calendarType, initialStartDate);
			/**
			 * If the number of months to show is 0, we show a weekly view
			 * with and index of 0, add a day for each day of the week
			 */
			if (numberOfMonthsToShow === 0) {
				let daysCount = MAX_DAYS_IN_WEEK - 1;
				while (daysCount > 0) {
					timeSpans.push(weeklyMomentToTimeSpan(moment(timeSpans[timeSpans.length - 1].moment).add(1, DAY)));
					daysCount--;
				}
				setCalendarTimeSpans(timeSpans);
				setCalendarType(CalendarType.Weekly);
			} else {
				let count = numberOfMonthsToShow - 1;
				while (count > 0) {
					timeSpans.push(
						quarterlyAndMonthlyMomentToTimeSpan(moment(timeSpans[timeSpans.length - 1].moment).add(1, MONTH))
					);
					count--;
				}
				setCalendarTimeSpans(timeSpans);
				setCalendarType(numberOfMonthsToShow > 1 ? CalendarType.Quarterly : CalendarType.Month);
			}

			if (!hideCampaigns && campaignTypes?.length > 0) {
				loadCampaignsRef.current?.(timeSpans);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [numberOfMonthsToShow, hideCampaigns, calendarType, campaignTypes]);

		React.useImperativeHandle(
			ref,
			() => {
				return {
					loadCampaigns: () => loadCampaignsRef.current?.(),
				};
			},
			[]
		);

		const onRequestUser = (id: string) => {
			let user = userCacheRef.current[id];
			if (!user) {
				// @ts-ignore
				user = new Api.UserViewModel(userSession, { id }).impersonate(impersonationContext);
				userCacheRef.current[id] = user;
			}
			return user;
		};

		const go = (forward: boolean) => () => {
			return new Promise(resolve => {
				const nextTimeSpans = calendarTimeSpans.map<ICalendarTimeSpan>(x => {
					const count =
						calendarType === CalendarType.Quarterly
							? numberOfMonthsToShow
							: calendarType === CalendarType.Month
								? 1
								: MAX_DAYS_IN_WEEK;

					const nextMoment = forward
						? moment(x.moment).add(count, x.unitOfTime)
						: moment(x.moment).subtract(count, x.unitOfTime);
					return isWeekly(calendarType)
						? weeklyMomentToTimeSpan(nextMoment)
						: quarterlyAndMonthlyMomentToTimeSpan(nextMoment);
				});
				setCalendarTimeSpans(nextTimeSpans);
				if (!hideCampaigns) {
					loadCampaigns(nextTimeSpans)?.then(resolve)?.catch(resolve);
				}
				onMonthsChange?.(nextTimeSpans[0].moment);
			});
		};

		const loadCampaigns = async (timeSpans: ICalendarTimeSpan[] = calendarTimeSpans) => {
			try {
				campaigns.reset();
				campaigns.campaignReportType = Api.BulkEmailReportType.Campaigns;
				const firstTimeSpan = timeSpans[0];
				const lastTimeSpan = timeSpans[timeSpans.length - 1];
				const startDate = moment(firstTimeSpan.moment).startOf(firstTimeSpan.unitOfTime).startOf(DAY).toDate();
				const endDate = moment(lastTimeSpan.moment).endOf(lastTimeSpan.unitOfTime).endOf(DAY).toDate();
				const socialMediaIsEnabled = impersonationContext?.isValid
					? impersonationContext?.account?.features?.socialMedia?.enabled
					: userSession?.account?.features?.socialMedia?.enabled;
				campaigns.dateRange = {
					endDate,
					startDate,
				};
				socialCampaigns.reset();
				socialCampaigns.setSelectedPostStatus(SelectedPostStatuses);
				socialCampaigns.dateRange = {
					endDate,
					startDate,
				};

				if (impersonationContext?.isValid && impersonationContext?.user?.id) {
					campaigns.selectedUserId = impersonationContext.user.id;
					socialCampaigns.setSelectedUserId(impersonationContext.user.id);
				}

				const promises: Promise<any>[] = [];

				/** Load emails if its not being filtered   */
				if (campaignTypes?.includes(CampaignType.Email)) {
					promises.push(campaigns.load());
				}
				/** Load social posts if its not being filtered   */
				if (socialCampaigns && socialMediaIsEnabled && campaignTypes?.includes(CampaignType.Social)) {
					promises.push(socialCampaigns.load());
				}

				let blogReports: Api.IBlogReportView[] = [];
				/** Load blogs if its not being filtered   */
				if (account?.features?.blogFeature?.enabled && campaignTypes?.includes(CampaignType.Blog)) {
					const blogReportsPromise = getBlogReportsAsync({
						endDate,
						impersonationContext,
						pageSize: 200,
						startDate,
						status: DefaultCampaignCalendarBlogStatuses,
						userSession,
						sendFromUserId: impersonationContext?.isValid ? impersonationContext.user?.id ?? undefined : undefined,
					});
					blogReportsPromise?.then(reportsCollection => {
						blogReports = reportsCollection.values;
					});
					promises.push(blogReportsPromise);
				}

				await Promise.all(promises);

				const nextTimeSpans = timeSpans.map(timeSpan => {
					const nextTimeSpan: ICalendarTimeSpan = { ...timeSpan, campaignsByGroupId: {} };

					nextTimeSpan.campaigns = campaigns.items.filter(x => {
						return (
							x.status !== Api.EmailSendStatus.Cancelled &&
							x.status !== Api.EmailSendStatus.Rejected &&
							x.schedule?.startDate &&
							timeSpan.moment.isSame(x.schedule?.startDate, timeSpan.unitOfTime)
						);
					});

					nextTimeSpan.campaigns = nextTimeSpan.campaigns?.sort((a, b) => {
						return moment(a.schedule.startDate).isBefore(b.schedule.startDate) ? -1 : 1;
					});
					nextTimeSpan.campaigns?.forEach(x => {
						if (x.groupId) {
							const c = nextTimeSpan.campaignsByGroupId[x.groupId] || [];
							c.push(x);
							nextTimeSpan.campaignsByGroupId[x.groupId] = c;
						}
					});

					nextTimeSpan.socialCampaigns = socialCampaigns?.items.filter(x => {
						return (
							(!impersonationContext?.isValid
								? x?.status === Api.PostStatus.Pending && (x.creator.id === userSession.user.id || userSession.isAdmin)
								: x.status === Api.PostStatus.Pending) ||
							x.status === Api.PostStatus.Scheduled ||
							x.status === Api.PostStatus.Succeeded ||
							((x.status === Api.PostStatus.PartiallySucceeded || x.status === Api.PostStatus.Started) &&
								x.dueDate &&
								timeSpan.moment.isSame(x.dueDate, timeSpan.unitOfTime))
						);
					});

					nextTimeSpan.socialCampaigns = nextTimeSpan?.socialCampaigns?.sort((a, b) => {
						return moment(a.dueDate).isBefore(b.dueDate) ? -1 : 1;
					});

					nextTimeSpan.blogReports = blogReports.filter(x => {
						return (
							x.status !== Api.BlogStatus.Cancelled &&
							x.scheduledSendDate &&
							timeSpan.moment.isSame(x.scheduledSendDate, timeSpan.unitOfTime)
						);
					});
					nextTimeSpan.blogReports = nextTimeSpan.blogReports?.sort((a, b) => {
						return moment(a.scheduledSendDate).isBefore(b.scheduledSendDate) ? -1 : 1;
					});

					return nextTimeSpan;
				});
				setCalendarTimeSpans(nextTimeSpans);
			} catch (error) {
				logApiError('Campaign_Loading_Error', error);
				errorMessages.pushApiError(error);
			}
		};
		const loadCampaignsRef = React.useRef(loadCampaigns);
		loadCampaignsRef.current = loadCampaigns;

		const calendarTypeChanged = (selectedCalendarType: CalendarType) => {
			if (onCalendarTypeChanged) {
				onCalendarTypeChanged(selectedCalendarType);
			} else {
				setCalendarType(selectedCalendarType);
			}
		};

		const getDroppableIdForTimeSpan = (timeSpan: ICalendarTimeSpan) => {
			return onRequestDroppableId?.(timeSpan.moment) || `${timeSpan.title}-${timeSpan.moment.get('year')}`;
		};

		const renderSingleMonthCalendar = () => {
			return (
				<DayPicker
					key='day-picker'
					fixedWeeks={false}
					month={calendarTimeSpans[0].moment.toDate()}
					navbarElement={<></>}
					showOverflowDates={false}
					onRenderDay={onRenderSingleMonthDay}
					weekdayElement={weekdayProps => <Weekday onRenderName={name => name} {...weekdayProps} />}
					selectedDays={calendarTimeSpans[0].moment.toDate()}
					className={css(styleSheet.dayPicker, showGhost && styleSheet.dayPickerGhost)}
				/>
			);
		};

		const onRenderSingleMonthDay = (day: Date): React.ReactNode => {
			const currentMonthTimeSpan = calendarTimeSpans[0];

			const dayTimeSpan: ICalendarTimeSpan = {
				moment: moment(day),
				title: moment(day).format('DD'),
				campaigns: currentMonthTimeSpan.campaigns?.filter(
					x => new Date(x.schedule.startDate).getDate() === day.getDate()
				),
				socialCampaigns: currentMonthTimeSpan.socialCampaigns?.filter(
					x => new Date(x.dueDate).getDate() === day.getDate()
				),
				blogReports: currentMonthTimeSpan.blogReports?.filter(
					x => new Date(x.scheduledSendDate).getDate() === day.getDate()
				),
				unitOfTime: DAY,
			};

			const id = getDroppableIdForTimeSpan(dayTimeSpan);
			const endOfDayMoment = moment(day).endOf(DAY);

			const isDropDisabled =
				!droppableType ||
				endOfDayMoment.isBefore(moment()) ||
				(outsideBusinessDays.includes(day.getDay()) && selectedCampaignType === CampaignType.Email);
			return (
				<DroppableObserver
					direction='horizontal'
					isDropDisabled={isDropDisabled}
					droppableId={day.toISOString()}
					key={id}
					type={droppableType}
				>
					{(droppableProvided: DroppableProvided, droppableStateSnapshot: DroppableStateSnapshot) => (
						<>
							{renderTimeSpan(dayTimeSpan, day.getDay(), droppableProvided, droppableStateSnapshot)}
							<span style={{ display: 'none' }}>{droppableProvided.placeholder}</span>
						</>
					)}
				</DroppableObserver>
			);
		};

		const renderTimeSpan = (
			calendarTimeSpan: ICalendarTimeSpan,
			index: number,
			droppableProvided?: DroppableProvided,
			droppableStateSnapshot?: DroppableStateSnapshot
		) => {
			const currentPlaceholders =
				placeholders?.filter(
					x =>
						calendarTimeSpan.moment.isSame(x.moment, calendarTimeSpan.unitOfTime) &&
						calendarTimeSpan.moment.isSame(x.moment, 'year')
				) || [];
			const currentSuggestions =
				suggestions?.filter(
					x =>
						calendarTimeSpan.moment.isSame(x.schedule.startDate, calendarTimeSpan.unitOfTime) &&
						calendarTimeSpan.moment.isSame(x.schedule.startDate, 'year')
				) || [];
			if (options?.timeSpanOptions) {
				options.timeSpanOptions.eventOptions = { ...(options.timeSpanOptions.eventOptions || {}) };
				options.timeSpanOptions.eventOptions.showScheduledFor = calendarType === CalendarType.Quarterly;
			}
			return (
				<CampaignCalendarTimeSpan
					index={index}
					calendarType={calendarType}
					droppableProps={droppableProvided?.droppableProps}
					hideCampaigns={hideCampaigns}
					isDraggingOver={droppableStateSnapshot?.isDraggingOver}
					key={`${calendarTimeSpan.title}-${index}-${calendarTimeSpan.moment.get('year')}`}
					calendarTimeSpan={calendarTimeSpan}
					onApproveCampaignClicked={onApproveCampaignClicked}
					onApproveSocialCampaignClicked={onApproveSocialCampaignClicked}
					onCampaignClicked={onCampaignClicked}
					onCampaignGroupClicked={onCampaignGroupClicked}
					onBlogPostClicked={onBlogPostClicked}
					onInnerRef={droppableProvided?.innerRef}
					onNotifyClientClicked={onNotifyClientClicked}
					onRenderSuggestion={onRenderSuggestedEvent}
					onRequestUser={onRequestUser}
					onSocialMediaEventClicked={onSocialMediaEventClicked}
					onSuggestedSocialMediaEventClicked={onSuggestedSocialMediaEventClicked}
					onSuggestionClicked={onSuggestedEventClicked}
					options={options?.timeSpanOptions}
					placeholders={currentPlaceholders}
					style={calendarType === CalendarType.Quarterly ? calendarTimeSpanStyleRef.current : { width: '100%' }}
					suggestions={currentSuggestions}
					userSession={userSession}
					styleSheet={styleSheet}
				/>
			);
		};

		const renderQuarterlyOrWeeklyCalendar = () => {
			return calendarTimeSpans.map((timeSpan, i) => {
				const id = getDroppableIdForTimeSpan(timeSpan);
				const isDropDisabled = !droppableType || timeSpan.moment.endOf(timeSpan.unitOfTime).isBefore(moment());

				const droppableId: string = timeSpan.moment.startOf(timeSpan.unitOfTime).toISOString();

				return (
					<DroppableObserver
						direction='horizontal'
						droppableId={droppableId}
						isDropDisabled={isDropDisabled}
						key={id}
						type={droppableType}
					>
						{(droppableProvided: DroppableProvided, droppableStateSnapshot: DroppableStateSnapshot) => (
							<>
								{renderTimeSpan(timeSpan, i, droppableProvided, droppableStateSnapshot)}{' '}
								<span style={{ display: 'none' }}>{droppableProvided.placeholder}</span>
							</>
						)}
					</DroppableObserver>
				);
			});
		};

		const renderContentByCalendarType = () => {
			switch (calendarType) {
				case CalendarType.Month:
					return renderSingleMonthCalendar();
				case CalendarType.Quarterly:
				case CalendarType.Weekly:
					return renderQuarterlyOrWeeklyCalendar();
				default:
					return null;
			}
		};

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} campaign-calendar ${className || ''}`}>
				<CampaignCalendarTitleBar
					calendarType={calendarType}
					end={calendarType === CalendarType.Month ? null : calendarTimeSpans[calendarTimeSpans.length - 1]}
					goBack={go(false)}
					goForward={go(true)}
					onCalendarTypeChanged={calendarTypeChanged}
					onRenderHeaderAccessory={onRenderHeaderAccessory}
					showGhost={showGhost}
					start={calendarTimeSpans[0]}
				/>
				<div className={css(styleSheet.body, calendarType === CalendarType.Month ? styleSheet.bodyMonth : undefined)}>
					{renderContentByCalendarType()}
					{campaigns.isBusy ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' /> : null}
				</div>
			</div>
		);
	})
);

const CampaignCalendarAsObserver = observer(CampaignCalendarBase);
export const CampaignCalendar = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ImpersonationContextKey
)(CampaignCalendarAsObserver);
