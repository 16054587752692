import { StyleDeclarationValue, css } from 'aphrodite';
import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { RadioButton } from '../../../components/RadioButton';
import { TextInput } from '../../../components/TextInput';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

export enum SendOptionCompleteByOption {
	ASAP = 'complete-by-asap-option',
	SPREAD = 'complete-by-spread-option',
}

interface IProps {
	className?: string;
	maxEmailsPerDay: number;
	onOptionChange(option: SendOptionCompleteByOption): void;
	onSendOptionCompleteByChange(numberOfDays?: number): void;
	recipients: number;
	styles?: StyleDeclarationValue[];
}

export const EditSendOptionsCompleteBy: React.FC<IProps> = props => {
	const { className, maxEmailsPerDay, onOptionChange, onSendOptionCompleteByChange, recipients, styles } = props;
	const radioGroupId = React.useRef(`complete-by-option-group-${uuidgen()}`).current;
	const radioIds = React.useRef<{ asap: string; spread: string }>({
		asap: SendOptionCompleteByOption.ASAP,
		spread: SendOptionCompleteByOption.SPREAD,
	}).current;
	const [completeByDays, setCompleteByDays] = React.useState(Math.ceil(recipients / maxEmailsPerDay));
	const [selectedId, setSelectedId] = React.useState(radioIds.asap);
	const maxNumDays = recipients > 30 ? 30 : recipients;

	useEffect(() => {
		// @ts-ignore
		onSendOptionCompleteByChange(selectedId === SendOptionCompleteByOption.ASAP ? null : completeByDays);
	}, [completeByDays, onSendOptionCompleteByChange, selectedId, maxEmailsPerDay, recipients]);

	const onCompleteByDaysChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const num = parseInt(e.target.value, 10);
			if (num <= maxNumDays && num >= Math.ceil(recipients / maxEmailsPerDay)) {
				if (selectedId !== radioIds.spread) {
					setSelectedId(radioIds.spread);
				}
				setCompleteByDays(num);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxNumDays, recipients, maxEmailsPerDay]
	);
	const onCompleteByOptionChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				setSelectedId(e.target.id);
				setCompleteByDays(Math.ceil(recipients / maxEmailsPerDay));
				onOptionChange(e.target.id as SendOptionCompleteByOption);
			}
		},
		[onOptionChange, recipients, maxEmailsPerDay]
	);

	const getPerDay = useCallback(() => {
		return Math.ceil(recipients / completeByDays);
	}, [recipients, completeByDays]);

	return (
		<div className={`${css(styleSheet.EditSendOptionsCompleteBy, ...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.radioOptions)}>
				<RadioButton
					checked={selectedId === radioIds.asap}
					className={css(styleSheet.radio)}
					iconClassName={css(styleSheet.radioIcon)}
					id={radioIds.asap}
					labelClassName={css(styleSheet.radioLabel, styleSheet.radioLabelLeft)}
					name={radioGroupId}
					onChange={onCompleteByOptionChanged}
				>
					Send as quickly as possible
					{typeof maxEmailsPerDay === 'number' && (
						<div className={css(styleSheet.perDayLabel)}>{`${maxEmailsPerDay} email${
							maxEmailsPerDay > 1 ? 's' : ''
						}/day`}</div>
					)}
				</RadioButton>
				<RadioButton
					checked={selectedId === radioIds.spread}
					className={css(styleSheet.radio)}
					iconClassName={css(styleSheet.radioIcon)}
					id={radioIds.spread}
					labelClassName={css(styleSheet.radioLabel)}
					name={radioGroupId}
					onChange={onCompleteByOptionChanged}
				>
					<div className={css(baseStyleSheet.verticalStack)}>
						<div className={css(baseStyleSheet.horizontalStack)}>
							<span>Spread out over</span>
							<TextInput
								className={css(baseStyleSheet.textField)}
								inputClassName={css(styleSheet.completeByDays)}
								inputId='complete-by-days-input'
								min={1}
								onChange={onCompleteByDaysChanged}
								step={1}
								type='number'
								value={completeByDays}
							/>
							<span className={css(styleSheet.fixedWidth30)}>{`day${completeByDays > 1 ? 's' : ''}`}</span>
						</div>
					</div>
					{!!maxEmailsPerDay && !!recipients && (
						<div className={css(styleSheet.perDayLabel)}>{`${getPerDay()} email${getPerDay() > 1 ? 's' : ''}/day`}</div>
					)}
				</RadioButton>
			</div>
		</div>
	);
};
