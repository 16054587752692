import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../../../models';
import { useEventLogging } from '../../../../../../models/Logging';
import { FullscreenModalHeader } from '../../../../../components/fullscreen/FullscreenModalHeader';
import { ScheduleSendIcon } from '../../../../../components/svgs/icons/ScheduleSendIcon';
import { SuccessCheckIcon } from '../../../../../components/svgs/icons/SuccessCheckIcon';
import { baseStyleSheet } from '../../../../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	styles?: StyleDeclarationValue[];
}

export const MultiSocialCampaignSchedulerComplete = inject(ImpersonationContextKey)(
	observer(function _MultiSocialCampaignSchedulerComplete({ styles = [], impersonationContext }: IProps) {
		const { logInput } = useEventLogging('MultiSocialCampaignSchedulerComplete');
		const onReportingLinkClicked = () => {
			logInput('ReportingLink', 'Click');
		};
		return (
			<div className={`${css(styleSheet.container, ...styles)} multi-social-campaign-scheduler-complete`}>
				<FullscreenModalHeader>
					<span>Post Scheduled</span>
				</FullscreenModalHeader>
				<figure className={css(styleSheet.iconContainer)}>
					<ScheduleSendIcon />
					<SuccessCheckIcon className={css(styleSheet.successIcon)} />
				</figure>
				<p className={css(styleSheet.titleMessage)}>
					<span>Your posts will be delivered on their scheduled dates.</span>
				</p>
				{!impersonationContext?.isValid ? (
					<p className={css(styleSheet.detailMessage)}>
						You can view the queued up posts in &nbsp;
						<NavLink
							className={css(baseStyleSheet.brandLink, styleSheet.link)}
							onClick={onReportingLinkClicked}
							to='/reporting/social-media-posts'
						>
							<span>Social Media Reports</span>
						</NavLink>
						.
					</p>
				) : null}
			</div>
		);
	})
);
