import * as Api from '@ViewModels';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { AccountUserSelectBox } from '../../../../admin/components/user/AccountUserSelectBox';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../models/Logging';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';
import { ComposerSendFromDropdown } from '../../../components/ComposerSendFromDropdown';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { ISelectOption } from '../../../components/Select';
import { brandPrimary, charmGray } from '../../../styles/colors';
import { baseStyleSheet, CssSelectors } from '../../../styles/styles';
import { CampaignComposerBaseLayout } from '../../BulkHwcPages/presentation';

export const styleSheet = StyleSheet.create({
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		padding: '20px 4px 20px 4px',
	},
	headerSocialContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	headerTitle: {
		color: brandPrimary,
		margin: 0,
		padding: 0,
	},
	selectLabel: {
		color: charmGray,
		fontSize: 11,
		marginBottom: 4,
		textTransform: 'uppercase',
		display: 'block',
	},
	selectUserWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		...CssSelectors.allChildren(
			{
				marginRight: 8,
			},
			'label'
		),
	},
	dropdownOverrides: {
		width: 240,
	},
	socialMediaHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});

const AccountSendFromDropdown = ({
	sendFromUserId,
	onSendFromChange,
	impersonationContext,
	userSelectLabel,
	isSocialMediaLayout,
}: {
	impersonationContext?: ImpersonationContextViewModel;
	sendFromUserId: string | null;
	userSelectLabel?: React.ReactNode;
	onSendFromChange: (params: {
		sendFrom: Api.ISendEmailFrom;
		sendFromUserId: string | null;
	}) => void;
	isSocialMediaLayout?: boolean;
}) => {
	const userSession = useUserSession();
	const { logApiError } = useEventLogging('MultiCampaignSchedulerLayout');

	const defaultUserSelectBoxOption: ISelectOption = {
		dataContext: Api.SendEmailFrom.ContactOwner,
		id: 'default-user-option-contact-owners',
		text: 'Contact Owners',
	};

	const initialSelectedOption = React.useMemo(() => {
		if (!sendFromUserId) {
			return defaultUserSelectBoxOption;
		}
		const user = new Api.UserViewModel(userSession, { id: sendFromUserId } as Partial<Api.IUser>).impersonate(
			impersonationContext
		);
		user.load()?.catch(error => {
			logApiError('UserLoad-Error', error);
		});
		return {
			dataContext: user,
			id: sendFromUserId,
			text: 'Loading...',
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sendFromUserId, userSession, impersonationContext, logApiError]);

	const onUserOptionSelected = (
		option: ISelectOption<string | Api.UserViewModel<Api.IUser>>,
		user?: Api.UserViewModel<Api.IUser>
	) => {
		if (option.dataContext === Api.SendEmailFrom.ContactOwner) {
			onSendFromChange({
				sendFrom: Api.SendEmailFrom.ContactOwner,
				sendFromUserId: null,
			});
		} else if (user) {
			if (user.id === userSession.user.id) {
				onSendFromChange({
					sendFrom: Api.SendEmailFrom.CurrentUser,
					sendFromUserId: userSession.user.id,
				});
				return;
			}
			onSendFromChange({
				sendFrom: Api.SendEmailFrom.SelectedUser,
				sendFromUserId: user.id,
			});
		}
	};

	return (
		<div>
			<div className={css(isSocialMediaLayout ? styleSheet.selectUserWrapper : undefined)}>
				<label className={css(styleSheet.selectLabel)}>
					{userSelectLabel || <span>Account User</span>}:<span className={css(baseStyleSheet.required)}>*</span>
				</label>

				<AccountUserSelectBox
					onOptionSelected={onUserOptionSelected}
					defaultOption={defaultUserSelectBoxOption}
					initialSelectedOption={initialSelectedOption}
					onRenderPlaceholder={() => 'Please choose'}
				/>
			</div>
		</div>
	);
};

interface IProps extends IImpersonationContextComponentProps {
	title?: string;
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId: string | null;
	onSendFromChange: (params: {
		sendFrom: Api.ISendEmailFrom;
		sendFromUserId: string | null;
	}) => void;
	sideBar?: React.ReactNode;
	styles?: StyleDeclarationValue[];
	hideContactOwnerOption?: boolean;
	userSelectLabel?: React.ReactNode;
	isSocialMediaLayout?: boolean;
}

export const MultiCampaignSchedulerLayout = inject(ImpersonationContextKey)(
	observer(function MultiCampaignSchedulerLayout({
		title,
		sendFrom,
		sendFromUserId,
		onSendFromChange,
		children,
		sideBar,
		styles = [],
		impersonationContext,
		hideContactOwnerOption,
		userSelectLabel,
		isSocialMediaLayout,
	}: React.PropsWithChildren<IProps>) {
		const userSession = useUserSession();
		const fullscreenModal = useFullscreenModal();
		const headerTitle = title || 'Schedule Campaigns';

		const isUsingAccountImpersonation = React.useMemo(() => {
			return impersonationContext?.isValid ?? false;
		}, [impersonationContext]);

		const isAdmin = userSession.userRole === 'superAdmin' || userSession.userRole === 'admin';
		const canSendOnBehalf = isAdmin && userSession.account.preferences?.sendOnBehalfEnabled;

		return (
			<>
				<MultiContainerHeader
					fullscreenHeader={headerTitle}
					onFullscreenRequestBack={ev => {
						ev.preventDefault();
						fullscreenModal.dismissModal();
					}}
					onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
				/>
				<CampaignComposerBaseLayout
					styles={styles}
					mainHeader={
						<div
							className={css(
								styleSheet.headerContainer,
								isSocialMediaLayout ? styleSheet.headerSocialContainer : undefined
							)}
						>
							<h3 className={css(styleSheet.headerTitle)}>{headerTitle}</h3>

							{canSendOnBehalf ? (
								<>
									{isUsingAccountImpersonation ? (
										<AccountSendFromDropdown
											sendFromUserId={sendFromUserId}
											onSendFromChange={onSendFromChange}
											impersonationContext={impersonationContext}
											userSelectLabel={userSelectLabel}
											isSocialMediaLayout={isSocialMediaLayout}
										/>
									) : null}

									{!isUsingAccountImpersonation ? (
										<ComposerSendFromDropdown
											sendFrom={sendFrom}
											sendFromUserId={sendFromUserId}
											onChange={onSendFromChange}
											dropdownStyles={[styleSheet.dropdownOverrides]}
											hideContactOwnerOption={hideContactOwnerOption}
										/>
									) : null}
								</>
							) : null}
						</div>
					}
					sideBar={sideBar}
				>
					{children}
				</CampaignComposerBaseLayout>
			</>
		);
	})
);
