import { StyleSheet } from 'aphrodite';
import { aceGray, error, prestoPurple, shuffleGray } from '../../../../../styles/colors';
import { cardStyle, Layouts } from '../../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	editor: {
		height: '100%',
		overflow: 'auto',
		width: '100%',
	},
	editorContainer: {
		border: `1px solid ${aceGray}`,
		borderLeft: 'none',
		borderRadius: '0 8px 8px 0',
		backgroundColor: `${shuffleGray}80`,
		flexGrow: 1,
		height: '100%',
		overflow: 'hidden',
		paddingLeft: 10,
	},
	editorContainerError: {
		border: `1px solid ${error}`,
	},
	editorContainerSuccess: {
		border: `1px solid ${prestoPurple}`,
		opacity: 0.5,
	},
	image: {
		aspectRatio: '1 / 1',
		objectFit: 'cover',
		height: '100%',
	},
	imageContainer: {
		aspectRatio: '1 / 1',
		borderRadius: '8px 0 0 8px',
		flexShrink: 0,
		height: 'calc(100% + 2px)',
		overflow: 'hidden',
	},
	largeImage: {
		...cardStyle,
		maxHeight: 200,
		width: 'auto',
		padding: 8,
		borderRadius: 8,
	},
	stack: {
		...Layouts.horizontalStack(0),
		height: 160,
	},
});
