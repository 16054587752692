import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: 44,
		height: '100%',
		overflow: 'auto',
		padding: '20px 20px 20px 24px',
	},
	schedulerContainer: {
		flexGrow: 1,
		minHeight: 0,
	},
});
