import * as Api from '@ViewModels';
import * as React from 'react';
import { AppState, UserSessionViewModelKey } from '../AppState';
import { storagePrefixExcludedFromClear, storageTypeAvailable } from '../Storage';

type Behavior<T> = {
	storageType: 'localStorage' | 'sessionStorage';
	defaultIfEmpty?: T;
	storageKey: string;
	skipPersistFn?: () => boolean;
	setInitialValue?: (storedValue: T) => T;
};

export class StorageKeyBuilder {
	protected _baseKey: string;
	protected _impersonationContext: Api.IImpersonationContext;
	protected _excludeFromClear: boolean;
	protected _useAccount: boolean;
	protected _useUser: boolean;

	public static create = (key: string) => new StorageKeyBuilder(key);

	constructor(key: string) {
		this._baseKey = key;
	}

	/**
	 * Implicitly calls useAccount()
	 * @returns
	 */
	public useUser = () => {
		this._useUser = true;
		this._useAccount = true;
		return this;
	};

	public useAccount = () => {
		this._useAccount = true;
		return this;
	};

	/**
	 * Implicitly calls useAccount() if account is provided, and useUser() if user is provided
	 * @param impersonationContext
	 * @returns
	 */
	public useImpersonation = (impersonationContext: Api.IImpersonationContext) => {
		if (impersonationContext.account?.id) {
			this._useAccount = true;
			if (impersonationContext.user?.id) {
				this._useUser = true;
			}
			this._impersonationContext = {
				account: { id: impersonationContext.account.id },
				user: impersonationContext.user?.id ? { id: impersonationContext.user?.id } : undefined,
			};
		}
		return this;
	};

	public excludeFromClear = () => {
		this._excludeFromClear = true;
		return this;
	};

	public build = () => {
		const userSession = AppState[UserSessionViewModelKey];
		const accountId = this._useAccount ? this._impersonationContext?.account?.id ?? userSession.account?.id : null;
		const userId = this._useUser ? this._impersonationContext?.user?.id ?? userSession.user?.id : null;
		const comps = [
			this._excludeFromClear ? storagePrefixExcludedFromClear : null,
			accountId,
			userId,
			this._baseKey,
		].filter(Boolean);
		return comps.join('-');
	};
}

export const usePersistChangeInBrowserStorage = <T>({
	storageType,
	storageKey,
	skipPersistFn,
	defaultIfEmpty,
	setInitialValue,
}: Behavior<T>): [T | null, React.Dispatch<React.SetStateAction<T>>] => {
	const getStoredValue = (): T | null => {
		if (!storageTypeAvailable(storageType) || skipPersistFn?.()) {
			return defaultIfEmpty ?? null;
		}
		const potentialValue = window[storageType].getItem(storageKey);
		if (potentialValue) {
			try {
				return JSON.parse(potentialValue);
			} catch {
				return defaultIfEmpty ?? null;
			}
		}
		return defaultIfEmpty ?? null;
	};

	const persistValueInLocalStorage = React.useCallback(
		(valueToStore: T) => {
			const stringifiedValue = JSON.stringify(valueToStore);
			window[storageType].setItem(storageKey, stringifiedValue);
		},
		[storageKey, storageType]
	);

	const storedValue = getStoredValue();
	const [value, setValue] = React.useState<T>(setInitialValue ? setInitialValue(storedValue) : storedValue);

	React.useEffect(() => {
		if (!storageTypeAvailable(storageType) || (skipPersistFn && skipPersistFn())) {
			return;
		}
		persistValueInLocalStorage(value);
	}, [value, persistValueInLocalStorage, skipPersistFn, storageType]);

	return [value, setValue];
};
