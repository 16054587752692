import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { CampaignType } from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useCampaignCalendarSuggestionsContext } from '../../components/campaigns/CampaignCalendar/context';
import { Checkbox } from '../../components/Checkbox';
import {
	ConfirmationDialog,
	DefaultConfirmationOptions,
	IConfirmationDialogOption,
} from '../../components/ConfirmationDialog';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { bs } from '../../styles/styles';
import { SupportedCampaignTypes } from './models';
import { styleSheet } from './styles';

function CardKeyLabel({ type }: { type: CampaignType }) {
	return (
		<>
			{type === CampaignType.Email
				? 'Email'
				: type === CampaignType.Social
					? 'Social Media'
					: type === CampaignType.Blog
						? 'Blogs'
						: null}
		</>
	);
}

interface ContentCalendarLegendProps extends IImpersonationContextComponentProps {
	campaignTypes: CampaignType[];
	onSetCampaignTypes: (campaignTypes: CampaignType[]) => void;
}

export const CampaignTypeLabel = ({
	type,
	label,
}: {
	type: CampaignType;
	label?: string;
}) => {
	return (
		<div className={css(styleSheet.checkboxContent)}>
			<span
				className={css(
					styleSheet.calendarCardTypeColor,
					type === CampaignType.Email ? styleSheet.calendarCardTypeColorEmail : undefined,
					type === CampaignType.Social ? styleSheet.calendarCardTypeColorSocial : undefined,
					type === CampaignType.Blog ? styleSheet.calendarCardTypeColorBlog : undefined
				)}
			/>
			<span className={css(styleSheet.showLabel)}>{label ? label : <CardKeyLabel type={type} />}</span>
		</div>
	);
};

const _ContentCalendarLegend = ({
	campaignTypes,
	onSetCampaignTypes,
	impersonationContext,
}: ContentCalendarLegendProps) => {
	const userSession = useUserSession();
	const account = impersonationContext?.isValid ? impersonationContext.account : userSession?.account;
	const blogsEnabled = account?.features?.blogFeature.enabled;
	const isSocialEnabled = account?.features?.socialMedia.enabled;

	const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = e.target;
		const type = id.replace('type-', '') as CampaignType;
		if (checked) {
			onSetCampaignTypes([...campaignTypes, type]);
			return;
		}

		let nextTypes = campaignTypes.filter(t => t !== type);
		if (nextTypes.length === 0) {
			nextTypes = [CampaignType.Email];
			if (isSocialEnabled) {
				nextTypes.push(CampaignType.Social);
			}
			if (blogsEnabled) {
				nextTypes.push(CampaignType.Blog);
			}
		}
		onSetCampaignTypes(nextTypes);
	};

	return (
		<ul className={css(bs.flex, bs.horizontalStack)}>
			{SupportedCampaignTypes.map((type, i) => {
				if ((type === CampaignType.Blog && !blogsEnabled) || (type === CampaignType.Social && !isSocialEnabled)) {
					return null;
				}

				return (
					<li className={css(bs.block, i === 0 ? styleSheet.listItem : undefined)} key={type}>
						<Checkbox id={`type-${type}`} onChange={handleTypeChange} checked={campaignTypes.includes(type)}>
							<CampaignTypeLabel type={type} />
						</Checkbox>
					</li>
				);
			})}
		</ul>
	);
};
const contentCalendarLegendObserver = observer(_ContentCalendarLegend);
export const ContentCalendarLegend = inject(ImpersonationContextKey)(contentCalendarLegendObserver);

interface ContentCalendarTabsProps {
	campaignTypes: CampaignType[];
	activeCampaignType: CampaignType;
	onSetCampaignActiveCampaignType: (campaignType: CampaignType) => void;
}

export const ContentCalendarTabs = ({
	campaignTypes,
	activeCampaignType,
	onSetCampaignActiveCampaignType,
}: ContentCalendarTabsProps) => {
	const [nextType, setNextType] = React.useState<CampaignType>(null);
	const { clearSelectedSuggestions, selectedSuggestions } = useCampaignCalendarSuggestionsContext();

	const updateCampaignType = (type: CampaignType) => {
		onSetCampaignActiveCampaignType(type);
		clearSelectedSuggestions();
	};

	const onCancelConfirmClose = (_result?: IConfirmationDialogOption, canceled?: boolean) => {
		if (!canceled) {
			updateCampaignType(nextType);
		}
		setNextType(null);
	};

	const onCampaignTypeClick = (type: CampaignType) => {
		const suggestionsWillBeLost = selectedSuggestions.length > 0;
		if (suggestionsWillBeLost) {
			setNextType(type);
		} else {
			updateCampaignType(type);
		}
	};

	const renderCalendarCardType = (type: CampaignType) => {
		return (
			<div>
				<span
					className={css(
						styleSheet.calendarCardTypeColorTab,
						type === CampaignType.Email ? styleSheet.calendarCardTypeColorEmail : undefined,
						type === CampaignType.Social ? styleSheet.calendarCardTypeColorSocial : undefined,
						type === CampaignType.Blog ? styleSheet.calendarCardTypeColorBlog : undefined
					)}
				/>
				<span>
					<CardKeyLabel type={type} />
				</span>
			</div>
		);
	};

	return (
		<>
			<ul className={css(styleSheet.ContentCalendarTabs)}>
				{campaignTypes.map(type => (
					<li className={css(bs.block, styleSheet.calendarCardTabItem)} key={type}>
						<button
							className={css(
								styleSheet.calendarCardTypeTab,
								type === activeCampaignType ? styleSheet.calendarCardTypeTabActive : undefined
							)}
							id={`type-${type}`}
							onClick={() => onCampaignTypeClick(type)}
						>
							{renderCalendarCardType(type)}
						</button>
					</li>
				))}
			</ul>

			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: !!nextType,
					onRequestClose: onCancelConfirmClose,
				}}
				options={DefaultConfirmationOptions}
				title='Changing tabs will unselect all suggestions'
			/>
		</>
	);
};
