import { SvgIcon } from '../SvgIcon';

export const NewDonorIcon = ({ width = 32, height = 32 }: { width?: number; height?: number }) => {
	return (
		<SvgIcon width={width} height={height} viewBox='0 0 32 32'>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-19.5, -44)'>
					<g transform='translate(20, 45)'>
						<circle stroke='#E072BE' cx='15' cy='15' r='15' />
						<path
							d='M11.5714286,11 C10.1537143,11 9,12.1400983 9,13.5410808 C9,16.3896324 12.9797143,19.6456039 15,21 C17.0202857,19.6456039 21,16.3896324 21,13.5410808 C21,12.1400983 19.8462857,11 18.4285714,11 C16.9808571,11 15.8571429,12.3662544 15.8571429,13.5410808 C15.8571429,14.0086397 15.4731429,14.3881077 15,14.3881077 C14.5268571,14.3881077 14.1428571,14.0086397 14.1428571,13.5410808 C14.1428571,12.3662544 13.0191429,11 11.5714286,11 M15,23 C14.8364444,23 14.6728889,22.955375 14.5288889,22.867 C14.2213333,22.678 7,18.179625 7,13.375 C7,10.962625 8.99377778,9 11.4444444,9 C12.8613333,9 14.1742222,9.732375 15,10.82 C15.8257778,9.732375 17.1386667,9 18.5555556,9 C21.0062222,9 23,10.962625 23,13.375 C23,18.179625 15.7786667,22.678 15.4711111,22.867 C15.3271111,22.955375 15.1635556,23 15,23'
							stroke='#FFFFFF'
							strokeWidth='0.75'
							fill='#E072BE'
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
