import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import videoPreviewURL from '../../../assets/video_preview.png';
import { RadioButton } from '../../RadioButton';
import { styleSheet } from './styles';

export const MainImageWidth = 372;
export const HalfImageWidth = MainImageWidth / 2 - 1;
export const ThirdImageWidth = MainImageWidth / 3 - 2;
export const MainImageHeight = 250;
export const HalfImageHeight = 155;
export const SmallImageHeight = 210;

export enum PreviewSize {
	Md = 'md',
	Sm = 'sm',
}

interface IPlatformPreviewImageViewProps {
	onCtaClick?: () => void;
	onClickEnlargeImage?: (imageUrl: string) => void;
	previewSize?: PreviewSize;
	postImages?: Api.IFileAttachment[];
	controls?: boolean;
}

export const MultiScheduleBasicImagePreview: React.FC<IPlatformPreviewImageViewProps> = ({
	postImages,
	previewSize,
}) => {
	const count = postImages?.length;
	const isMultiple = count > 1;
	const imgHeight = previewSize === PreviewSize.Sm ? SmallImageHeight : !isMultiple ? 'auto' : MainImageHeight;

	return (
		<div
			className={css(
				styleSheet.imageDisplayAreaPreview,
				previewSize !== PreviewSize.Sm && isMultiple
					? styleSheet.imageDisplayAreaPreviewNotMultiple
					: styleSheet.imageDisplayAreaPreviewMultiple,
				count > 4 && styleSheet.imageDisplayAreaPreviewColWrap
			)}
		>
			{postImages.map((image, i) => {
				if (((count === 3 || count === 4) && i === 0) || !isMultiple) {
					return (
						<figure key={`${image.url}-${i}`}>
							<img src={image.url} width={MainImageWidth} height={imgHeight} />
						</figure>
					);
				}

				if (count === 3 || count === 2) {
					return (
						<figure className={css(i === 2 && styleSheet.imageDisplayLeft)} key={`${image.url}-${i}`}>
							<img src={image.url} width={HalfImageWidth} height={HalfImageHeight} />
						</figure>
					);
				}

				if (count === 2) {
					return (
						<figure key={`${image.url}-${i}`}>
							<img src={image.url} width={MainImageWidth} height={MainImageHeight} />
						</figure>
					);
				}

				if (count === 4) {
					return (
						<figure className={css(i === 2 && styleSheet.imageDisplayLeft)} key={`${image.url}-${i}`}>
							<img
								src={image.url}
								width={i === 3 ? ThirdImageWidth : ThirdImageWidth + 1}
								height={HalfImageHeight} // These are reverse because of col wrap
							/>
						</figure>
					);
				}

				return (
					<figure
						className={css((i === 1 || i === 3 || i === 4) && styleSheet.imageDisplayLeft)}
						key={`${image.url}-${i}`}
					>
						<img
							src={image.url}
							width={HalfImageWidth}
							height={i === 0 || i === 1 ? HalfImageWidth - 1 : ThirdImageWidth} // These are reverse because of col wrap
						/>
					</figure>
				);
			})}
		</div>
	);
};

export const MultiScheduleLinkedInImagePreview: React.FC<IPlatformPreviewImageViewProps> = ({
	postImages,
	previewSize,
}) => {
	const count = postImages?.length;
	const isMultiple = count > 1;
	const isOdd = count % 2;
	const imgHeight = previewSize === PreviewSize.Sm ? SmallImageHeight : !isMultiple ? 'auto' : MainImageHeight;

	return (
		<div className={css(styleSheet.imageDisplayAreaPreviewNotMultiple, styleSheet.imageDisplayAreaPreview)}>
			{postImages.map((image, i) => {
				if (((count === 3 || count === 4) && i === 0) || !isMultiple) {
					return (
						<figure key={`${image.url}-${i}`}>
							<img src={image.url} width={MainImageWidth} height={imgHeight} />
						</figure>
					);
				}

				if (count === 3 || count === 2) {
					return (
						<figure className={css(isOdd && i === 2 && styleSheet.imageDisplayLeft)} key={`${image.url}-${i}`}>
							<img src={image.url} width={HalfImageWidth} height={count === 2 ? 476 : HalfImageHeight} />
						</figure>
					);
				}

				if (count === 4) {
					return (
						<figure className={css((i === 2 || i === 3) && styleSheet.imageDisplayLeft)} key={`${image.url}-${i}`}>
							<img src={image.url} width={i === 3 ? ThirdImageWidth : ThirdImageWidth + 1} height={HalfImageHeight} />
						</figure>
					);
				}

				return (
					<figure
						className={css((i === 1 || i === 3 || i === 4) && styleSheet.imageDisplayLeft)}
						key={`${image.url}-${i}`}
					>
						<img
							src={image.url}
							width={i === 0 || i === 1 ? HalfImageWidth : i === 4 ? ThirdImageWidth : ThirdImageWidth + 1}
							height={i === 0 || i === 1 ? 240 : HalfImageHeight}
						/>
					</figure>
				);
			})}
		</div>
	);
};

export const MultiScheduleInstagramImagePreview: React.FC<IPlatformPreviewImageViewProps> = props => {
	const { postImages, previewSize } = props;
	const [selectedImage, setSelectedImage] = React.useState(0);

	const onSelectedTabChanged = (indx: number) => () => {
		setSelectedImage(indx);
	};
	const count = postImages?.length;
	const isMultiple = count > 1;
	const imgHeight = previewSize === PreviewSize.Sm ? SmallImageHeight : !isMultiple ? 'auto' : MainImageHeight;

	return (
		<>
			<figure
				className={css(
					previewSize !== PreviewSize.Sm ? styleSheet.imageDisplayAreaPreviewNotMultiple : undefined,
					styleSheet.imageDisplayAreaPreview,
					styleSheet.imageDisplayAreaPreviewInstagram
				)}
			>
				<img
					className={css(styleSheet.tab)}
					src={postImages[selectedImage].url}
					width={MainImageWidth}
					height={imgHeight}
				/>
			</figure>
			<div
				className={`${css(
					styleSheet.tabNavigation,
					previewSize === PreviewSize.Sm ? styleSheet.radioBackground : undefined
				)}`}
			>
				{postImages.map((_, i) => {
					return (
						<RadioButton
							checked={i === selectedImage || false}
							className={css(styleSheet.circle)}
							id={`${postImages[selectedImage].url}-${i}`}
							key={`${postImages[selectedImage].url}-${i}`}
							onChange={onSelectedTabChanged(i)}
						/>
					);
				})}
			</div>
		</>
	);
};

export const MultiScheduleBasicVideoPreview: React.FC<IPlatformPreviewImageViewProps> = ({
	postImages,
	previewSize,
	controls,
}) => {
	return (
		<div
			className={css(
				styleSheet.imageDisplayAreaPreview,
				previewSize !== PreviewSize.Sm
					? styleSheet.imageDisplayAreaPreviewNotMultiple
					: styleSheet.imageDisplayAreaPreviewMultiple
			)}
		>
			{postImages.map((image, i) => {
				return (
					<div className={css(styleSheet.videoWrapper)} key={`${image.url}-${i}`}>
						{controls ? (
							<video
								key={`${image.url}-${i}`}
								src={image.url}
								width={MainImageWidth}
								height={MainImageHeight} // These are reverse because of col wrap
								controls={controls}
							/>
						) : (
							<img src={videoPreviewURL} width='100%' />
						)}
					</div>
				);
			})}
		</div>
	);
};
