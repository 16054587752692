import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../../models/UiUtils';
import {
	aceGray,
	brandPrimary,
	brandPrimaryHover,
	brandPrimaryText,
	brandSecondary,
	charmGray,
	green4,
	inputBorderColor,
	lightGray,
	mention,
	prestoPurple,
	shuffleGray,
	success,
	titles,
	white,
} from '../../../styles/colors';
import { BreakPoints, CssSelectors, Layouts, ctaButtonProps, truncateTextStyle } from '../../../styles/styles';

const border = `1px solid ${inputBorderColor}`;
const monthTitleHeight = 40;
const eventIconSize = 30;
const monthEventIconSize = 18;
const calendarTitleBarHeight = 48;
const calendarTitleBarBottomMargin = 16;
const calendarMenuHeight = 42;
const calendarMenuTopMargin = 16;
const calendarMenuBottomMargin = 8;
const calendarMonthTitleCalculatedHeight = 22;

const calendarCardColor = 'rgba(13, 15, 17, 0.7)';

export const styleSheet = StyleSheet.create({
	calendarCardWrap: {
		marginBottom: 2,
		display: 'block',
		width: '100%',
		transition: 'all 300ms',
		':focus': {
			outline: 'none',
			boxShadow: '0 1px 2px 2px rgba(0, 0, 0, 0.3)',
			borderRadius: 8,
		},
		':hover': {
			boxShadow: '0 1px 2px 0px rgba(0, 0, 0, 0.3)',
			borderRadius: 8,
		},
	},
	calendarEventCardImageWrap: {
		position: 'relative',
		maxWidth: '100%',
		height: 67,
		overflow: 'hidden',
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		...CssSelectors.allDescendants(
			{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				maxWidth: '100%',
			},
			'img'
		),
	},
	calendarImgCardWeekly: {
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
	},
	calendarCard: {
		borderTopRightRadius: 8,
		borderTopLeftRadius: 8,
		borderRadius: 8, // remove when footer content is available
		border: '1px solid transparent',
		padding: 8,
		color: calendarCardColor,
		paddingTop: 11,
		fontSize: 10,
		overflow: 'hidden',
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.checkbox-content'
		),
	},
	calendarCardFeatured: {
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
	},
	calendarEventCardTitleContainer: {
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	calendarEventCardTitle: {
		flexGrow: 1,
	},
	calendarCardQuarterly: {
		padding: '12px 30px 12px 12px',
	},
	calendarCardWeekWrap: {
		marginBottom: 8,
	},
	calendarCardisCompletedEvent: {
		opacity: 0.5,
	},
	calendarCardQuarterlyWrap: {
		marginBottom: 8,
	},
	calendarCardMonthWrap: {
		maxWidth: '80%',
		margin: '0 auto 4px',
	},
	calendarCardEmail: {
		background: green4,
	},
	calendarCardSocial: {
		background: brandPrimaryHover,
	},
	calendarCardBlog: {
		background: prestoPurple,
		color: white,
	},
	calendarCardPending: {
		border: `1px dashed ${brandSecondary}`,
	},
	calendarCardBlogPending: {
		border: `1px dashed ${white}`,
	},
	calendarCardSuggestion: {
		border: `1px dashed ${brandPrimary}`,
		color: brandPrimary,
	},
	calendarCardEmailSuggestion: {
		background: hexToRGBA(green4, 0.25),
	},
	calendarCardSocialSuggestion: {
		background: hexToRGBA(brandPrimaryHover, 0.25),
	},
	calendarCardBlogSuggestion: {
		background: hexToRGBA(prestoPurple, 0.25),
	},
	calendarCardFooter: {
		padding: '8px 4px',
		background: shuffleGray,
		color: charmGray,
		fontSize: 10,
		borderBottomRightRadius: 8,
		borderBottomLeftRadius: 8,
		height: 8,
	},
	calendarOption: {
		color: aceGray,
		height: 42,
		border: `1px solid transparent !important`,
		borderRadius: 8,
		':focus': {
			outline: 'none',
			border: `1px solid ${inputBorderColor} !important`,
			background: hexToRGBA(white, 0.7),
		},
	},
	activeCalendarOption: {
		border: `1px solid ${brandPrimary} !important`,
		color: brandPrimary,
		borderRadius: 8,
		background: white,
	},
	body: {
		display: 'flex',
		height: `calc(100% - ${calendarMenuHeight + calendarMenuBottomMargin + calendarMenuTopMargin + calendarTitleBarHeight + calendarTitleBarBottomMargin}px)`,
		justifyContent: 'space-evenly',
		position: 'relative',
	},
	blogCard: {
		background: '#E8F3F9',
		borderRadius: 3,
		boxSizing: 'border-box',
		position: 'relative',
		width: '100%',
	},
	activeMonthOrDay: {
		color: brandPrimary,
	},
	buttonChevron: {
		fontSize: 0,
		color: 'transparent',
		border: '1px solid transparent',
		trasition: 'all 300ms',
		width: 22,
		height: 22,
		':focus': {
			outline: 'none',
			border: `1px solid ${brandPrimary}`,
		},
	},
	buttonChevronLeft: {
		margin: '0 20px 0 0',
	},
	buttonChevronRight: {
		margin: '0 0 0 20px',
	},
	calendarMonthTitleText: {
		fontFamily: 'Lexend-Regular, sans-serif',
	},
	quarterTitle: {
		color: titles,
	},
	pastQuarterTitle: {
		color: charmGray,
	},
	pastWeekTitle: {
		color: charmGray,
	},
	CampaignCalendarTitleBar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	calendarTypeContainer: {
		display: 'flex',
		gap: 16,
	},
	leftSection: {
		display: 'flex',
		flexDirection: 'column',
	},
	rightSection: {
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'column',
		padding: 16,
		gap: 16,
		width: '100%',
	},
	calendarMenu: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: `0 0 ${calendarMenuBottomMargin}px`,
		maxWidth: 260,
		background: lightGray,
		borderRadius: 8,
		padding: 4,
		height: calendarMenuHeight,
		...CssSelectors.allDescendants(
			{
				width: 86,
				padding: '8px 0',
				textAlign: 'center',
				border: '1px solid transparent',
				transition: 'all 300ms',
				fontWeight: 600,
			},
			'button'
		),
	},
	bodyMonth: {
		border: 'none',
		overflow: 'auto',
	},
	calendarDayTitle: {
		height: 'auto',
		margin: 0,
		textAlign: 'right',
	},
	calendarEvent: {
		boxSizing: 'border-box',
		fontSize: 12,
		padding: 10,
		margin: 2,
	},
	calendarEventAprroveButton: {
		...ctaButtonProps('#EB8638'),
		fontSize: 14,
		padding: '4px 14px',
		width: '100%',
	},
	calendarEventApproveButtonDay: {
		fontSize: 10,
		padding: '4px 10px',
	},
	calendarEventClickable: {
		cursor: 'pointer',
	},
	calendarEventDate: {},
	calendarEventDay: {
		fontSize: 10,
		padding: 6,
	},
	calendarEventFooter: {
		marginTop: 10,
	},
	calendarEventHeader: {
		display: 'flex',
		flexWrap: 'wrap',
		...CssSelectors.allChildren({
			':first-child': {
				paddingRight: 3,
			},
		}),
	},
	calendarEventIcon: {
		alignItems: 'center',
		borderRadius: '50%',
		boxSizing: 'border-box',
		display: 'flex',
		flexShrink: 0,
		height: eventIconSize,
		justifyContent: 'center',
		position: 'relative',
		width: eventIconSize,
	},
	calendarEventIconDay: {
		height: monthEventIconSize,
		width: monthEventIconSize,
		...CssSelectors.allChildren(
			{
				height: 14,
				width: 12,
			},
			'svg'
		),
	},
	calendarEventScheduled: {
		background: '#E8F3F9',
		color: brandPrimaryText,
		...CssSelectors.allDescendants(
			{
				background: brandSecondary,
			},
			'.campaign-calendar-event-icon'
		),
	},
	calendarEventSender: {
		marginBottom: 4,
	},
	calendarEventSent: {
		background: '#F1FCE7',
		color: '#65AF1A',
		...CssSelectors.allDescendants(
			{
				background: success,
			},
			'.campaign-calendar-event-icon'
		),
		...CssSelectors.allDescendants(
			{
				fontStyle: 'normal',
			},
			'.campaign-calendar-event-status'
		),
	},
	calendarEventStatus: {
		fontStyle: 'italic',
	},
	calendarEventSubject: {
		borderBottom: '1px solid',
		marginBottom: 8,
		marginTop: 4,
		maxHeight: 140,
		paddingBottom: 6,
		...truncateTextStyle,
		whiteSpace: 'normal',
	},
	calendarEventSubjectDay: {
		borderBottom: 'none',
		marginBottom: 4,
		paddingBottom: 0,
		whiteSpace: 'nowrap',
	},
	calendarEventWaitingOnApproval: {
		background: '#FFF4EB',
		color: '#EB8638',
		...CssSelectors.allDescendants(
			{
				background: '#EB8638',
			},
			'.campaign-calendar-event-icon'
		),
	},
	calendarGhostIcon: {
		height: 'auto',
		width: 50,
	},
	calendarMonth: {
		boxSizing: 'border-box',
		position: 'relative',
		width: '100%',
		minHeight: '100%',
		height: '100%',
	},
	calendarMonthEventsFirst: {
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
	},
	calendarMonthEventsLast: {
		borderTopRightRadius: 8,
		borderBottomRightRadius: 8,
	},
	calendarMonthQuarterly: {},
	calendarMonthDay: {
		':not(:last-child)': {
			borderRight: 'none',
		},
	},
	calendarMonthDayToday: {
		background: hexToRGBA(brandPrimaryHover, 0.05),
	},
	calendarMonthDropTarget: {
		border: `2px dashed ${brandSecondary}`,
		bottom: 0,
		boxSizing: 'border-box',
		height: '100%',
		left: 0,
		position: 'absolute',
		right: 0,
		top: 0,
		width: '100%',
		zindex: 1,
	},
	calendarMonthEmptyPlaceholder: {
		alignItems: 'center',
		color: '#AAA',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 14,
		justifyContent: 'flex-start',
		marginTop: 15,
		minHeight: 105,
		textAlign: 'center',
	},
	calendarMonthEmptyPlaceholderIcon: {
		background: '#EFEFEF',
	},
	calendarMonthEvents: {
		height: `calc(100% - ${calendarMonthTitleCalculatedHeight + 8}px)`,
		margin: '4px 2px 2px',
		overflowX: 'hidden',
		overflowY: 'auto',
		':first-of-type': {
			borderTopLeftRadius: 8,
		},
	},
	calendarEventsInnerToday: {
		background: hexToRGBA(brandPrimaryHover, 0.05),
	},
	calendarMonthQuarterlyEvents: {
		height: `calc(100% - ${monthTitleHeight + calendarMenuHeight}px)`,
		border,
		margin: 0,
		padding: 8,
	},
	calendarMonthQuarterlyEventsWithSuggestions: {
		[BreakPoints.forMaxWidth(1262)]: {
			height: `calc(100% - ${monthTitleHeight * 3 + calendarMonthTitleCalculatedHeight}px)`,
		},
	},
	calendarMonthWeeklyEvents: {
		textAlign: 'right',
		border,
		margin: 0,
		fontWeight: 600,
		padding: 4,
	},
	calendarMonthTitle: {
		boxSizing: 'border-box',
		fontSize: 14,
		height: monthTitleHeight,
		marginBottom: 8,
		padding: '8px 10px',
		textAlign: 'center',
	},
	calendarMonthTitleDay: {
		height: 'auto',
		margin: '0 0 4px',
		paddingBottom: 0,
		paddingTop: 4,
		textAlign: 'right',
	},
	calendarMonthWeekTitleDay: {
		textAlign: 'center',
	},
	calendarMonthWeekDay: {
		display: 'flex',
		justifyContent: 'space-evenly',
		flexDirection: 'column',
		maxWidth: `${100 / 7}%`,
		...CssSelectors.allDescendants({
			'.calendar-events:not(:last-child)': {
				borderRight: 0,
			},
		}),
	},
	calendarTitleBar: {
		display: 'flex',
		height: calendarTitleBarHeight,
		marginBottom: calendarTitleBarBottomMargin,
		...Layouts.horizontalStack(40),
		width: 350,
	},
	calendarTitleBarWeekly: {
		width: 475,
	},
	calendarTitleBarIconWrap: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		top: 0,
		left: 0,
	},
	calendarTitleBarBackIcon: {
		height: 'auto',
		transform: 'rotate3d(0,0,1,90deg)',
		width: 20,
	},
	calendarTitleBarContent: {
		display: 'flex',
		justifyContent: 'space-between',
		flex: '0 1 auto',
		margin: 0,
		alignItems: 'center',
		width: '100%',
	},
	calendarTitleBarContentNoAccessory: {
		width: '100%',
	},
	calendarTitleBarForwardIcon: {
		height: 'auto',
		transform: 'rotate3d(0,0,1,-90deg)',
		width: 20,
	},
	calendarCardApproveForwardIcon: {
		height: 'auto',
		transform: 'rotate3d(0,0,1,-90deg)',
		width: 11,
	},
	calendarTitleBarGhost: {
		height: 44,
	},
	calendarTypeDropdown: {
		flex: 1,
		height: 40,
		marginLeft: 20,
		maxWidth: 140,
		width: 'auto',
	},
	calendarTypeTrigger: {
		width: 90,
	},
	container: {
		background: '#fff',
	},
	dateAndStatus: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingTop: 2,
		...CssSelectors.allChildren(
			{
				':first-child': {
					paddingRight: 3,
				},
				paddingBottom: 2,
			},
			'span'
		),
	},
	dayPicker: {
		flexShrink: 0,
		margin: 'auto',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				pointerEvents: 'auto',
				color: `${aceGray} !important`,
			},
			'.DayPicker-Day--disabled'
		),
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allChildren(
					{
						':first-child': {
							background: '#fbfbfb',
						},
						':last-child': {
							background: '#fbfbfb',
						},
					},
					'div'
				),
				display: 'flex',
				justifyContent: 'space-evenly',
			},
			'.DayPicker-Week'
		),
		...CssSelectors.allDescendants(
			{
				display: 'flex',
				justifyContent: 'center',
				marginTop: 20,
				width: '100%',
			},
			'.DayPicker-Weekdays'
		),
		...CssSelectors.allDescendants(
			{
				display: 'flex',
				fontSize: 14,
				justifyContent: 'center',
				width: '100%',
				color: `${charmGray} !important`,
				fontFamily: 'Lexend-Regular, sans-serif',
				textTransform: 'capitalize',
			},
			'.DayPicker-Weekday'
		),
		...CssSelectors.allDescendants(
			{
				alignItems: 'center',
				display: 'flex',
				height: 30,
				width: '100%',
			},
			'.DayPicker-WeekdaysRow'
		),
		...CssSelectors.allDescendants(
			{
				fontSize: '10px !important',
				height: '16vh',
				maxHeight: 220,
				minHeight: 175,
				outline: '1px solid #D3DBDE',
				overflow: 'hidden',
				padding: 0,
				textAlign: 'start',
				verticalAlign: 'top',
				width: '100%',
			},
			'.DayPicker-Day'
		),
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.DayPicker-wrapper'
		),
		...CssSelectors.allDescendants(
			{
				display: 'flex',
				flexDirection: 'column',
				margin: 0,
				width: '98%',
			},
			'.DayPicker-Month'
		),
		...CssSelectors.allDescendants(
			{
				borderRadius: 0,
			},
			'.DayPicker-Day--selected'
		),
	},
	dayPickerGhost: {
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allChildren(
					{
						background: 'transparent !important',
					},
					'div'
				),
			},
			'.DayPicker-Week'
		),
	},
	imageOpacity: {
		opacity: 0.5,
	},
	infoWrap: {
		display: 'block',
		border: `1px solid ${brandPrimary}`,
		background: mention,
		borderRadius: 8,
		padding: 8,
		fontSize: 12,
		margin: 0,
	},
	notFeaturedSection: {
		borderBottom: `1px solid ${inputBorderColor}`,
		margin: 8,
	},
	platformIconRow: {
		alignItems: 'center',
		display: 'flex',
	},
	platformIconRowDay: {
		alignItems: 'flex-start',
		flexDirection: 'column',
		height: 36,
		justifyContent: 'space-around',
	},
	platformImages: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				height: 20,
				marginRight: 6,
			},
			'img'
		),
	},
	platformImagesDay: {
		...CssSelectors.allChildren(
			{
				height: 14,
				marginRight: 4,
			},
			'img'
		),
	},
	isWeeklyDayDate: {
		padding: '6px 10px 5px',
	},
	platformSection: {
		display: 'flex',
		paddingRight: 4,
	},
	socialCard: {
		background: '#E8F3F9',
		borderRadius: 3,
		boxSizing: 'border-box',
		position: 'relative',
		width: '100%',
	},
	socialCardPending: {
		background: '#FFF4EB',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: '#eb8638',
		position: 'relative',
		width: '100%',
	},
	socialCardSent: {
		background: '#F1FCE7',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: '#65AF1A',
		position: 'relative',
		width: '100%',
	},
	socialTextPending: {
		color: '#EB8638',
	},
	socialtextScheduled: {
		color: brandPrimaryText,
	},
	socialtextSent: {
		color: '#65AF1A',
	},
	suggestionCard: {
		background: 'transparent',
		borderRadius: 3,
		boxSizing: 'border-box',
		position: 'relative',
		width: '100%',
	},
	suggestionCardBorderLayer: {
		backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
			`<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect rx='3' ry='3' width='100%' height='100%' fill='#FFF' stroke='${brandSecondary}' stroke-width='1' stroke-dasharray='6' stroke-linecap='round'/></svg>`
		)}")`,
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 0,
	},
	suggestionCardContent: {
		...Layouts.verticalStack(8),
		boxSizing: 'border-box',
		color: titles,
		fontSize: 12,
		height: '100%',
		padding: 10,
		position: 'relative',
		width: '100%',
		zIndex: 1,
	},
	suggestionCardContentDay: {
		...Layouts.verticalStack(0),
		fontSize: 10,
		padding: 6,
	},
	suggestionCardIcon: {
		background: brandPrimary,
	},
	suggestionCardName: {
		fontStyle: 'italic',
	},
	suggestionCardTitle: {
		color: brandPrimaryText,
		fontSize: 14,
		fontStyle: 'italic',
	},
	socialMediaIcon: {
		width: 15,
		marginRight: 4,
	},
	timeWrap: {
		fontSize: 32,
		fontFamily: 'Lexend-Regular, sans-serif',
	},
	today: {
		color: brandPrimary,
	},
	dayOfTheMonth: {
		fontSize: 12,
		borderRadius: '50%',
		fontWeight: 600,
	},
	triggerContainer: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		width: 250,
	},
	triggerContainerShowing: {
		maxWidth: 350,
		width: 'auto',
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
		width: 120,
	},
	triggerValue: {
		paddingLeft: 10,
		paddingRight: 5,
	},
	eventCheckbox: {
		color: brandPrimary,
		fontSize: 10,
	},
	selectLabel: {
		color: charmGray,
		fontSize: 11,
		marginBottom: 4,
		textTransform: 'uppercase',
		display: 'block',
	},
	senderInitials: {
		minWidth: 16,
		borderLeft: `2px ${calendarCardColor} solid`,
		paddingLeft: 4,
		marginRight: 4,
		flexShrink: 0,
	},
	calendarTitleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});
