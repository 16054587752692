import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../models/UiUtils';
import {
	brandPrimary,
	brandPrimaryText,
	charmGray,
	gray4,
	green4,
	illusionYellow,
	lightGray,
	prestoPurple,
	shuffleGray,
	spanishBlue,
	titles,
	white,
} from '../../styles/colors';
import { CssSelectors, Layouts, cardStyle, ctaButtonReverseProps } from '../../styles/styles';

const suggestionsToggleContainerHeight = 62;
const suggestionsToggleContainerBottomPadding = 10;
const pendingApprovalAlertHeight = 50;
const pendingApprovalAlertBottomMargin = 10;
const pendingApprovalAlertBorderWidth = 1;

export const styleSheet = StyleSheet.create({
	calendar: {
		height: '100%',
	},
	calendarContainer: {
		width: `calc(100% - 22rem)`,
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 0%',
		paddingBottom: 2,
	},
	calendarContainerCard: {
		...cardStyle,
		flex: '1 1 0%',
		background: '#fff',
		boxSizing: 'border-box',
		overflow: 'hidden',
		padding: 20,
	},
	calendarContainerCardWithAlert: {
		height: `calc(100vh - ${200 + pendingApprovalAlertHeight + 2 * pendingApprovalAlertBorderWidth + pendingApprovalAlertBottomMargin}px)`,
	},
	calendarContainerCardWithAlertAndSuggestions: {
		height: `calc(100vh - ${140 + pendingApprovalAlertHeight + 2 * pendingApprovalAlertBorderWidth + pendingApprovalAlertBottomMargin + suggestionsToggleContainerHeight + suggestionsToggleContainerBottomPadding}px)`,
	},
	calendarContainerCardWithSuggestions: {
		height: `calc(100vh - ${140 + suggestionsToggleContainerHeight + suggestionsToggleContainerBottomPadding}px)`,
	},
	campaignSearch: {
		flexGrow: 1,
	},
	campaignSelector: {
		height: `calc(100vh - 250px)`,
	},
	cardContainer: {
		height: 'calc(100% - 75px)',
	},
	campaignTemplateCardDragging: {
		opacity: 0.95,
	},
	campaignTemplateCardDraggingOverDay: {
		opacity: 0.9,
	},
	contentContainer: {
		display: 'flex',
		boxSizing: 'border-box',
		height: '100%',
		paddingTop: 8,
		gap: 16,
	},
	container: {
		boxSizing: 'border-box',
		height: '100%',
		width: '100%',
	},
	checkboxContent: {
		display: 'flex',
		alignItems: 'center',
	},
	filterDropdownContainer: {
		display: 'flex',
		flex: 4,
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		...CssSelectors.allChildren({
			marginLeft: 10,
		}),
		width: '100%',
	},
	lastSuggestionRadio: {
		marginLeft: 10,
	},
	newCampaignCta: {
		marginLeft: 24,
		maxHeight: 40,
		minHeight: 40,
	},
	newContentModal: {
		color: titles,
		fontSize: 20,
		width: 420,
	},
	newContentModalBody: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		...Layouts.verticalStack(12),
	},
	newContentModalFooter: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 25,
	},
	newContentModalSubtitle: {
		color: '#4A4A4A',
		fontSize: 14,
	},
	pendingApprovalAlert: {
		alignItems: 'center',
		backgroundColor: hexToRGBA(illusionYellow, 0.25),
		borderColor: illusionYellow,
		borderRadius: 8,
		borderStyle: 'solid',
		borderWidth: pendingApprovalAlertBorderWidth,
		display: 'flex',
		marginBottom: pendingApprovalAlertBottomMargin,
		height: pendingApprovalAlertHeight,
		padding: '0 4px 0 24px',
		gap: 12,
	},
	pendingApprovalAlertButton: {
		...ctaButtonReverseProps(white, brandPrimary),
		backgroundColor: white,
		fontSize: 14,
		padding: '4px 14px',
	},
	pendingApprovalAlertButtonContainer: {
		marginRight: 16,
		marginLeft: 'auto',
	},
	pendingApprovalAlertText: {
		color: titles,
		fontSize: 14,
		marginLeft: 16,
	},
	pendingApprovalText: {
		color: titles,
		fontSize: 12,
		display: 'inline-block',
		marginRight: 10,
	},
	searchContainer: {
		display: 'flex',
		marginBottom: 20,
	},
	suggestionsToggleContainer: {
		marginLeft: 8,
		...CssSelectors.allChildren(
			{
				minHeight: suggestionsToggleContainerHeight / 2,
			},
			'label'
		),
	},
	suggestionToggleWrap: {
		width: 190,
		marginRight: 16,
	},
	suggestionToggleRow: {
		alignItems: 'center',
	},
	suggestionsToggleText: {
		color: brandPrimary,
		fontSize: 14,
		marginLeft: 6,
	},
	suggestionTypeToggle: {
		display: 'flex',
		fontSize: 12,
		height: suggestionsToggleContainerHeight,
		justifyContent: 'space-between',
		...CssSelectors.allDescendants(
			{
				fontSize: 12,
			},
			'div'
		),
	},
	selectLabel: {
		color: charmGray,
		fontSize: 11,
		marginBottom: 4,
		textTransform: 'uppercase',
		display: 'block',
	},
	campaignTypeLabel: {
		marginBottom: 0,
		display: 'block',
	},
	triggerContainer: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		width: 200,
	},
	triggerContainerCampaignType: {
		width: 125,
	},
	triggerContainerShowing: {
		maxWidth: 350,
		width: 'auto',
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
		width: 120,
	},
	triggerValue: {
		paddingLeft: 10,
		paddingRight: 5,
	},
	typesKey: {
		background: white,
		padding: 10,
		boxShadow: `0 0 6px 0 ${hexToRGBA(gray4, 0.1)}`,
		borderRadius: 8,
		alignItems: 'center',
		display: 'flex',
		width: 380,
		marginBottom: 8,
	},
	calendarCardTypeColor: {
		height: 12,
		width: 12,
		borderRadius: '50%',
		marginRight: 8,
		display: 'inline-block',
	},
	calendarCardTypeColorTab: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: 16,
		height: 16,
		transform: 'rotate(48deg) translate(0px, -11px)',
	},
	ContentCalendarTabs: {
		display: 'flex',
		width: 330,
		gap: 4,
	},
	calendarCardTabItem: {
		flex: '1 0 107px',
	},
	calendarCardTypeTab: {
		display: 'inline-block',
		width: '100%',
		height: 44,
		color: charmGray,
		background: lightGray,
		position: 'relative',
		textAlign: 'center',
		border: `1px solid ${shuffleGray}`,
		overflow: 'hidden',
		borderRadius: 0,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 3,
		borderBottom: 'none',
		marginTop: -1,
	},
	calendarCardTypeTabActive: {
		color: brandPrimaryText,
		background: white,
	},
	calendarCardTypeColorEmail: {
		background: green4,
	},
	calendarCardTypeColorSocial: {
		background: spanishBlue,
	},
	calendarCardTypeColorBlog: {
		background: prestoPurple,
	},
	showSpan: {
		display: 'none',
		'@media screen and  (min-width: 1332px)': {
			display: 'inline',
		},
	},
	listItem: {
		marginLeft: '0.75rem',
	},
	showLabel: {
		display: 'none',
		'@media screen and  (min-width: 1270px)': {
			display: 'inline',
		},
	},
	suggestedButton: {
		height: 42,
	},
	pendingApprovalCounts: {
		marginLeft: 32,
		fontSize: 14,
		display: 'flex',
		gap: 12,
	},
});
