import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	height?: number;
	width?: number;
}

export const NewDonorAutomationTriggerGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles = [], height = 172, width = 172 } = props;
	return (
		<SvgIcon
			className={`new-donors-automation-trigger-graphic ${className || ''} ${css(...styles)}`}
			height={width}
			width={height}
			viewBox='0 0 172 172'
		>
			<defs>
				<linearGradient x1='50.1522038%' y1='-26.9879532%' x2='50.1522038%' y2='99.6955925%'>
					<stop stopColor='#FFFFFF' stopOpacity='0.148438354' offset='0%' />
					<stop stopColor='#00CCFF' stopOpacity='0.199778737' offset='100%' />
				</linearGradient>
				<circle cx='80' cy='80' r='80' />
			</defs>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-69.5, -23.5)'>
					<g transform='translate(70, 24)'>
						<mask id='new-donor-mask1' fill='white'>
							<use xlinkHref='#path-2' />
						</mask>
						<use fill='url(#linearGradient-1)' xlinkHref='#path-2' />
						<g mask='url(#new-donor-mask1)' fillRule='nonzero'>
							<g transform='translate(-34, 42)'>
								<rect fill='#FFFFFF' x='66' y='41' width='95' height='81' />
								<rect fill='#0066FF' x='102' y='56' width='24' height='65' />
								<rect fill='#E072BE' x='102' y='56' width='24' height='65' />
								<polygon
									fill='#E9EBEF'
									style={{ mixBlendMode: 'multiply' }}
									points='66 47 66 59.996 161 66 160.719985 49.964'
								/>
								<rect fill='#FFFFFF' x='61' y='33' width='106' height='23' />
								<rect fill='#E072BE' x='102' y='31' width='24' height='25' />
								<path
									d='M111.193927,9.51055592 C117.29482,18.8993948 111.546639,34 111.546639,34 C111.546639,34 95.906962,33.878283 89.8060716,24.4894441 C83.7051813,15.1006052 89.45336,0 89.45336,0 C89.45336,0 105.093037,0.121728179 111.193927,9.51055592 Z'
									fill='#E072BE'
								/>
								<path
									d='M113.806072,9.51055592 C107.705181,18.8993948 113.45336,34 113.45336,34 C113.45336,34 129.093038,33.878283 135.193928,24.4894441 C141.294819,15.1006052 135.54664,0 135.54664,0 C135.54664,0 119.906962,0.121728179 113.806072,9.51055592 Z'
									fill='#E072BE'
								/>
								<circle id='Oval' fill='#FFFFFF' cx='97.5' cy='13.5' r='2.5' />
								<circle id='Oval' fill='#FFFFFF' cx='111.5' cy='38.5' r='2.5' />
								<circle id='Oval' fill='#FFFFFF' cx='111.5' cy='92.5' r='2.5' />
								<circle id='Oval' fill='#FFFFFF' cx='103.5' cy='22.5' r='1.5' />
								<circle id='Oval' fill='#FFFFFF' cx='128.5' cy='8.5' r='1.5' />
								<circle id='Oval' fill='#FFFFFF' cx='117.5' cy='47.5' r='1.5' />
								<circle id='Oval' fill='#FFFFFF' cx='108.5' cy='69.5' r='1.5' />
								<circle id='Oval' fill='#FFFFFF' cx='116.5' cy='76.5' r='1.5' />
								<circle id='Oval' fill='#EFB59C' cx='63' cy='34' r='6' />
								<path
									d='M61.2437554,70 C61.1661205,70 61.0881405,69.9966562 61.0098153,69.9899685 C60.225706,69.9261915 59.5067584,69.5395011 59.0334974,68.926994 C58.5602364,68.3144868 58.3776148,67.5343418 58.5314882,66.7824504 C58.5494703,66.6960996 58.5720895,66.6107276 58.5992525,66.5266864 L49.5015753,56.0422701 C48.892868,55.3352601 48.8322232,54.3228863 49.3523797,53.5516711 L49.3688758,53.5320853 L57.5578999,45.6641077 C58.4888412,44.7697148 59.9876144,44.7800249 60.9055034,45.687136 C61.8233925,46.5942471 61.8128115,48.0546546 60.8818702,48.9490475 C60.8716096,48.9589182 60.8612456,48.9686952 60.850778,48.9783786 L54.9197659,54.4725388 L61.4127548,64.6269132 C61.535734,64.6331812 61.6581089,64.6478652 61.7789746,64.6708566 C63.1703998,64.941428 64.1249119,66.1954965 63.986722,67.5714658 C63.8485322,68.947435 62.6626235,69.9974036 61.2437638,70 L61.2437554,70 Z'
									fill='#EFB59C'
								/>
								<polygon fill='#FFB8B8' points='73.0006162 118 76.3885876 118 78 107 73 107.000259' />
								<path
									d='M72,117.000244 L78.1075165,117 L78.1077618,117 C80.2573205,116.99994 82,118.734199 82,120.87358 L82,120.999624 L72.0001805,121 L72,117.000244 Z'
									fill='#2F2E41'
								/>
								<polygon fill='#FFB8B8' points='43 114.529097 45.650954 116 53 107.170922 49.0878227 105' />
								<path
									d='M43.0100344,113 L48.140701,115.917085 L48.1409079,115.917206 C49.9466451,116.943906 50.5388943,119.174065 49.4637343,120.898409 L49.4636544,120.898531 L49.4003892,121 L41,116.223839 L43.0100344,113 Z'
									fill='#2F2E41'
								/>
								<path
									d='M77.20902,114 L73.8094822,114 C73.2392994,114.000914 72.7607627,113.569259 72.7014325,113.000504 L69.2742037,80.6898963 C69.2564166,80.5227085 69.1291934,80.3881802 68.9636685,80.3615303 C68.7981436,80.3348804 68.6353295,80.4227121 68.5662606,80.5759159 L62.3834819,94.3066803 L51.437196,112.208376 C51.1443279,112.682768 50.5485877,112.869538 50.0384699,112.646891 L45.6655158,110.717265 C45.3730284,110.588109 45.1498824,110.339257 45.0527433,110.033902 C44.9556042,109.728546 44.9938167,109.396062 45.1576771,109.120879 L55.2203263,92.2223252 L58.3064558,77.9844059 C57.3358282,71.8098834 61.4968798,62.6300985 61.5390775,62.53792 L61.5596974,62.4930589 L73.021473,58 L73.0789534,58.0705759 C77.5991508,72.9478159 80.3535631,88.9582558 78.3197977,112.97601 C78.2678311,113.553357 77.7870059,113.996614 77.20902,114 Z'
									fill='#0066FF'
								/>
								<path
									d='M61.1829141,64 L61.1280517,63.8903622 C61.1018308,63.8379079 58.4824441,58.5856995 56.463241,52.9722062 C55.6786534,50.7774644 55.8977017,48.3705494 57.0671393,46.336536 C58.2517751,44.2814769 60.288324,42.7926453 62.6816974,42.2319787 L62.6816974,42.2319787 C66.929165,41.2641082 71.2816858,43.3991714 72.9133605,47.2509662 C74.5150268,51.058744 74.0045704,55.1230817 73.5241885,58.3193464 L73.5146224,58.3833852 L73.4533552,58.4112648 L61.1829141,64 Z'
									fill='#F8D72A'
								/>
								<path
									d='M71,30 L61,30 L61,25.8962458 C63.1948853,25.0753103 65.3426917,24.3771544 66.6410235,25.8962458 C69.0484087,25.8962606 71,27.7335635 71,30 L71,30 Z'
									fill='#422209'
								/>
								<path
									d='M60.5000715,26 C54.6401933,26 53,33.3256372 53,37.4584044 C53,39.7632014 54.0451114,40.5875435 55.6873813,40.8664756 L56.2673722,37.7814467 L57.6257755,40.9992254 C58.0870931,41.0015201 58.5717239,40.9926181 59.0741471,40.9833193 L59.5347221,40.0374632 L60.5617832,40.9663482 C64.6753186,40.9724608 68,41.5705027 68,37.4584044 C68,33.3256372 66.5615853,26 60.5000715,26 Z'
									fill='#422209'
								/>
								<path
									d='M86.6631915,33.4354361 C86.7001152,33.5036696 86.7342226,33.5738575 86.7655138,33.6459998 C87.0815236,34.3666096 87.0779792,35.1890773 86.7557706,35.9068846 C86.433562,36.6246919 85.8232942,37.1696576 85.0782032,37.404943 C84.9924832,37.43169 84.9054325,37.4538802 84.8174096,37.4714221 L79.776249,50.6343543 C79.4340249,51.5177774 78.5582141,52.0699722 77.6216179,51.9928396 L77.5962768,51.9880022 L66.6699282,48.6677256 C65.4290797,48.2892307 64.7266095,46.9675566 65.1003673,45.7146493 C65.4741252,44.461742 66.7827797,43.7513708 68.0243534,44.1274347 C68.0379842,44.1315827 68.0515779,44.1358511 68.0651346,44.1402401 L75.7961183,46.6456996 L81.7814129,35.9347409 C81.7285118,35.8235604 81.6834214,35.7087625 81.6464751,35.5911966 C81.2264299,34.23491 81.8930174,32.7780232 83.1882879,32.2214265 C84.4835584,31.6648298 85.9859083,32.1896924 86.6632137,33.4354305 L86.6631915,33.4354361 Z'
									fill='#EFB59C'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
