import { StyleSheet } from 'aphrodite';
import { titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	closeButton: {
		justifySelf: 'center',
		marginTop: 26,
	},
	container: {
		textAlign: 'center',
	},
	detailMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		lineHeight: '18px',
		marginTop: 16,
	},
	iconContainer: {
		display: 'inline-block',
		position: 'relative',
		marginTop: 48,
	},
	link: {
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
	successIcon: {
		height: 42,
		left: 6,
		position: 'absolute',
		top: '26%',
		width: 42,
		zIndex: 1,
	},
	titleMessage: {
		color: titles,
		fontSize: 18,
		marginTop: 26,
		marginBottom: 16,
	},
});
