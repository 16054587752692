import { StyleSheet } from 'aphrodite';
import { borderColor } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	DateTimePicker: {
		width: 420,
		borderRadius: 8,
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 16,
		gap: 16,
	},
	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: 24,
	},
	calendar: {},
	calendarDayPicker: {},
	separator: {
		width: '85%',
		borderBottom: `2px solid ${borderColor}`,
	},
	timeContainer: {
		display: 'flex',
		width: '100%',
		marginLeft: 24,
		alignItems: 'flex-start',
	},
	timeDropdown: {
		marginLeft: 8,
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
});
