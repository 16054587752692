import * as Api from '@ViewModels';
import { UseQueryResult } from '@tanstack/react-query';
import { css, StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';
import { IContentCalendarSelectedSuggestion, IImpersonationContextComponentProps } from '../../../../../../models';
import { createContentStateWithHtmlStringValue, getUniqueIdForSuggestion } from '../../../../../../models/UiUtils';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { TinyPopover } from '../../../../../components/TinyPopover';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../../../components/richContent/RichContentDocumentEditor';
import { MultiCampaignTemplateItem } from '../../MultiCampaignTemplateItem';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	canRemove?: boolean;
	error?: Api.IOperationResultNoValue;
	onRemove?: () => void;
	onUpdateDate: (newDate: Date) => void;
	query: UseQueryResult<Api.ITemplate, unknown>;
	scheduled?: boolean;
	scheduleDate: Date;
	styles?: StyleDeclarationValue[];
	suggestion: IContentCalendarSelectedSuggestion;
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId?: string;
}

const defaultEditorConfig: IRichContentDocumentEditorConfig = {
	plugins: null,
	toolbar: null,
};

export function MultiBlogTemplateItem({
	suggestion,
	query,
	onRemove,
	scheduled,
	scheduleDate,
	error,
	onUpdateDate,
	styles = [],
	sendFrom,
	sendFromUserId,
}: IProps) {
	const { data: template } = query;

	const content = React.useMemo<Api.IRichContentEditorState>(() => {
		if (template) {
			const contentString = template.content.document.replace(
				new RegExp(`</?${Api.RawRichTextContentDocumentRootElementName}>`, 'gim'),
				''
			);
			return createContentStateWithHtmlStringValue(contentString);
		}
		return null;
	}, [template]);

	return (
		<MultiCampaignTemplateItem
			key={getUniqueIdForSuggestion(suggestion)}
			name={template?.name || 'Loading...'}
			scheduleDate={scheduleDate}
			onRemove={onRemove}
			onUpdateDate={onUpdateDate}
			styles={styles}
			showSuccess={scheduled}
			sendFrom={sendFrom}
			sendFromUserId={sendFromUserId}
		>
			<div>
				<div className={css(styleSheet.stack)}>
					<TinyPopover
						anchorStyles={[styleSheet.imageContainer]}
						toggleOnHover={true}
						placement={['right']}
						anchor={<img src={template?.attachments?.[0]?.url} alt='Preview' className={css(styleSheet.image)} />}
					>
						<img src={template?.attachments?.[0]?.url} alt='Preview' className={css(styleSheet.largeImage)} />
					</TinyPopover>
					<div
						className={css(
							styleSheet.editorContainer,
							scheduled ? styleSheet.editorContainerSuccess : null,
							error ? styleSheet.editorContainerError : null
						)}
					>
						{!content ? (
							<LoadingSpinner key='loading' type='small' />
						) : (
							<RichContentDocumentEditor
								className={css(styleSheet.editor)}
								config={defaultEditorConfig}
								contentState={content}
								readOnly={true}
							/>
						)}
					</div>
				</div>
			</div>
		</MultiCampaignTemplateItem>
	);
}
