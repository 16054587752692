import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { getDisplayName } from '../../../models/UiUtils';
import { useUserQuery } from '../../../queries';
import { CampaignSelectEmployeeModal } from '../../containers/TextingCampaign/CampaignSelectEmployeeModal';
import { Dropdown } from '../Dropdown';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

export function ComposerSendFromDropdown(props: {
	disabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId: string | null;
	onChange: (params: { sendFrom: Api.ISendEmailFrom; sendFromUserId: string | null }) => void;
	dropdownStyles?: StyleDeclarationValue[];
	hideContactOwnerOption?: boolean;
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isEmployeeModalOpen, setIsEmployeeModalOpen] = React.useState(false);
	const userSession = useUserSession();
	const isUsingImpersonation = Boolean(props.impersonationContext?.account?.id);
	const userQuery = useUserQuery({
		impersonationContext: props.impersonationContext,
		enabled:
			props.sendFromUserId != null &&
			Boolean(isUsingImpersonation || userSession.user!.role?.toLowerCase().includes('admin')),
		id: props.sendFromUserId!,
	});

	const user = props.impersonationContext?.user ?? userSession.user;
	let sendFromDropdownText: React.ReactNode = null;

	switch (props.sendFrom) {
		case Api.SendEmailFrom.ContactOwner:
			sendFromDropdownText = 'Contact owner of the recipient';
			break;
		case Api.SendEmailFrom.CurrentUser:
			sendFromDropdownText = user != null ? getDisplayName(user) : '';
			break;
		case Api.SendEmailFrom.SelectedUser:
			sendFromDropdownText = userQuery.data ? getDisplayName(userQuery.data) : 'User ...';
			break;
		default:
			sendFromDropdownText = '';
	}

	return (
		<div className={css(styleSheet.ComposerSendFromDropdown)}>
			<span>Send From:</span>
			<Dropdown
				anchor={
					<div className={css(styleSheet.dropdownContainer)}>
						<span className={css(styleSheet.dropdownText)}>{sendFromDropdownText}</span>
						<DisclosureIcon className={css(styleSheet.dropdownIcon)} />
					</div>
				}
				disabled={props.disabled}
				styles={[styleSheet.dropdownInline, ...[props.dropdownStyles ?? []]]}
				contentClassName={css(styleSheet.dropdownMenu)}
				contentPositionY='bottom'
				isOpen={isOpen}
				onOpenChanged={setIsOpen}
				openOnClick={true}
			>
				<div>
					<div
						className={css(styleSheet.dropdownItem)}
						onClick={() => {
							setIsOpen(false);
							props.onChange({ sendFrom: Api.SendEmailFrom.CurrentUser, sendFromUserId: userSession.user!.id });
						}}
					>
						{`${userSession.user!.firstName} ${userSession.user!.lastName}`}
					</div>
					{!props.hideContactOwnerOption ? (
						<div
							className={css(styleSheet.dropdownItem)}
							onClick={() => {
								setIsOpen(false);
								props.onChange({ sendFrom: Api.SendEmailFrom.ContactOwner, sendFromUserId: null });
							}}
						>
							Contact owner of the recipient
						</div>
					) : null}
					{userSession.user!.role?.toLowerCase().includes('admin') ? (
						<div
							className={css(styleSheet.dropdownItem)}
							onClick={() => {
								setIsOpen(false);
								setIsEmployeeModalOpen(true);
							}}
						>
							Select an employee
						</div>
					) : null}
				</div>
			</Dropdown>
			{isEmployeeModalOpen ? (
				<CampaignSelectEmployeeModal
					onClose={() => setIsEmployeeModalOpen(false)}
					onSubmit={userId => {
						props.onChange({ sendFrom: Api.SendEmailFrom.SelectedUser, sendFromUserId: userId });
						setIsEmployeeModalOpen(false);
					}}
				/>
			) : null}
		</div>
	);
}
