import { StyleSheet } from 'aphrodite';
import { aceGray, error, shuffleGray } from '../../../../../styles/colors';
import { CssSelectors } from '../../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	recipientsList: {
		overflow: 'auto',
	},
	templatesList: {
		display: 'flex',
		flexDirection: 'column',
		gap: 32,
		overflow: 'auto',
		padding: '20px 20px 20px 24px',
	},
	tagTokenContainer: {
		maxHeight: 'unset',
		...CssSelectors.allChildren({
			marginLeft: '0.5rem',
		}),
	},
	contentContainer: {
		':hover': {
			cursor: 'pointer',
		},
		...CssSelectors.allChildren(
			{
				fontSize: '14px',
			},
			':first-child:not(.tag):not(.saved-search)'
		),
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'flex',
				marginLeft: 8,
				textTransform: 'capitalize',
			},
			':last-child'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 0,
			},
			'.saved-search'
		),
	},
	itemsContainer: {
		':last-child': {
			marginBottom: 0,
		},
		marginBottom: 8,
	},
	pill: {
		borderRadius: 30,
		fontSize: 12,
		padding: '3px 10px',
		...CssSelectors.allChildren(
			{
				height: 13,
				marginRight: 8,
				width: 13,
			},
			'svg'
		),
	},
	emailEditorContainer: {
		backgroundColor: `${shuffleGray}80`,
		border: `1px solid ${aceGray}`,
		borderRadius: 8,
		height: 176,
		overflow: 'hidden',
		paddingLeft: 10,
	},
	editorContainerError: {
		border: `1px solid ${error}`,
	},
	emailEditorStyles: {
		width: '100%',
		height: '100%',
		overflow: 'auto',
	},
	emailSuccess: {
		paddingTop: 60,
	},
});
