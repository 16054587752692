import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../../../models/UiUtils';
import { RichContentDocumentEditor } from '../../../../../components/richContent/RichContentDocumentEditor';
import { MultiCampaignTemplateItem } from '../../MultiCampaignTemplateItem';
import { styleSheet } from './styles';

export function MultiEmailTemplateItem(
	props: React.PropsWithChildren<{
		template: Api.ITemplate;
		scheduled?: boolean;
		scheduleDate: Date;
		onUpdateDate: (newDate: Date) => void;
		onRemove: () => void;
		sendFrom: Api.ISendEmailFrom;
		sendFromUserId?: string;
		error?: Api.IOperationResultNoValue;
	}>
) {
	const { template, scheduled, error, ...restProps } = props;
	return (
		<MultiCampaignTemplateItem showSuccess={scheduled} {...restProps} name={template.name}>
			<div className={css(styleSheet.emailEditorContainer, error ? styleSheet.editorContainerError : null)}>
				<RichContentDocumentEditor
					readOnly
					contentState={convertRawRichTextContentStateToRichContentEditorState(props.template.content)}
					styles={[styleSheet.emailEditorStyles]}
				/>
			</div>
		</MultiCampaignTemplateItem>
	);
}
