// eslint-disable-next-line import/no-internal-modules
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IPostTarget, ITemplate, SocialMediaPreviewLimits, SocialMediaType } from '../../../../extViewmodels';
import {
	getShortenedString,
	getSocialMediaPlainTextContentFromRawRichContent,
	getSocialMediaPlatformLogoUrl,
} from '../../../../models/UiUtils';
import { bs } from '../../../styles/styles';
import { PreviewPostContentView } from '../PreviewSocialMediaPost/presentation';
import {
	MultiScheduleBasicImagePreview,
	MultiScheduleBasicVideoPreview,
	MultiScheduleInstagramImagePreview,
	MultiScheduleLinkedInImagePreview,
} from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	targets?: IPostTarget[];
	template?: ITemplate;
	children: React.ReactNode;
}

export const PreviewSocialMediaPostCalendar: React.FC<IProps> = observer((props: IProps) => {
	const { className = '', template, targets, styles = [] } = props;

	const [targetIndex, setTargetIndex] = React.useState(0);
	const postContent = getSocialMediaPlainTextContentFromRawRichContent(template?.content);

	const postImages = template?.attachments;
	const contentDisplayLength = targets?.length > 0 ? getContentLength(targets[targetIndex].provider) : null;
	const displayContent =
		postContent?.length > contentDisplayLength
			? getShortenedString(postContent.trim(), contentDisplayLength).trim()
			: postContent?.trim();

	const onSetTargetPreview = React.useCallback(
		(index?: number) => () => {
			if (targets?.length > 0) {
				setTargetIndex(index);
			}
		},
		[targets]
	);

	function getContentLength(targetChoice: SocialMediaType) {
		if (targetChoice === SocialMediaType.Instagram) {
			return SocialMediaPreviewLimits.Instagram;
		} else if (targetChoice === SocialMediaType.LinkedIn) {
			return SocialMediaPreviewLimits.LinkedIn;
		}
		return SocialMediaPreviewLimits.FaceBook;
	}

	if (!targets?.length) {
		return null;
	}

	return (
		<>
			<section
				className={`preview-social-media-post ${className} ${css(styleSheet.previewContentContainer, ...styles)}`}
			>
				<h3 className={css(styleSheet.previewHeader)}>Preview Posts</h3>
				{targets?.length > 1 ? (
					<div className={css(styleSheet.iconRow)}>
						{targets
							?.sort((a, b) => (a.provider > b.provider ? 1 : -1))
							?.map((x, i) => (
								<span className={css(styleSheet.iconRow)} key={`${x}-${i}`}>
									<button
										className={
											x === targets[targetIndex]
												? css(styleSheet.iconRow, styleSheet.iconRowSelected)
												: css(styleSheet.iconRow)
										}
										onClick={onSetTargetPreview(i)}
									>
										<img key={i} src={getSocialMediaPlatformLogoUrl(x.provider)} />
									</button>
								</span>
							))}
					</div>
				) : null}
				<div
					className={css(
						styleSheet.previewContentBox,
						targets[targetIndex].provider === SocialMediaType.Instagram &&
							postImages?.length > 1 &&
							styleSheet.previewContentBoxInstagram
					)}
				>
					<PreviewPostContentView
						previewContent={displayContent}
						postContent={postContent}
						truncate={postContent?.length > contentDisplayLength}
						target={targets[targetIndex]}
					/>
					{postImages?.length > 0 ? (
						postImages.some(x => x.mimeType?.startsWith('video/')) ? (
							<MultiScheduleBasicVideoPreview postImages={postImages} controls={true} />
						) : targets[targetIndex].provider === SocialMediaType.Instagram && postImages?.length > 1 ? (
							<MultiScheduleInstagramImagePreview postImages={postImages} />
						) : targets[targetIndex].provider === SocialMediaType.LinkedIn ? (
							<MultiScheduleLinkedInImagePreview postImages={postImages} />
						) : (
							<MultiScheduleBasicImagePreview postImages={postImages} />
						)
					) : null}
				</div>
				<p
					className={css(
						bs.m0,
						targets[targetIndex].provider === SocialMediaType.Instagram && postImages?.length > 1
							? styleSheet.disclaimerTextInstagram
							: styleSheet.disclaimerText
					)}
				>
					This is only a preview. Content may display slightly differently when published.
				</p>
			</section>
			<div>{props.children}</div>
		</>
	);
});
