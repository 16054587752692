import * as Api from '@ViewModels';
import { AutomationTriggerType } from '@ViewModels';
import { css } from 'aphrodite';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useToggle } from '../../../../models/hooks/useToggle';
import { Checkbox } from '../../Checkbox';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ISelectOption, Select } from '../../Select';
import { AutomationSurveyAutocomplete } from '../../SelectASurvey';
import { AutoCompleteSearchField, IAutoCompleteSearchFieldComponent } from '../../autocomplete/AutoCompleteSearchField';
import { DangerMessage } from '../../errorMessages/DangerMessage';
import { AddTagModal } from '../../reporting/tags/AddTag';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { TagIcon } from '../../svgs/icons/TagIcon';
import { styleSheet } from './styles';

interface IAutomationTriggerSettingsProps<T extends Api.IAutomationTrigger = Api.IAutomationTrigger> {
	automationTemplate?: Api.AutomationTemplateViewModel;
	onRequestSetTrigger?(trigger: T): Promise<any>;
	trigger?: T;
}

export const AutomationTriggerSettings: React.FC<IAutomationTriggerSettingsProps> = props => {
	switch (props.trigger?._type) {
		case Api.AutomationTriggerType.Texting: {
			return <AutomationTextingTriggerSettings {...props} />;
		}
		case Api.AutomationTriggerType.Tag: {
			return <AutomationTagTriggerSettings {...props} />;
		}
		case Api.AutomationTriggerType.NewLead: {
			return <AutomationNewLeadTriggerSettings {...props} />;
		}
		case Api.AutomationTriggerType.NewClient:
		case Api.AutomationTriggerType.NewDonor:
		case Api.AutomationTriggerType.ResourceSelector: {
			return <AutomationYoloTriggerSettings {...props} />;
		}
		case Api.AutomationTriggerType.EventRegistration: {
			return <AutomationEventRegistrationTriggerSettings {...props} />;
		}
		default: {
			break;
		}
	}
	return null as JSX.Element;
};

interface IAutomationTriggerSettingsCheckboxProps<TTrigger extends Api.IAutomationTrigger = Api.IAutomationTrigger>
	extends IAutomationTriggerSettingsProps<TTrigger> {
	keyInTrigger: keyof TTrigger;
	/** If set to true, the value written to the trigger's key will be the opposite of the <input/> "checked" value */
	trackUnchecked?: boolean;
}

type AutomationTriggerSettingsCheckboxType<T extends Api.IAutomationTrigger = Api.IAutomationTrigger> = React.FC<
	IAutomationTriggerSettingsCheckboxProps<T>
>;

const AutomationTriggerSettingsCheckbox: AutomationTriggerSettingsCheckboxType = observer(props => {
	const { trigger, onRequestSetTrigger, children, keyInTrigger, trackUnchecked, automationTemplate } = props;

	const [checked, setChecked] = React.useState(trackUnchecked ? !trigger?.[keyInTrigger] : !!trigger?.[keyInTrigger]);
	const onCheckChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setChecked(e.target.checked);
			const t: Api.INewLeadAutomationTrigger = {
				...(trigger || {}),
				[keyInTrigger]: trackUnchecked ? !e.target.checked : e.target.checked,
			};
			onRequestSetTrigger?.(t);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[trigger, onRequestSetTrigger, keyInTrigger]
	);
	return (
		<Checkbox
			checked={checked}
			className={css(styleSheet.triggerSettingsCheckbox)}
			disabled={automationTemplate?.isBusy || (automationTemplate?.id ? !automationTemplate.canEdit : false)}
			id={`automation-trigger-${trigger?._type || ''}-${keyInTrigger}-checkbox`}
			onChange={onCheckChanged}
			type='large'
		>
			<div>
				<div className={css(styleSheet.checkboxText)}>{children}</div>
			</div>
		</Checkbox>
	);
});

const AutomationTriggerSettingsCheckboxes = {
	[Api.AutomationTriggerType.NewLead]:
		AutomationTriggerSettingsCheckbox as AutomationTriggerSettingsCheckboxType<Api.INewLeadAutomationTrigger>,
	default: AutomationTriggerSettingsCheckbox,
} as const;

export const AutomationOnHoldCheckbox: React.FC<IAutomationTriggerSettingsProps> = observer(props => {
	const { trigger, onRequestSetTrigger, children, automationTemplate } = props;

	const [checked, setChecked] = React.useState(
		trigger ? (trigger.putOnHold === undefined || trigger.putOnHold === null ? false : !trigger.putOnHold) : false
	);
	const onCheckChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setChecked(e.target.checked);
			const t: Api.IAutomationTrigger = {
				...(trigger || {}),
				putOnHold: !e.target.checked,
			};
			onRequestSetTrigger?.(t);
		},
		[trigger, onRequestSetTrigger]
	);
	return (
		<Checkbox
			checked={checked}
			className={css(styleSheet.triggerSettingsCheckbox)}
			disabled={automationTemplate?.isBusy || (automationTemplate?.id ? !automationTemplate.canEdit : false)}
			id='automation-trigger-on-hold-checkbox'
			onChange={onCheckChanged}
			type='large'
		>
			<div>
				<div className={css(styleSheet.checkboxText)}>{children}</div>
			</div>
		</Checkbox>
	);
});

interface IAutomationCancelOnReplyCheckboxProps {
	automationTemplate?: Api.AutomationTemplateViewModel;
}

export const AutomationCancelOnReplyCheckbox: React.FC<IAutomationCancelOnReplyCheckboxProps> = observer(props => {
	const { automationTemplate } = props;
	const logger = useEventLogging('AutomationCancelOnReplyCheckbox');
	const errorMessages = useErrorMessages();

	const [checked, setChecked] = React.useState(
		automationTemplate
			? automationTemplate.cancelOnReply === undefined || automationTemplate.cancelOnReply === null
				? false
				: automationTemplate.cancelOnReply
			: false
	);
	const onCheckChanged = React.useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			setChecked(e.target.checked);
			try {
				await automationTemplate.setCancelOnReply(e.target.checked);
			} catch (err: any) {
				errorMessages.pushApiError(err);
				logger.logApiError('SetAutomationCancelOnReply-Error', err);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[automationTemplate]
	);
	return (
		<Checkbox
			checked={checked}
			className={css(styleSheet.cancelOnReplyCheckbox)}
			disabled={automationTemplate?.isBusy || (automationTemplate?.id ? !automationTemplate.canEdit : false)}
			id='automation-cancel-on-reply-checkbox'
			onChange={onCheckChanged}
			type='large'
		>
			<div>
				<div className={css(styleSheet.checkboxText, styleSheet.cancelOnReplyCheckboxText)}>
					Cancel the automation if the recipient replies to email or texts.
				</div>
			</div>
		</Checkbox>
	);
});

const AutomationTextingTriggerSettingsFc: React.FC<
	IAutomationTriggerSettingsProps<Api.ITextingCampaignAutomationTrigger>
> = observer(props => {
	const { automationTemplate, trigger, onRequestSetTrigger } = props;
	const logger = useEventLogging('AutomationTriggerCard');
	const errorMessages = useErrorMessages();

	const [checked, setChecked] = React.useState(
		trigger
			? trigger.acknowledgedOnlySendingToOptedIn === undefined || trigger.acknowledgedOnlySendingToOptedIn === null
				? false
				: trigger.acknowledgedOnlySendingToOptedIn
			: false
	);
	const onCheckChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const currentChecked = e.target.checked;
			setChecked(currentChecked);
			if (onRequestSetTrigger) {
				const t: Api.ITextingCampaignAutomationTrigger = {
					...(trigger || {}),
					acknowledgedOnlySendingToOptedIn: currentChecked,
				};
				onRequestSetTrigger(t)?.catch((error: Api.IOperationResultNoValue) => {
					logger.logApiError('SetAutomationTrigger-Error', error);
					errorMessages.pushApiError(error);
					setChecked(!currentChecked);
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[trigger, onRequestSetTrigger]
	);
	return (
		<Checkbox
			checked={checked}
			className={css(styleSheet.triggerSettingsCheckbox)}
			disabled={automationTemplate?.isBusy || (automationTemplate?.id ? !automationTemplate.canEdit : false)}
			id='automation-trigger-texting-ack-checkbox'
			onChange={onCheckChanged}
			type='large'
		>
			<div>
				<div className={css(styleSheet.checkboxText, styleSheet.textingTriggerCheckboxText)}>
					I agree that the recipients have opted in to receive texts and this is not a marketing campaign.
				</div>
			</div>
		</Checkbox>
	);
});

export const AutomationTextingTriggerSettings = inject(ImpersonationContextKey)(AutomationTextingTriggerSettingsFc);

export const AutomationTagAutocomplete: React.FC<
	IAutomationTriggerSettingsProps & {
		onTagSelected?(tag: string): void;
		className?: string;
		limit?: number;
		initialTag?: string;
	}
> = observer(props => {
	const { automationTemplate, onTagSelected, limit = 10, initialTag = '' } = props;

	const [searchQuery, setSearchQuery] = React.useState<string>(initialTag);
	const userSession = useUserSession();
	const tags = React.useRef(
		new Api.TagsViewModel(userSession).impersonate(automationTemplate?.impersonationContext)
	).current;
	const autoCompleteSearchFieldComponentRef = React.useRef<IAutoCompleteSearchFieldComponent>(null);

	// #region AutoCompleteSearchField callbacks
	const onCreateAutoCompleteViewModel = React.useCallback(
		(_: Api.ResourceAutoCompleteViewModelType, suggestedVm: Api.ResourceAutoCompleteViewModel) => {
			return tags.isImpersonating ? suggestedVm.impersonate(tags.impersonationContext) : suggestedVm;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onAutoCompleteSearchFieldRef = React.useCallback((ref?: IAutoCompleteSearchFieldComponent) => {
		autoCompleteSearchFieldComponentRef.current = ref;
	}, []);

	const onAutocompleteItemSelectedTagTrigger = React.useCallback(
		(tag: string): void => {
			onTagSelected?.(tag);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[automationTemplate, onTagSelected]
	);

	const onInputBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		const tagValue = (e.target.value || '').trim();
		if (tagValue) {
			onAutocompleteItemSelectedTagTrigger(tagValue);
			autoCompleteSearchFieldComponentRef.current?.setSearchQuery(tagValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showTagModal, setShowTagModal, setShowTagModalToggle] = useToggle(false);
	const onAddTagModalRequestClose = React.useCallback(
		(tag?: Api.TagViewModel, cancelled?: boolean) => {
			setShowTagModal(false);
			if (tag && !cancelled) {
				setSearchQuery(tag.name);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showTagModal, searchQuery]
	);
	const onRenderResultsFooter = React.useCallback(() => {
		return (
			<div className={css(styleSheet.addTagDropdownFooter)}>
				<button className={css(styleSheet.addTagButton)} onMouseDown={setShowTagModalToggle(true)}>
					<TagIcon />
					<span>Add a Tag</span>
				</button>
				<AddTagModal
					modalProps={{
						isOpen: !!showTagModal,
						onRequestClose: onAddTagModalRequestClose,
					}}
					impersonationContext={tags.impersonationContext}
				/>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showTagModal]);
	// #endregion

	return (
		<AutoCompleteSearchField
			className={props.className}
			anchorClassName={css(styleSheet.searchBox)}
			clearSearchFieldAfterSelectingItem={false}
			dropdownContentClassName={css(styleSheet.autocompleteBox)}
			initialSearchQuery={searchQuery}
			inputId='automation-trigger-tag-search'
			inputProps={{
				disabled: automationTemplate?.id ? !automationTemplate.canEdit : false,
				onBlur: onInputBlur,
				placeholder: 'Search or add a new tag',
			}}
			leftAccessory={
				<span className={css(styleSheet.iconSpan)}>
					<SearchIcon fillColor='#CCC' />
				</span>
			}
			onCreateAutoCompleteViewModel={onCreateAutoCompleteViewModel}
			onInnerRef={onAutoCompleteSearchFieldRef}
			onItemSelected={onAutocompleteItemSelectedTagTrigger}
			onRenderResultsFooter={onRenderResultsFooter}
			pageSize={limit}
			resultsLimit={limit}
			type={Api.ResourceAutoCompleteViewModelType.Tag}
		/>
	);
});

export const AutomationTagTriggerSettings: React.FC<
	IAutomationTriggerSettingsProps & { onTagSelected?(tag: string): void }
> = observer(props => {
	const logger = useEventLogging('AutomationTriggerCard');
	const errorMessages = useErrorMessages();
	return (
		<div>
			<div className={css(styleSheet.triggerSettingsTitle)}>Set a tag as your trigger</div>
			<AutomationTagAutocomplete
				{...props}
				initialTag={(props.trigger as Api.ITagAutomationTrigger)?.tag}
				onTagSelected={tag => {
					props.automationTemplate
						?.addTrigger<Api.ITagAutomationTrigger>({
							_type: 'TagAutomationTrigger',
							tag,
						})
						?.catch((err: Api.IOperationResultNoValue) => {
							errorMessages.pushApiError(err);
							logger.logApiError('AutomationTemplateAddTagTrigger', err);
						});
					props.onTagSelected?.(tag);
				}}
			/>
		</div>
	);
});

interface IAutomationNewLeadTriggerSettingsProps
	extends IAutomationTriggerSettingsProps,
		IImpersonationContextComponentProps {
	automationTemplates?: Api.AutomationTemplatesViewModel;
}

const AutomationNewLeadTriggerSettingsFc: React.FC<IAutomationNewLeadTriggerSettingsProps> = props => {
	const { automationTemplate, trigger, onRequestSetTrigger, automationTemplates, impersonationContext } = props;
	const newLeadTrigger = trigger as Api.INewLeadAutomationTrigger;
	const userSession = useUserSession();
	const logger = useEventLogging('AutomationNewLeadTriggerSettings');
	const { pushApiError: showApiError } = useErrorMessages();
	const automationTemplatesViewModel = React.useRef(
		automationTemplates || new Api.AutomationTemplatesViewModel(userSession).impersonate(impersonationContext)
	).current;
	const [selectOptions, setSelectOptions] = React.useState<ISelectOption<Api.INewLeadAutomationTrigger>[]>([]);
	const [selectedOption, setSelectedOption] = React.useState<ISelectOption<Api.INewLeadAutomationTrigger>>(null);

	React.useEffect(() => {
		if (selectOptions?.length === 0) {
			const triggerPromise = automationTemplatesViewModel.loadTriggers();
			if (triggerPromise) {
				logger.logEvent('TriggersLoad');
				triggerPromise
					.then(() => {
						const options = automationTemplatesViewModel.availableLeadParserTriggers.map<
							ISelectOption<Api.INewLeadAutomationTrigger>
						>(x => {
							return {
								dataContext: x.trigger,
								id: x.trigger.id || x.name,
								text: x.name,
							};
						});
						// check to see if the trigger already has a scanner and if it's custom
						const selected =
							!!newLeadTrigger.emailScannerIds?.find(x => x === Api.EmailScannerId.Custom) &&
							newLeadTrigger.customEmailScannerIds?.length > 0
								? // filter to options where id === the id of the first custom scanner/parser
									options.filter(
										x => x.dataContext.customEmailScannerIds?.[0] === newLeadTrigger.customEmailScannerIds[0]
									)
								: // filter to options where id === the id of the first email scanner/parser
									options.filter(x => x.dataContext.emailScannerIds?.[0] === newLeadTrigger.emailScannerIds?.[0]);

						ReactDOM.unstable_batchedUpdates(() => {
							setSelectOptions(options);
							setSelectedOption(selected?.[0]);
						});
					})
					.catch(err => {
						logger.logApiError('TriggersLoad-Error', err);
						showApiError(err);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// #region Select Callbacks
	const onOptionClicked = React.useCallback(
		async (option: ISelectOption<Api.INewLeadAutomationTrigger>, wasSelected: boolean) => {
			if (wasSelected) {
				const checkboxOptions =
					Api.selectKeysOf(trigger as Api.INewLeadAutomationTrigger, ['onlyForNewLeads', 'putOnHold']) || {};
				const t: Api.INewLeadAutomationTrigger = {
					...(trigger || {}),
					...option.dataContext,
					...checkboxOptions, // retain these
				};
				onRequestSetTrigger?.(t);
				setSelectedOption(option);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[automationTemplate, onRequestSetTrigger, trigger]
	);

	const onRenderPlaceholder = React.useCallback(() => {
		return (
			<Observer>
				{() => {
					if (automationTemplatesViewModel.isBusy && selectOptions?.length === 0) {
						return <LoadingSpinner type='tiny' />;
					}
					return <div>Please select...</div>;
				}}
			</Observer>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOptions]);
	// #endregion
	const NewLeadTriggerSettingsCheckbox = AutomationTriggerSettingsCheckboxes[Api.AutomationTriggerType.NewLead];
	return (
		<div>
			<div className={css(styleSheet.triggerSettingsTitle)}>Select a lead source below:</div>
			<Select
				onOptionClick={onOptionClicked}
				onRenderPlaceholder={onRenderPlaceholder}
				options={selectOptions}
				selectedOption={selectedOption}
				styles={[styleSheet.triggerSelect]}
			/>
			<div>
				<AutomationOnHoldCheckbox {...props}>
					<div className={css(styleSheet.triggerSettingsCheckboxText)}>
						Automatically approve Automations triggered by new leads
					</div>
				</AutomationOnHoldCheckbox>
			</div>
			<div>
				<NewLeadTriggerSettingsCheckbox {...props} keyInTrigger='onlyForNewLeads'>
					<div className={css(styleSheet.triggerSettingsCheckboxText)}>
						Don&apos;t run automation if the lead is already in Levitate
					</div>
				</NewLeadTriggerSettingsCheckbox>
			</div>
			<div>
				<NewLeadTriggerSettingsCheckbox {...props} keyInTrigger='disableObserveSendIntervals'>
					<div className={css(styleSheet.triggerSettingsCheckboxText)}>
						Allow automation to send emails outside of business hours
					</div>
				</NewLeadTriggerSettingsCheckbox>
			</div>
		</div>
	);
};

const AutomationNewLeadTriggerSettingsAsObserver = observer(AutomationNewLeadTriggerSettingsFc);
export const AutomationNewLeadTriggerSettings = inject(ImpersonationContextKey)(
	AutomationNewLeadTriggerSettingsAsObserver
);

export const AutomationYoloTriggerSettings = (props: IAutomationTriggerSettingsProps) => {
	const putOnHold = props?.trigger?.putOnHold;
	const { trigger, automationTemplate } = props;
	const shouldRenderDangerMessage = putOnHold !== undefined && putOnHold !== null && !putOnHold;

	const displayName = automationTemplate?.isImpersonating
		? automationTemplate.impersonationContext.account?.preferences?.resourceSelectorSettings?.[trigger.resourceSelector]
				?.displayName
		: automationTemplate?.userSession?.account?.preferences?.resourceSelectorSettings?.[trigger.resourceSelector]
				?.displayName;

	let textByResourceSelectorType = 'unknown';
	if (trigger?._type === AutomationTriggerType.ResourceSelector) {
		const t = trigger as Api.IResourceSelectorAutomationTrigger;
		switch (t.resourceSelector) {
			case Api.ResourceSelectorId.TurningXX:
			case Api.ResourceSelectorId.HappyBirthday:
			case Api.ResourceSelectorId.Turning65:
			case Api.ResourceSelectorId.Turning72:
			case Api.ResourceSelectorId.Turning73: {
				textByResourceSelectorType = `birthdays`;
				break;
			}
			case Api.ResourceSelectorId.PolicyRenew:
				textByResourceSelectorType = `renewals`;
				break;
			case Api.ResourceSelectorId.HouseAnniversaries:
				textByResourceSelectorType = `anniversaries`;
				break;
			case Api.ResourceSelectorId.FinancialReview:
				textByResourceSelectorType = `financial reviews`;
				break;
			case Api.ResourceSelectorId.Custom1:
			case Api.ResourceSelectorId.Custom2:
			case Api.ResourceSelectorId.Custom3:
			case Api.ResourceSelectorId.Custom4:
			case Api.ResourceSelectorId.Custom5:
			case Api.ResourceSelectorId.Custom6:
			case Api.ResourceSelectorId.Custom7:
			case Api.ResourceSelectorId.Custom8:
			case Api.ResourceSelectorId.Custom9:
			case Api.ResourceSelectorId.Custom10: {
				textByResourceSelectorType = `${displayName?.toLowerCase()}`;
				break;
			}
			default: {
				break;
			}
		}
	}
	if (trigger?._type === AutomationTriggerType.NewClient) {
		textByResourceSelectorType = `new clients`;
	}
	if (trigger?._type === AutomationTriggerType.NewDonor) {
		textByResourceSelectorType = 'new donors';
	}

	return (
		<>
			<div>
				<AutomationOnHoldCheckbox {...props}>
					<div className={css(styleSheet.triggerSettingsCheckboxText)}>
						{`Automatically approve Automations triggered by ${textByResourceSelectorType}.`}
					</div>
				</AutomationOnHoldCheckbox>
			</div>
			<div className={css(styleSheet.triggerSettingsWarning)}>
				{shouldRenderDangerMessage && (
					<DangerMessage type='warning'>
						Users will no longer see a card on the dashboard prompting them to approve this automation; it will be
						kicked off automatically.&nbsp;
						<strong className={css(styleSheet.bold)}>
							Only check this box if you feel 100 % confident in the accuracy of your data.
						</strong>
					</DangerMessage>
				)}
			</div>
		</>
	);
};

export const AutomationEventRegistrationTriggerSettings: React.FC<
	IAutomationTriggerSettingsProps & { onEventSelected?(eventId: string): void }
> = observer(props => {
	const logger = useEventLogging('AutomationTriggerCard');
	const errorMessages = useErrorMessages();
	return (
		<div>
			<div className={css(styleSheet.triggerSettingsTitle)}>Set an event registration as your trigger</div>
			<AutomationSurveyAutocomplete
				{...props}
				initialEventName={(props.trigger as Api.IEventRegistrationTrigger)?.eventName}
				onSurveySelected={survey => {
					props.automationTemplate
						?.addTrigger<Api.IEventRegistrationTrigger>({
							_type: 'EventRegistrationTrigger',
							eventId: survey.id,
							eventName: survey.name,
							validResponses: [Api.EventRegistrationResponseStatus.Attending],
						})
						?.catch((err: Api.IOperationResultNoValue) => {
							errorMessages.pushApiError(err);
							logger.logApiError('AutomationTemplateAddTagTrigger', err);
						});
					props.onEventSelected?.(survey.id);
				}}
			/>

			<div className={css(styleSheet.eventRegistrationTriggerDisclaimer)}>
				This automation will start when a contact responds with &ldquo;I&apos;m Attending&rdquo; and will be cancelled
				if their response changes.
			</div>
		</div>
	);
});
