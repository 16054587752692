import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { CampaignType } from '../../../../models/AdminModels';
import { baseStyleSheet } from '../../../styles/styles';
import { ComplianceCompleteIcon } from '../../svgs/icons/ComplianceCompleteIcon';
import { styleSheet } from './styles';

interface IProps {
	onDismiss?(e: React.MouseEvent<HTMLElement>): void;
	styles?: StyleDeclarationValue[];
	campaignType?: CampaignType;
	multiple?: boolean;
}

export function ComplianceConfirmation({ multiple, onDismiss, campaignType, styles = [] }: IProps) {
	let campaignDisplayName = 'campaign';
	switch (campaignType) {
		case CampaignType.Blog: {
			campaignDisplayName = 'blog post';
			break;
		}
		case CampaignType.Social: {
			campaignDisplayName = 'social media post';
			break;
		}
		default: {
			break;
		}
	}
	campaignDisplayName = `${campaignDisplayName}${multiple ? 's' : ''}`;
	return (
		<div className={`${css(styleSheet.container, ...styles)} compliance-confirmation`}>
			<div className={css(styleSheet.iconContainer)}>
				<ComplianceCompleteIcon className={css(styleSheet.icon)} />
			</div>
			<div className={css(styleSheet.titleMessage)}>{`We've emailed you a copy of the ${campaignDisplayName}.`}</div>
			<div className={css(styleSheet.detailMessage)}>
				{`${multiple ? 'These' : 'This'} ${campaignDisplayName} ${multiple ? 'are' : 'is'} now pending. You can come back and mark ${multiple ? 'these' : 'this'} ${campaignDisplayName} as "Approved" later.`}
			</div>
			<button
				className={css(baseStyleSheet.ctaButton, baseStyleSheet.verticalStack, styleSheet.stack)}
				onClick={onDismiss}
			>
				<span>Okay</span>
			</button>
		</div>
	);
}
