import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		maxWidth: 378,
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allDescendants(
			{
				color: 'rgba(13,15,17,1)',
				fontSize: 14,
				fontStyle: 'normal',
				fontWeight: 400,
				letterSpacing: 0,
				lineHeight: '16px',
				margin: '0 0 5px',
				textAlign: 'center',
			},
			'p'
		),
	},
});
