import { StyleSheet } from 'aphrodite';
import { charmGray, grayIconFill, header, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	charCount: {
		color: header,
		display: 'flex',
		fontSize: 12,
		justifyContent: 'flex-end',
		marginRight: 10,
		...CssSelectors.allDescendants(
			{
				display: 'flex',
				paddingRight: 6,
			},
			'button'
		),
	},
	composerContainer: {
		marginTop: 10,
	},
	container: {
		color: grayIconFill,
		fontSize: 14,
		marginBottom: 72,
	},
	header: {
		color: titles,
		fontSize: 18,
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	labelContainer: {
		alignItems: 'flex-end',
		color: charmGray,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
		paddingBottom: 10,
	},
	labelEmoji: {
		alignItems: 'center',
		display: 'flex',
	},
	mediaContainer: {
		color: charmGray,
		fontSize: 14,
		marginTop: 48,
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				padding: 0,
			},
			'.modal-overlay-content'
		),
	},
	textAreaContainer: {
		...CssSelectors.allChildren({
			height: 124,
			padding: 14,
			paddingTop: 10,
			maxHeight: '50vh',
			minHeight: 124,
			resize: 'vertical',
		}),
	},
});
