import { StyleSheet } from 'aphrodite';
import * as Colors from '../../../styles/colors';
import { BreakPoints, CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	body: {
		...Layouts.horizontalStack(30),
		borderBottom: `1px solid ${Colors.nameCircles}`,
		height: 300,
		padding: '30px 0 16px 0',
	},
	campaignList: {
		minHeight: '100%',
	},
	campaignListContainer: {
		flexGrow: 1,
		height: '100%',
		overflowY: 'auto',
		position: 'relative',
	},
	stopSendingBy: {
		padding: '10px 20px 20px',
	},
	checkboxLabel: {
		width: 150,
	},
	radioOptions: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 20px 20px',
		...CssSelectors.allChildren(
			{
				alignItems: 'flex-start',
			},
			'.radio-button'
		),
	},
	EditSendOptionsCompleteBy: {
		background: Colors.mention,
		display: 'flex',
		flexDirection: 'column',
	},
	completeByDays: {
		textAlign: 'center',
		width: 46,
	},
	confirmationSubTitle: {
		color: Colors.titles,
		fontSize: 14,
	},
	confirmationTitle: {
		color: Colors.brandSecondary,
		fontSize: 18,
	},
	container: {
		textAlign: 'center',
	},
	content: {
		margin: '0 auto',
		maxWidth: 700,
		width: '60%',
		[BreakPoints.tabletWidth]: {
			width: '90%',
		},
	},
	dayPicker: {
		flexShrink: 0,
		margin: 'auto',
	},
	expirationWarning: {
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
				textAlign: 'start',
			},
			'time'
		),
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginLeft: 10,
		textAlign: 'start',
	},
	fixedWidth30: {
		display: 'inline-block',
		maxWidth: 30,
		minWidth: 30,
	},
	footer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '98%',
		padding: '50px 0 36px 0',
	},
	footerInputs: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginLeft: 'auto',
		marginRight: 'auto',
		position: 'relative',
	},
	footerInputsInner: {
		flexGrow: 1,
		maxWidth: 600,
		...CssSelectors.allChildren(
			{
				justifyContent: 'flex-start',
			},
			'*:first-child'
		),
	},
	footerWithDanger: {
		padding: '10px 0 36px 0',
	},
	footerWithWarning: {
		padding: '20px 0 36px 0',
	},
	header: {
		alignItems: 'center',
		borderBottom: `1px solid ${Colors.nameCircles}`,
		boxSizing: 'border-box',
		color: Colors.titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'center',
		padding: '10px 0 20px 0',
	},
	headerDescription: {
		color: Colors.label,
		fontSize: 14,
		marginTop: 5,
	},
	headerIcon: {
		height: 120,
		width: 120,
	},
	headerText: {
		marginLeft: 30,
		textAlign: 'left',
	},
	omittedContainer: {
		background: Colors.background,
		border: `1px solid ${Colors.white}`,
		borderRadius: 3,
		marginBottom: 20,
		marginTop: 20,
		paddingLeft: 5,
		paddingRight: 3,
		width: '70%',
	},
	omittedDialog: {
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				maxHeight: 500,
				minWidth: 500,
			},
			'.body'
		),
	},
	omittedGroup: {
		'::-webkit-scrollbar': {
			backgroundColor: Colors.backgroundAlt,
			width: 6,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: Colors.borderOutline,
			borderRadius: 10,
			padding: '0 2px',
			width: 4,
		},
		background: Colors.background,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: 235,
		overflowY: 'auto',
	},
	omittedItem: {
		display: 'flex',
		paddingLeft: 12,
	},
	omittedList: {
		alignItems: 'center',
		borderBottom: `1px solid ${Colors.inputBorderColor}`,
		display: 'flex',
		fontSize: 13,
		height: 30,
		marginRight: 5,
		paddingLeft: 10,
	},
	perDayLabel: {
		color: Colors.navigation,
		fontStyle: 'italic',
	},
	radio: {
		':first-child': {
			marginRight: 10,
		},
		boxSizign: 'border-box',
	},
	radioIcon: {
		paddingTop: 15,
	},
	radioLabel: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		maxHeight: 60,
		minHeight: 60,
	},
	radioLabelLeft: {
		maxHeight: 49,
		minHeight: 49,
		paddingTop: 11,
	},
	scheduleCta: {
		flexGrow: 1,
	},
	scheduleTypeDropDown: {
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	scheduleTypeDropDownOption: {
		fontSize: 14,
		textAlign: 'left',
	},
	scheduleTypeDropDownTrigger: {
		fontSize: 14,
		height: 'auto',
		width: 160,
	},
	selectedDayStringRep: {
		color: Colors.label,
		fontSize: 14,
		marginTop: 10,
		textAlign: 'left',
		width: 160,
	},
	sendOnBehalfWarning: {
		...CssSelectors.allDescendants(
			{
				minHeight: 24,
				minWidth: 30,
			},
			'svg'
		),
		background: 'none',
		border: 'none',
		margin: '0 auto',
		textAlign: 'left',
	},
	timeDropdown: {
		...CssSelectors.allChildren(
			{
				maxWidth: 150,
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	timeDropdownOption: {
		fontSize: 14,
	},
	timeDropdownPlaceholder: {
		color: Colors.navigation,
	},
	timeDropdownTrigger: {
		fontSize: 14,
		height: 'auto',
		minWidth: 108,
	},
	warningMessage: {
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
				textAlign: 'start',
			},
			'span'
		),
		alignItems: 'center',
		background: Colors.warningBackground,
		border: `1px solid ${Colors.destructive}`,
		borderRadius: 20,
		color: Colors.label,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		marginBottom: 20,
		padding: '10px 10px',
	},
	wideWarning: {
		maxWidth: 700,
	},
	advancedOptionsButton: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	advancedOptionsChevron: {
		marginLeft: '0.25rem',
	},
	advancedOptionsChevronIcon: {
		transform: 'rotate3d(0,0,1,0deg)',
	},
	advancedOptionsChevronIconFlipped: {
		transform: 'rotate3d(0,0,1,180deg)',
	},
	advancedOptionsBody: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		alignItems: 'flex-start',
	},
	advancedOptionsContainer: {
		background: Colors.background,
		padding: 16,
		borderRadius: 8,
		gap: 12,
		display: 'flex',
		flexDirection: 'column',
	},
});
