import { useMutation } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { IContentCalendarSelectedSuggestion } from '../../../../../../models';
import { useUserSession } from '../../../../../../models/hooks/appStateHooks';

const scheduleEmailAsync = ({
	email,
	contentCalendarSuggestionId,
	additionalAttachments,
	impersonationContext,
	userSession,
}: {
	email: Api.IEmailMessageCompose<Api.IFollowUpOptions>;
	contentCalendarSuggestionId?: string;
	additionalAttachments?: Api.FileWithExtensionsType[];
	impersonationContext?: Api.IImpersonationContext;
	userSession: Api.UserSessionContext;
}) => {
	const formData = new FormData();
	formData.append('value', JSON.stringify(email));

	if (additionalAttachments?.length) {
		additionalAttachments.forEach(x => formData.append('files', x));
	}

	return userSession.webServiceHelper.callAsync<Api.ISendEmailResponse>(
		Api.ImpersonationBroker.composeApiUrl({
			impersonationContext,
			queryParams: { contentCalendarSuggestionId },
			urlPath: `email`,
		}),
		'POST',
		formData
	);
};

interface IScheduleEmailSuggestionsParams {
	emails: Api.IEmailMessageCompose<Api.IFollowUpOptions>[];
	suggestions: IContentCalendarSelectedSuggestion[];
	additionalAttachments?: Api.FileWithExtensionsType[];
	impersonationContext?: Api.IImpersonationContext;
}

export function useScheduleEmailSuggestions({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (result: PromiseSettledResult<Api.ISendEmailResponse>[], params: IScheduleEmailSuggestionsParams) => void;
}) {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: ({
			emails,
			suggestions,
			additionalAttachments,
			impersonationContext,
		}: IScheduleEmailSuggestionsParams) => {
			return Promise.allSettled(
				suggestions.map((suggestion, index) => {
					const email = emails[index];
					return scheduleEmailAsync({
						email,
						contentCalendarSuggestionId: suggestion.id,
						additionalAttachments,
						impersonationContext,
						userSession,
					});
				})
			);
		},
		onError,
		onSuccess,
	});
}
