import moment from 'moment';
import * as React from 'react';
import { IContentCalendarSelectedSuggestion, IMultiCampaignSchedulerResult } from '../../../../models';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';
import { getUniqueIdForSuggestion } from '../../../../models/UiUtils';

export const SUGGESTIONS_SCHEDULE_SUCCESS_EVENT = 'scheduleSuccess';

export interface ICampaignCalendarSuggestionsContextProps {
	supportsBulkScheduleSuggestions: boolean;
	onScheduleSuggestions?: (suggestions: IContentCalendarSelectedSuggestion[]) => void;
	onSuccess?: (e: CustomEvent<IMultiCampaignSchedulerResult>) => void;
	initialSelectedSuggestions?: IContentCalendarSelectedSuggestion[];
}

export function useCreateCampaignCalendarSuggestionsContext({
	supportsBulkScheduleSuggestions = false,
	onScheduleSuggestions,
	onSuccess,
	initialSelectedSuggestions,
}: ICampaignCalendarSuggestionsContextProps) {
	const [selectedSuggestions, setSelectedSuggestions] = React.useState<IContentCalendarSelectedSuggestion[]>(
		initialSelectedSuggestions || []
	);

	const suggestionsRef = React.useRef<IContentCalendarSelectedSuggestion[]>(selectedSuggestions);
	suggestionsRef.current = selectedSuggestions;
	const dragDropSelectedSuggestions = React.useMemo(() => {
		return selectedSuggestions.filter(s => Boolean(s.dragDropId));
	}, [selectedSuggestions]);

	const [suggestionsMap] = React.useState<Map<string, IContentCalendarSelectedSuggestion>>(() => {
		const map = new Map<string, IContentCalendarSelectedSuggestion>();
		selectedSuggestions.forEach(suggestion => {
			const key = getUniqueIdForSuggestion(suggestion);
			map.set(key, suggestion);
		});
		return map;
	});

	const [dateMap, setDateMap] = React.useState<Map<string, Date>>(() => new Map<string, Date>());

	const scheduleCallbackRef = React.useRef(onScheduleSuggestions);
	scheduleCallbackRef.current = onScheduleSuggestions;
	const scheduleSelectedSuggestions = React.useCallback(() => {
		scheduleCallbackRef.current?.(suggestionsRef.current);
	}, []);

	const hasSelectedSuggestion = React.useCallback(
		(suggestion: IContentCalendarSelectedSuggestion) => {
			if (!suggestion) {
				return false;
			}
			const key = getUniqueIdForSuggestion(suggestion);
			return Boolean(suggestionsMap.get(key));
		},
		[suggestionsMap]
	);

	const deselectSuggestion = React.useCallback(
		(suggestion: IContentCalendarSelectedSuggestion) => {
			setSelectedSuggestions(value => {
				const key = getUniqueIdForSuggestion(suggestion);
				const matchingSuggestion = suggestionsMap.get(key);
				if (matchingSuggestion) {
					suggestionsMap.delete(key);
					return value.filter(x => x !== matchingSuggestion);
				}
				return value;
			});
		},
		[suggestionsMap]
	);

	const selectSuggestion = React.useCallback(
		(suggestion: IContentCalendarSelectedSuggestion) => {
			setSelectedSuggestions(value => {
				const key = getUniqueIdForSuggestion(suggestion);
				const matchingSuggestion = suggestionsMap.get(key);
				if (matchingSuggestion) {
					return value;
				}

				const selectedSuggestion: IContentCalendarSelectedSuggestion = JSON.parse(JSON.stringify(suggestion));

				if (selectedSuggestion.schedule?.startDate) {
					// date validation... clear the date if needed and allow the user to re-select in the next step
					const startMoment = moment(selectedSuggestion.schedule.startDate);
					if (
						startMoment.isBefore(moment().startOf('day')) ||
						(selectedSuggestion.schedule.expirationDate &&
							startMoment.isAfter(selectedSuggestion.schedule.expirationDate))
					) {
						selectedSuggestion.schedule.startDate = null;
					}
				}

				suggestionsMap.set(key, selectedSuggestion);
				return [...value, selectedSuggestion];
			});
		},
		[suggestionsMap]
	);

	const clearSelectedSuggestions = React.useCallback(() => {
		suggestionsMap.clear();
		dateMap.clear();
		setSelectedSuggestions([]);
	}, [dateMap, suggestionsMap]);

	const onSuccessCallbackRef = React.useRef(onSuccess);
	onSuccessCallbackRef.current = onSuccess;
	const onScheduleSuccess = React.useCallback(
		(result: IMultiCampaignSchedulerResult) => {
			const event = new CustomEvent<IMultiCampaignSchedulerResult>(SUGGESTIONS_SCHEDULE_SUCCESS_EVENT, {
				detail: result,
				cancelable: true,
				bubbles: false,
			});
			onSuccessCallbackRef.current?.(event);
			clearSelectedSuggestions();
			return event;
		},
		[clearSelectedSuggestions]
	);

	/**
	 * @Note Can be null if the suggestion has no start date.
	 */
	const getStartDateForSuggestion = React.useCallback(
		(suggestion: IContentCalendarSelectedSuggestion) => {
			const key = getUniqueIdForSuggestion(suggestion);
			const overrideDate = dateMap.get(key);
			return overrideDate
				? overrideDate
				: suggestion.schedule.startDate
					? new Date(suggestion.schedule.startDate)
					: null;
		},
		[dateMap]
	);

	const updateStartDateForSuggestion = React.useCallback(
		(suggestion: IContentCalendarSelectedSuggestion, date: Date) => {
			const key = getUniqueIdForSuggestion(suggestion);
			const matchingSuggestion = suggestionsMap.get(key);
			if (!matchingSuggestion) {
				return;
			}

			setDateMap(value => {
				value.set(key, date);
				return new Map(value);
			});
		},
		[suggestionsMap]
	);

	const context = React.useMemo(() => {
		return {
			dragDropSelectedSuggestions,
			clearSelectedSuggestions,
			deselectSuggestion,
			hasSelectedSuggestion,
			scheduleSelectedSuggestions,
			selectedSuggestions,
			selectSuggestion,
			setSelectedSuggestions,
			supportsBulkScheduleSuggestions,
			getStartDateForSuggestion,
			updateStartDateForSuggestion,
			onScheduleSuccess,
		} as const;
	}, [
		dragDropSelectedSuggestions,
		clearSelectedSuggestions,
		deselectSuggestion,
		hasSelectedSuggestion,
		scheduleSelectedSuggestions,
		selectedSuggestions,
		selectSuggestion,
		supportsBulkScheduleSuggestions,
		getStartDateForSuggestion,
		updateStartDateForSuggestion,
		onScheduleSuccess,
	]);
	return context;
}

export type ICampaignCalendarSuggestionsContext = ReturnType<typeof useCreateCampaignCalendarSuggestionsContext>;

export const CampaignCalendarSuggestionsContext = React.createContext<ICampaignCalendarSuggestionsContext>(undefined);

export const useCampaignCalendarSuggestionsContext = () =>
	useContextGuard(CampaignCalendarSuggestionsContext, 'CampaignCalendarSuggestionsContext');

export function CampaignCalendarSuggestionsProvider({
	children,
	...props
}: React.PropsWithChildren<ICampaignCalendarSuggestionsContextProps>) {
	const context = useCreateCampaignCalendarSuggestionsContext(props);
	return (
		<CampaignCalendarSuggestionsContext.Provider value={context}>
			{children}
		</CampaignCalendarSuggestionsContext.Provider>
	);
}
