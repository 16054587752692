import { UseQueryResult } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getSocialMediaPlatformLogoUrl,
} from '../../../../../../models/UiUtils';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { RichContentDocumentEditor } from '../../../../../components/richContent/RichContentDocumentEditor';
import { ISelectOption, MultiSelect } from '../../../../../components/Select';
import { Checkbox } from '../../../../../components/Checkbox';
import { PreviewSocialMediaPostCalendar } from '../../../../../components/socialMedia/PreviewSocialMediaMultiScheduler';
import { SliderArrowRight } from '../../../../../components/svgs/icons/SliderArrowRight';
import { brandPrimary } from '../../../../../styles/colors';
import { bs } from '../../../../../styles/styles';
import { styleSheet } from './styles';
import { Noop } from '../../../../../../extViewmodels/Utils';

const TARGETS = [
	{ provider: Api.SocialMediaType.Facebook },
	{ provider: Api.SocialMediaType.Instagram },
	{ provider: Api.SocialMediaType.LinkedIn },
];

export function SocialMediaPostPreviewSelector({
	templateQueries,
}: {
	templateQueries?: UseQueryResult<Api.ITemplate, unknown>[];
}) {
	const [selectedTemplateIndex, setSelectedTemplateIndex] = React.useState<number>(0);

	const nextTemplate = () => {
		if (selectedTemplateIndex < templateQueries.length - 1) {
			setSelectedTemplateIndex(selectedTemplateIndex + 1);
			return;
		}
		setSelectedTemplateIndex(0);
	};

	const prevTemplate = () => {
		if (selectedTemplateIndex > 0) {
			setSelectedTemplateIndex(selectedTemplateIndex - 1);
			return;
		}
		setSelectedTemplateIndex(templateQueries.length - 1);
	};

	return (
		<PreviewSocialMediaPostCalendar targets={TARGETS} template={templateQueries[selectedTemplateIndex]?.data}>
			{templateQueries?.length > 1 ? (
				<>
					<menu className={css(bs.listNone, styleSheet.previewMenu)}>
						{templateQueries.map((templateQuery, i) => {
							if (!templateQuery.isSuccess) {
								return <LoadingSpinner key={i} type='small' />;
							}
							const template = templateQuery.data;
							return (
								<li key={uuidgen()}>
									<button
										type='button'
										className={css(styleSheet.bulletPointButton)}
										onClick={() => setSelectedTemplateIndex(i)}
									>
										<span
											className={css(
												styleSheet.bulletPoint,
												i === selectedTemplateIndex ? styleSheet.bulletPointActive : undefined
											)}
											tabIndex={-1}
										>
											{' '}
										</span>
										{i}. {template.name}
									</button>
								</li>
							);
						})}
					</menu>

					<footer className={css(bs.flex, bs.flexRow, bs.flexBetween, styleSheet.sidebarFooter)}>
						<button type='button' className={css(styleSheet.arrows)} onClick={prevTemplate}>
							Previous Post
							<SliderArrowRight className={css(styleSheet.doubleArrowPrev)} fillColor={brandPrimary} />
						</button>
						<p>Navigate Post Previews</p>
						<button type='button' className={css(styleSheet.arrows)} onClick={nextTemplate}>
							Next Post
							<SliderArrowRight fillColor={brandPrimary} />
						</button>
					</footer>
				</>
			) : null}
		</PreviewSocialMediaPostCalendar>
	);
}

export const MultiCampaignSocialMediaConnection = ({
	options,
	selectedOptions,
	onSelectionChanged,
	provider,
	clearSelection,
}: {
	options: ISelectOption<Api.ISocialMediaConnection>[];
	selectedOptions: ISelectOption<Api.ISocialMediaConnection>[];
	onSelectionChanged: (option: ISelectOption<Api.ISocialMediaConnection>) => void;
	provider: Api.SocialMediaType;
	clearSelection?: () => void;
}) => {
	const selectedConnections = selectedOptions?.map(x => x.dataContext) ?? [];

	return (
		<div key={`${provider}-target`} className={css(bs.wFull)}>
			<div className={css(styleSheet.socialMediaDropdown)}>
				<figure className={css(bs.mr1)}>
					<img key={provider} className={css(bs.h4, bs.w4)} src={getSocialMediaPlatformLogoUrl(provider)} />
				</figure>

				<MultiSelect
					onOptionClick={onSelectionChanged}
					triggerStyles={options?.length === 0 ? [styleSheet.dropdownDisabled] : []}
					disabled={options?.length === 0}
					options={options}
					selectedOptions={selectedOptions}
					renderOption={(option: ISelectOption<Api.ISocialMediaConnection>) => {
						const isSelected = selectedOptions.indexOf(option) > -1;
						return (
							<div
								className={css(
									styleSheet.selectOption,
									option.dataContext?.state !== Api.SocialMediaConnectionState.Connected
										? styleSheet.disconnectedAccount
										: null
								)}
								key={option.id}
							>
								<Checkbox
									checked={isSelected || false}
									id={`connection-option-checkbox-${option.id}`}
									onClick={_e => {
										_e.preventDefault();
										_e.stopPropagation();
										onSelectionChanged(option);
									}}
									onChange={_e => Noop()}
								/>
								<span className={css(bs.truncateText)}>
									@{option.text} - <small>{option.dataContext.postTargetDisplayName}</small>
								</span>
							</div>
						);
					}}
					selectedOptionsTitle={
						<span
							className={css(
								bs.truncateText,
								selectedConnections &&
									selectedConnections.find(connection => connection.state !== Api.SocialMediaConnectionState.Connected)
									? styleSheet.disconnectedAccount
									: undefined
							)}
						>
							{selectedConnections.length === 1 ? (
								<span>
									@{selectedConnections[0].userName} - <small>{selectedConnections[0].postTargetDisplayName}</small>
								</span>
							) : null}

							{selectedConnections.length > 1 ? <span>{selectedConnections.length} accounts selected</span> : null}

							{!selectedConnections.length ? 'Select Account' : null}
						</span>
					}
				/>
				{selectedConnections.length ? (
					<button type='button' className={css(styleSheet.clearButton)} onClick={clearSelection}>
						Clear
					</button>
				) : null}
			</div>
		</div>
	);
};

export function SocialMediaTemplateItem({
	template,
}: {
	template: Api.ITemplate;
}) {
	const previewImageUrl = template?.attachments[0]?.url;

	return (
		<div className={css(bs.flex, bs.flexCol, bs.gap4)}>
			<div className={css(bs.flex)}>
				<figure
					className={css(
						bs.overflowHidden,
						bs.relative,
						styleSheet.itemImageWrap,
						!previewImageUrl ? styleSheet.noImagePlaceholder : undefined
					)}
				>
					{template?.attachments[0]?.mimeType?.startsWith('video/') ? (
						<video src={previewImageUrl} />
					) : previewImageUrl ? (
						<img src={previewImageUrl} alt={`${template.name} image preview`} />
					) : (
						<figcaption className={css(bs.m0)}>No Image Added</figcaption>
					)}
				</figure>
				<RichContentDocumentEditor
					readOnly={true}
					contentState={convertRawRichTextContentStateToRichContentEditorState(template.content)}
					styles={[bs.textField, bs.wFull, bs.h28, bs.overflowAuto, styleSheet.richContentEditor]}
				/>
			</div>
		</div>
	);
}
