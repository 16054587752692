import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
	IContentCalendarSelectedSuggestion,
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ImpersonationContextKey,
	IMultiCampaignSchedulerRequest,
	IMultiCampaignSchedulerResult,
	ModalChildComponentContextKey,
} from '../../../../../../models';
import { CampaignType } from '../../../../../../models/AdminModels';
import { EmailComposerContext, IEmailComposerContext } from '../../../../../../models/Email';
import { useErrorMessages, useToaster, useUserSession } from '../../../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../../../models/Logging';
import {
	accountRequiresCompliance,
	applyDefaultExcludedTagsToContactsFilterRequest,
	getUniqueIdForSuggestion,
	updateContactFilterSendFrom,
} from '../../../../../../models/UiUtils';
import { useTemplateQueries } from '../../../../../../queries';
import { AdminUserSessionContext } from '../../../../../../viewmodels/AdminViewModels';
import { ComposeEmailViewModel } from '../../../../../../viewmodels/AppViewModels';
import { useCampaignCalendarSuggestionsContext } from '../../../../../components/campaigns/CampaignCalendar/context';
import { ComplianceApprovalPrompt } from '../../../../../components/campaigns/ComplianceApprovalPrompt';
import { TagEmailRecipientsList } from '../../../../../components/email/TagEmailRecipientsList';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { PrivateRoute } from '../../../../../components/PrivateRoute';
import { WarningBanner, WarningBannerMessage } from '../../../presentation';
import { MultiCampaignSchedulerLayout } from '../../MultiCampaignSchedulerLayout';
import { MultiEmailTemplateItem } from '../MultiEmailTemplateItem';
import { useScheduleEmailSuggestions } from './hooks';
import { MultiEmailSchedulerSendOptions } from './presentation';
import { styleSheet } from './styles';

function _MultiEmailScheduler({
	impersonationContext,
	parentModal,
}: IImpersonationContextComponentProps & IModalContext) {
	const userSession = useUserSession<AdminUserSessionContext>();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('MultiEmailScheduler');
	const toaster = useToaster();
	const match = useRouteMatch();
	const history = useHistory();
	const location = useLocation<ILocationState<any, IMultiCampaignSchedulerRequest>>();

	const {
		deselectSuggestion,
		selectedSuggestions,
		getStartDateForSuggestion,
		updateStartDateForSuggestion,
		onScheduleSuccess,
	} = useCampaignCalendarSuggestionsContext();

	const requiresCompliance = accountRequiresCompliance(userSession, impersonationContext);

	const getInitialSendFrom = () => {
		if (!impersonationContext?.user) {
			if (!impersonationContext?.account) {
				return Api.SendEmailFrom.CurrentUser;
			}
			return Api.SendEmailFrom.ContactOwner;
		}
		const currentUserId = userSession.user.id;
		const impersonatingUserId = impersonationContext.user.id;

		if (currentUserId === impersonatingUserId) {
			return Api.SendEmailFrom.CurrentUser;
		}

		return Api.SendEmailFrom.SelectedUser;
	};

	const [sendFrom, setSendFrom] = React.useState<Api.ISendEmailFrom>(getInitialSendFrom());
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(null);

	const templateQueries = useTemplateQueries({
		templateIds: selectedSuggestions.map(t => t.templateReference.templateId),
	});
	const templates = templateQueries?.map(query => query.data) ?? [];

	const scheduledEmailResponsesRef = React.useRef<Map<string, Api.ISendEmailResponse>>(new Map());
	const suggestionEmailErrorsRef = React.useRef<Set<[IContentCalendarSelectedSuggestion, Api.IOperationResultNoValue]>>(
		new Set()
	);

	const scheduleMutation = useScheduleEmailSuggestions({
		onError: error => {
			logApiError('ScheduleEmailSuggestions-Error', error);
			errorMessages.pushApiError(error);
		},
		onSuccess: (results, params) => {
			results.forEach((result, i) => {
				const suggestion = params.suggestions[i];
				if (result.status === 'fulfilled') {
					const response = result.value;
					scheduledEmailResponsesRef.current.set(getUniqueIdForSuggestion(suggestion), response);
				} else {
					const error = Api.asApiError(result.reason);
					const matchingSuggestion = selectedSuggestions.find(
						s => getUniqueIdForSuggestion(suggestion) === getUniqueIdForSuggestion(s)
					);
					suggestionEmailErrorsRef.current.add([matchingSuggestion, error]);
				}
			});

			if (scheduledEmailResponsesRef.current.size === selectedSuggestions.length) {
				const scheduled = Array.from(scheduledEmailResponsesRef.current?.values()) || [];
				const scheduleResult: IMultiCampaignSchedulerResult = {
					scheduledSuggestions: selectedSuggestions,
					withCompliance: emailComposer.emailMessage.options.sendWithCompliance,
				};

				toaster.push({
					message: `${scheduled.length} campaigns scheduled.`,
					type: 'successMessage',
				});

				const event = onScheduleSuccess(scheduleResult);
				if (!event.defaultPrevented) {
					parentModal.onRequestClose();
				}
			}
		},
	});

	const [emailComposer, emailComposerContext] = React.useMemo((): [
		ComposeEmailViewModel<Api.IFollowUpOptions>,
		IEmailComposerContext<ComposeEmailViewModel<Api.IFollowUpOptions>>,
	] => {
		const sharedEmailComposer = new ComposeEmailViewModel(userSession).impersonate(impersonationContext);
		sharedEmailComposer.canCustomizeIndividualEmails = false;
		sharedEmailComposer.emailMessage = new Api.EmailMessageViewModel(userSession).impersonate(impersonationContext);
		sharedEmailComposer.emailMessage.contactsFilterRequest = {
			contactFilterRequest: applyDefaultExcludedTagsToContactsFilterRequest(sharedEmailComposer, {
				criteria: [
					{
						property: Api.ContactFilterCriteriaProperty.OwnedBy,
						value: sendFromUserId,
					},
				],
			}),
			groupByDuplicate: true,
		};
		return [
			sharedEmailComposer,
			{
				emailComposer: sharedEmailComposer,
			},
		] as const;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const partialCurrentUser = React.useMemo(() => {
		const { id, email, primaryEmail, firstName, lastName } = userSession.user;
		return {
			id,
			email,
			primaryEmail,
			firstName,
			lastName,
		};
	}, [userSession]);

	const onNextClicked = (_: React.MouseEvent<HTMLButtonElement>) => {
		if (requiresCompliance) {
			history.push({
				pathname: `${match.path}/compliance-options`,
				state: location.state,
			});
			return;
		}
		finalizeAndSchedule();
	};

	const finalizeAndSchedule = async (sendWithCompliance = false, complianceEmail?: string) => {
		const mainEmailMessage = emailComposer.emailMessage;
		const currentUser = userSession.user;

		suggestionEmailErrorsRef.current?.clear();
		const unscheduledSuggestions = scheduledEmailResponsesRef.current
			? selectedSuggestions.filter(suggestion => {
					return !scheduledEmailResponsesRef.current.has(getUniqueIdForSuggestion(suggestion));
				})
			: selectedSuggestions;

		if (unscheduledSuggestions?.length) {
			const emailMessages = unscheduledSuggestions.map(suggestion => {
				const matchingTemplate = templates.find(template => template.id === suggestion.templateReference.templateId);

				const currentComposer = ComposeEmailViewModel.instanceForTemplate(userSession, matchingTemplate).impersonate(
					impersonationContext
				);
				currentComposer.emailMessage.contactsFilterRequest = mainEmailMessage.contactsFilterRequest;
				currentComposer.emailMessage.contactsToAdd.addAll(mainEmailMessage.contactsToAdd.toArray());
				currentComposer.emailMessage.contactsToOmit.addAll(mainEmailMessage.contactsToOmit.toArray());

				const sendFromUser =
					sendFrom === Api.SendEmailFrom.CurrentUser
						? partialCurrentUser
						: sendFromUserId
							? { id: sendFromUserId }
							: null;

				const emailMessageModel = currentComposer.emailMessage.toJs();
				emailMessageModel.subject = matchingTemplate.subject || matchingTemplate.name;
				emailMessageModel.options = {
					...emailMessageModel.options,
					scheduledSend: {
						expirationDate: matchingTemplate.schedule?.expirationDate,
						criteria: Api.ScheduleCriteria.StartAfter,
						startDate: getStartDateForSuggestion(suggestion).toISOString(),
					},
					sendWithCompliance,
					sendWithComplianceEmail: complianceEmail,
					sendEmailFrom: sendFrom,
					sendEmailFromUser: sendFromUser,
					sendEmailFromUserId: sendFrom === Api.SendEmailFrom.CurrentUser ? currentUser.id : sendFromUserId,
				};

				return emailMessageModel;
			});

			scheduleMutation.mutate({
				impersonationContext,
				suggestions: unscheduledSuggestions,
				emails: emailMessages,
			});
		}
	};

	const onSendFromChange = ({
		sendFrom: newSendFrom,
		sendFromUserId: newSendFromUserId,
	}: {
		sendFrom: Api.ISendEmailFrom;
		sendFromUserId: string | null;
	}) => {
		setSendFrom(newSendFrom);
		setSendFromUserId(newSendFromUserId);
		impersonationContext?.update({
			account: impersonationContext?.account,
			user: newSendFromUserId ? ({ id: newSendFromUserId } as Partial<Api.IUser>) : null,
		});
		updateContactFilterSendFrom({ emailComposer, newSendFrom, newSendFromUserId, partialCurrentUser });
	};

	const suggestionsWithoutDatesCount = selectedSuggestions.filter(s => !getStartDateForSuggestion(s)).length;

	const canSchedule =
		!suggestionsWithoutDatesCount && !scheduleMutation.isLoading && !templateQueries.some(q => q.isLoading);
	return (
		<div className={css(styleSheet.container)}>
			{suggestionsWithoutDatesCount > 0 ? (
				<WarningBanner>
					<>
						<WarningBannerMessage>
							{`You have `}
							<strong>{suggestionsWithoutDatesCount}</strong>
							{` without a send date. Please select a date/time to continue scheduling these posts.`}
						</WarningBannerMessage>
					</>
				</WarningBanner>
			) : null}
			<EmailComposerContext.Provider value={emailComposerContext}>
				<MultiCampaignSchedulerLayout
					sendFrom={sendFrom}
					sendFromUserId={sendFromUserId || null}
					onSendFromChange={onSendFromChange}
					styles={[styleSheet.schedulerContainer]}
					sideBar={
						<Switch>
							<PrivateRoute path={match.url} exact userSession={userSession}>
								<TagEmailRecipientsList
									canClearFilters={false}
									ctaButtonText={requiresCompliance ? 'Next: Compliance Options' : 'Schedule on Suggested Dates'}
									disableContactAdding={false}
									disableContactEdits={false}
									disableContactRemoval={false}
									disableCta={!canSchedule}
									disableDropdown={sendFrom === Api.SendEmailFrom.ContactOwner}
									emailComposer={emailComposer}
									isAutomation={false}
									key='recipients-list'
									onCtaClick={onNextClicked}
									styles={[styleSheet.recipientsList]}
								/>
							</PrivateRoute>
							<PrivateRoute path={`${match.url}/compliance-options`} userSession={userSession}>
								<ComplianceApprovalPrompt
									disabled={!canSchedule}
									isLoading={scheduleMutation.isLoading}
									campaignType={CampaignType.Email}
									onScheduleCompliance={(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string) => {
										e.preventDefault();
										finalizeAndSchedule(true, sendWithComplianceEmail);
									}}
									onScheduleWithoutCompliance={() => finalizeAndSchedule(false)}
								/>
							</PrivateRoute>
						</Switch>
					}
				>
					<div className={css(styleSheet.templatesList)}>
						{templateQueries.map((templateQuery, i) => {
							const suggestion = selectedSuggestions[i];
							const id = getUniqueIdForSuggestion(suggestion);
							if (!templateQuery.isSuccess) {
								return <LoadingSpinner key={`${id}-loading`} type='small' />;
							}

							return (
								<MultiEmailTemplateItem
									key={id}
									scheduled={scheduledEmailResponsesRef.current?.has(getUniqueIdForSuggestion(suggestion))}
									error={Array.from(suggestionEmailErrorsRef.current || []).find(([s]) => s === suggestion)?.[1]}
									template={templateQuery.data}
									scheduleDate={getStartDateForSuggestion(suggestion)}
									onUpdateDate={(newDate: Date) => updateStartDateForSuggestion(suggestion, newDate)}
									onRemove={templateQueries.length > 1 ? () => deselectSuggestion(suggestion) : undefined}
									sendFrom={sendFrom}
									sendFromUserId={sendFrom === Api.SendEmailFrom.CurrentUser ? userSession.user.id : sendFromUserId}
								/>
							);
						})}
					</div>
					<MultiEmailSchedulerSendOptions />
				</MultiCampaignSchedulerLayout>
			</EmailComposerContext.Provider>
		</div>
	);
}
export const MultiEmailScheduler = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(observer(_MultiEmailScheduler));
