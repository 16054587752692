import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { action, computed, runInAction } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import * as Models from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import {
	EDuplicateType,
	shouldShowDuplicateTemplateWarning,
	suppressDuplicateTemplateWarning,
} from '../../../../models/Campaigns';
import { hideDefaultMessageTemplate } from '../../../../models/MessageTemplates';
import { FirstNamePlaceholder, Token } from '../../../../models/Token';
import {
	accountRequiresCompliance,
	applyDefaultExcludedTagsToContactsFilterRequest,
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultBulkMessagingBodyEditorState,
	getDisplayName,
	getFileSizeStringValue,
	getKeyDateKindFromResourceSelectorId,
} from '../../../../models/UiUtils';
import {
	ComposeEmailError,
	ComposeEmailViewModel,
	ComposeResourceEmailViewModel,
} from '../../../../viewmodels/AppViewModels';
import hbdImageGraphic from '../../../assets/hbd_action_graphic.svg';
import { Attachments } from '../../../components/Attachments';
import { Avatar } from '../../../components/Avatar';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { Dropdown } from '../../../components/Dropdown';
import { HandwrittenCardTemplateSearchModal } from '../../../components/HandwrittenCards/HandwrittenCardTemplateSearchModal';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Modal } from '../../../components/Modal';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { Popover, PopoverType } from '../../../components/Popover';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { SystemTemplateTranslationSelector } from '../../../components/SystemTemplateTranslationSelector';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../../components/autocomplete/SimpleAutoCompleteSearchField';
import { AutomationPreview } from '../../../components/automation/AutomationPreview';
import { DuplicateWarningDialog } from '../../../components/campaigns/DuplicateWarningDialog';
import { AlternateKeyDatesHeader } from '../../../components/email/AlternateKeyDatesHeader';
import { SimpleEmailComposer } from '../../../components/email/EmailComposer';
import {
	IPlaceholderPopoverProps,
	MergeFieldPopOverMessage,
} from '../../../components/email/EmailComposer/presentation';
import { TagEmailRecipientsList } from '../../../components/email/TagEmailRecipientsList';
import { EmailMessageTemplateButtons } from '../../../components/email/templates/EmailMessageTemplateButtons';
import { RichContentDocumentEditor } from '../../../components/richContent/RichContentDocumentEditor';
import { SocialMediaBannerGraphic } from '../../../components/svgs/graphics/SocialMediaBannerGraphic';
import { DisclosureIcon } from '../../../components/svgs/icons/DisclosureIcon';
import { ExpiryIcon } from '../../../components/svgs/icons/ExpiryIcon';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { TemplateTypeTag } from '../../../components/templates/TemplateTypeTag';
import { baseStyleSheet } from '../../../styles/styles';
import { AIAssistantButton } from '../../AIAssistantButton';
import { FollowUpContentSelector } from '../FollowUpContentSelector';
import { Search } from '../Search';
import {
	IConnectionTypeComponentProps,
	IEmailContentGenerationContextProviderComponentProps,
	withConnectionTypes,
	withEmailContentGenerationContextProvider,
} from './context';
import {
	EditCampaignContentCreationTips,
	EditCampaignEmailFollowUpRecipients,
	EditCampaignRecipients,
	EditCampaignSurveyFollowUpRecipientOptions,
	EditCampaignSurveyFollowUpRecipients,
	EmailContentGenerationWizard,
	StartAutomationBanner,
} from './presentation';
import { styleSheet } from './styles';

const LazyHtmlNewsletterCampaignComposer = React.lazy(
	() => import(/* webpackPrefetch: true */ '../../../components/HtmlNewsletterCampaignComposer')
);

const NavigateConfirmationOptions: IConfirmationDialogOption<boolean>[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: "Yes, don't save",
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Stay on this campaign',
	},
];

const adminRegExp = new RegExp(/admin/i);
const customRegExp = new RegExp(/custom/i);
const TurningXXRexExp = new RegExp(/xx/i);

export interface IEditCampaignComponent {
	finishEditingContent?(): void;
	resetSignature?(userId?: string): void;
	validateBaseEmailBeforeSending?(): boolean;
}

interface ISendOnBehalfOption {
	onClick(): void;
	text: string;
}

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps,
		Models.IImpersonationContextComponentProps,
		IFullscreenModalComponentProps,
		IEmailContentGenerationContextProviderComponentProps,
		IConnectionTypeComponentProps {
	canImpersonateOnTemplateSave?: boolean;
	canShowTemplateSelect?: boolean;
	className?: string;
	contextFinishedEditingCtaText?: string;
	disableCampaignEdits?: boolean;
	disableContactEdits?: boolean;
	editing?: boolean;
	emailComposer: ComposeEmailViewModel<Api.IEmailMessageFollowUpOptions>;
	initialEmailBodyTemplate?: Api.ITemplate;
	isQueuedCampaign?: boolean;
	onAutomationsCreated?(result: Api.ICreateAutomationsResult): void;
	onContentFinishedEditingClicked?(e: React.MouseEvent<HTMLElement>): void;
	onContextFinishedEditingClicked?(e: React.MouseEvent<HTMLElement>): void;
	onInnerRef?(ref?: IEditCampaignComponent): void;
	onRejectClicked?(): void;
	onRenderSendFromOptions?(): React.ReactNode;
	onShowOmitConfirmation?(e?: React.MouseEvent<HTMLElement>, hasChanged?: boolean): void;
	onSocialMediaPostBannerClicked?(e: React.MouseEvent<HTMLElement>): void;
	readonlyContent?: boolean;
	showForInitialSelection?: boolean;
	styles?: StyleDeclarationValue[];
}

interface IState {
	automationTemplatesForResourceSelectorId?: Api.AutomationTemplateViewModel[];
	bodyError?: string;
	canShowContentCreationTips?: boolean;
	duplicateType?: EDuplicateType;
	editing?: boolean;
	emailBodyEditorState?: Api.IRichContentEditorState;
	emailBodyTemplate?: Api.ITemplate;
	hasEdited?: boolean;
	hideTemplateSaveButton?: boolean;
	isDuplicateModalOpen?: boolean;
	isNewEmail?: boolean;
	isPriorFollowUp?: boolean;
	lastContactListScrollOffset?: number;
	levPlaceholderPopover?: IPlaceholderPopoverProps;
	navigateAway?(): void;
	newContactFirstName?: string;
	previousContactFirstName?: string;
	redirectTo?: string;
	selectAnEmployeeModalIsOpen?: boolean;
	selectedAutomationTemplate?: Api.AutomationTemplateViewModel;
	selectedCustomEmailHasCustomContent?: boolean;
	sendFromDropdownIsOpen?: boolean;
	showDeleteConfirmation?: boolean;
	showHwcTemplateChooserModal?: boolean;
	showNavigateConfirmation?: boolean;
	showOmitConfirmation?: boolean;
	signatureTemplate?: Api.ITemplate | null;
	subject?: string;
	subjectError?: string;
	templates?: Api.TemplatesViewModel;
	tempSelectedEmployee?: Api.IUser;
	translatedEmailBodyTemplate?: Api.ITemplate;
	unsubscribeSignatureTemplate?: Api.ITemplate;
}

class _EditCampaign extends React.Component<IProps, IState> implements IEditCampaignComponent {
	private mMounted: boolean;
	private mRecipientListScrollerRef: HTMLElement;
	private mTemplates: Api.TemplatesViewModel;
	private mUnsubscribeTemplate: Api.UnsubscribeTemplateViewModel;
	private mAutomationTemplates: Api.AutomationTemplatesViewModel;
	public readonly state: IState;

	constructor(props: IProps) {
		super(props);
		this.mTemplates = new Api.TemplatesViewModel(props.userSession, {
			userId: props.emailComposer?.campaign?.creator?.id,
		}).impersonate(props.impersonationContext);
		this.mUnsubscribeTemplate = new Api.UnsubscribeTemplateViewModel(props.userSession).impersonate(
			props.impersonationContext
		);
		if (props.userSession.account.features.automation?.enabled) {
			this.mAutomationTemplates = new Api.AutomationTemplatesViewModel(props.userSession).impersonate(
				props.impersonationContext
			);
		}

		/**
		 * @NOTE This is used to differentiate between the create flow for a follow-up and the edit flow In the create and the
		 * edit flows we have a value for this.props.emailComposer.emailMessage.options.followUp.bulkEmailMessageId, but
		 * only in the edit flow do we have a value for this.props.emailComposer.emailMessage.options.followUp.statuses on
		 * init of the component. "On init" is also important. In the create flow statuses is null on init, but at the end
		 * it will have a value. Thus, we cannot do this check later or change this to a @computed getter either.
		 */
		const isPriorFollowUp = this.props.emailComposer?.emailMessage?.options?.followUp?.statuses?.length > 0;

		const isNewEmail =
			!props.initialEmailBodyTemplate && !props.emailComposer?.emailMessage?.templateReference && !isPriorFollowUp;

		this.state = {
			canShowContentCreationTips: isNewEmail && !props.canShowTemplateSelect && !props.emailComposer?.reachOutInfo,
			editing:
				isNewEmail ||
				(this.keyDateKind
					? !Api.TemplatesViewModel.userHasTemplateForKeyDateKind(props.userSession, this.keyDateKind)
					: false),
			emailBodyEditorState: convertRawRichTextContentStateToRichContentEditorState(
				props.emailComposer?.emailMessage?.content
			),
			emailBodyTemplate: props.initialEmailBodyTemplate,
			hasEdited: false,
			isDuplicateModalOpen: false,
			isNewEmail,
			isPriorFollowUp,
			selectAnEmployeeModalIsOpen: false,
			sendFromDropdownIsOpen: false,
			showNavigateConfirmation: false,
			subject: props.emailComposer?.emailMessage?.subject,
			templates: new Api.TemplatesViewModel(props.userSession, {
				userId: props.emailComposer?.campaign?.creator?.id,
			}).impersonate(props.impersonationContext),
		};
	}

	@computed
	public get keyDateKind() {
		const resourceVM = this.props.emailComposer as ComposeResourceEmailViewModel;
		return getKeyDateKindFromResourceSelectorId(resourceVM?.resourceSelector) ?? null;
	}

	public async componentDidMount() {
		const { onInnerRef, emailComposer } = this.props;
		const { emailBodyTemplate } = this.state;
		this.mMounted = true;
		onInnerRef?.(this);
		this.loadDefaultSignatureTemplate();
		this.loadUnsubscribeSignatureTemplate();

		// load the automation templates first
		await this.loadAutomationTemplates();
		this.loadInitialBodyTemplate();
		if (!emailComposer.emailMessage.attachments) {
			emailComposer.emailMessage.setAttachments(new Api.AttachmentsToBeUploadedViewModel([]));
		}

		emailComposer.isUserEditingMessage = true;

		// Ref: https://github.com/Real-Magic/Levitate/issues/11737
		// Only worry about showing the duplicate warning if used by the the current user.
		const duplicateType = EDuplicateType.User;
		const showDuplicateWarning = shouldShowDuplicateTemplateWarning(emailBodyTemplate);
		if (showDuplicateWarning) {
			this.setState({
				isDuplicateModalOpen: showDuplicateWarning,
			});
		}
		this.setState({ duplicateType });

		if (
			emailComposer.isCustomAutomation(this.action) &&
			emailComposer.emailMessage?.isSendFromOption(Api.SendEmailFrom.ContactOwner)
		) {
			emailComposer.sendOnBehalf = true;
			emailComposer.sendAsEmployee = null;
			emailComposer.sendAsConnectionType = undefined;
		}
	}

	public resetSignature = (userId?: string) => {
		const { impersonationContext, userSession } = this.props;
		if (!userId) {
			this.setState({ signatureTemplate: null });
			return;
		}
		this.mTemplates = new Api.TemplatesViewModel(userSession, { userId }).impersonate(impersonationContext);
		this.loadDefaultSignatureTemplate(true);
	};

	public componentWillUnmount() {
		this.mMounted = false;
		this.props.onInnerRef?.(null);

		const { emailComposer } = this.props;
		if (emailComposer != null) {
			emailComposer.isUserEditingMessage = false;
		}
	}

	public render() {
		const { className, styles, match, location, userSession, children, emailComposer } = this.props;
		const { redirectTo, showNavigateConfirmation, tempSelectedEmployee } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} edit-campaign ${className || ''}`}>
				{this.renderFullscreenHeader()}
				<div className={css(styleSheet.leftContainer)}>
					<div className={css(styleSheet.templateContainer)}>
						{this.renderHeader()}
						<div className={css(styleSheet.templateContentContainer)}>
							{this.action === 'automation' ? this.renderAutomationPreview() : this.renderEmailContent()}
						</div>
					</div>
				</div>
				{children ? (
					<div className={css(styleSheet.contextContainer)}>
						<Switch location={location}>
							{this.renderEmailContentGenerationWizardRoute()}
							<PrivateRoute location={location} userSession={userSession}>
								{children}
							</PrivateRoute>
						</Switch>
					</div>
				) : this.showForApproval ? (
					<Switch location={location}>
						{this.renderEmailContentGenerationWizardRoute()}
						<PrivateRoute
							exact={true}
							location={location}
							render={this.onRenderRecipientsSelect}
							userSession={userSession}
						/>
					</Switch>
				) : (
					<div className={css(styleSheet.contextContainer)}>
						<Switch location={location}>
							<PrivateRoute
								exact={true}
								location={location}
								path={`${match.url}/tags`}
								render={this.renderGlobalSearch}
								userSession={userSession}
							/>
							<PrivateRoute
								exact={true}
								location={location}
								path={`${match.url}/recipients/survey-follow-up/from-report`}
								render={this.onRenderSurveyFollowUpRecipientsOptions}
								userSession={userSession}
							/>
							<PrivateRoute
								exact={true}
								location={location}
								path={`${match.url}/recipients/survey-follow-up`}
								render={this.onRenderSurveyFollowUpRecipients}
								userSession={userSession}
							/>
							<PrivateRoute
								exact={true}
								location={location}
								path={`${match.url}/recipients/follow-up`}
								render={this.onRenderRecipientsFollowUpSelect}
								userSession={userSession}
							/>
							<PrivateRoute
								exact={true}
								location={location}
								path={`${match.url}/recipients/:id`}
								render={this.onRenderRecipientCustomContentContext}
								userSession={userSession}
							/>
							<PrivateRoute
								exact={true}
								location={location}
								path={`${match.url}/recipients`}
								render={this.onRenderRecipientsSelect}
								userSession={userSession}
							/>
							{this.renderEmailContentGenerationWizardRoute()}
						</Switch>
					</div>
				)}
				{this.state.showHwcTemplateChooserModal ? (
					<HandwrittenCardTemplateSearchModal
						isOpen={true}
						onClose={() => this.setState({ showHwcTemplateChooserModal: false })}
						onTemplateClicked={template => {
							this.props.fullscreenModal.history.push({
								pathname: `/bulk-birthday-postcard/${template.id}`,
							});
						}}
					/>
				) : null}
				{emailComposer?.canSendOnBehalf && (
					<Modal
						isOpen={this.state.selectAnEmployeeModalIsOpen}
						onRequestClose={this.onSelectAnEmployeeClose}
						useDefaultHeader={true}
					>
						<div className={css(styleSheet.selectAnEmployeeModal)}>
							<div className={css(styleSheet.title, baseStyleSheet.truncateText)}>Select an Employee</div>
							<div className={css(styleSheet.modalBodyText)}>
								Since you are the admin of this Levitate account, you can select another employee so this email will
								come from their email instead. They must be a Levitate user.
							</div>
							<SimpleAutoCompleteSearchField
								onClear={this.clearSelectedEmployee}
								// FOLLOWUP: Resolve
								// @ts-ignore
								onItemSelected={this.onEmployeeSelected}
								onKeyDown={this.onSelectAnEmployeeChange}
								placeholder='Search employee'
								style={styleSheet.searchEmployeeBox}
								type={Api.ResourceAutoCompleteViewModelType.User}
								onCreateAutoCompleteViewModel={this.onCreateAutoCompleteViewModel}
							/>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.selectAnEmployeeCTA)}
								style={{ marginBottom: 5 }}
								onClick={this.onSaveSelectAnEmployee}
								disabled={!tempSelectedEmployee}
							>
								<span>Save</span>
							</button>
						</div>
					</Modal>
				)}
				<DeleteConfirmation
					bodyText='Are you sure you want to delete this template?'
					destructiveButtonText='Yes, Delete'
					isOpen={this.state.showDeleteConfirmation}
					onFinish={this.onDeleteTemplate}
				/>
				{redirectTo && <Redirect push={true} to={redirectTo} />}
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: showNavigateConfirmation,
						onRequestClose: this.onNavigateConfirmationRequestClose,
					}}
					options={NavigateConfirmationOptions}
					title='Navigating away will erase any unsaved changes you have made to this message. Are you sure?'
				/>

				<DuplicateWarningDialog
					isOpen={this.state.isDuplicateModalOpen}
					onRequestClose={this.onIsDuplicateModalRequestClose}
					title='Campaign Duplicate'
					type={CampaignType.Email}
					duplicateType={this.state.duplicateType}
				/>
			</div>
		);
	}

	private onIsDuplicateModalRequestClose = (suppress?: boolean, cancel?: boolean) => {
		const { fullscreenModal } = this.props;
		if (suppress) {
			suppressDuplicateTemplateWarning(this.state.emailBodyTemplate.id);
		}
		if (cancel) {
			this.setState({ isDuplicateModalOpen: false });
			fullscreenModal.dismissModal();
			return;
		}
		this.setState({ isDuplicateModalOpen: false });
	};

	private renderEmailContentGenerationWizardRoute() {
		const { match, location, userSession } = this.props;
		const { emailBodyEditorState, emailBodyTemplate, isNewEmail, subject } = this.state;
		return (
			<PrivateRoute location={location} path={`${match.url}/ai-content-generation-wizard`} userSession={userSession}>
				<EmailContentGenerationWizard
					content={emailBodyEditorState?.getRawRichTextContent()}
					isNewEmail={isNewEmail}
					onContentGenerated={this.onEmailContentGenerated}
					subject={subject}
					templateId={emailBodyTemplate?.id}
				/>
			</PrivateRoute>
		);
	}

	private renderFullscreenHeader() {
		const { readonlyContent, emailComposer } = this.props;
		const { isNewEmail, emailBodyTemplate } = this.state;
		let fullscreenTitle: string = null;
		if (this.action === 'automation') {
			fullscreenTitle = 'View Campaign';
		} else if (isNewEmail) {
			fullscreenTitle = emailComposer?.isFollowUp ? 'Create a Follow-Up Campaign' : 'Create New Campaign';
		} else {
			const content = emailBodyTemplate?.content || emailComposer.emailMessage.content;
			if (content?.sourceFormat === Api.ContentSourceFormat.UnlayerHtmlNewsletter) {
				fullscreenTitle = 'View Campaign';
			} else if (readonlyContent && emailComposer?.isFollowUp) {
				fullscreenTitle = 'View Follow-Up Campaign';
			} else {
				fullscreenTitle = 'View Campaign';
			}
		}

		return (
			<MultiContainerHeader
				fullscreenHeader={fullscreenTitle}
				onFullscreenRequestBack={this.onHeaderBackButtonClicked}
				onFullscreenRequestClose={this.onCloseButtonClicked}
			/>
		);
	}

	private renderReadOnlyContent(hasActionsHeader = false) {
		const { userSession, emailComposer } = this.props;
		const { emailBodyEditorState, levPlaceholderPopover, signatureTemplate, subject, unsubscribeSignatureTemplate } =
			this.state;

		const editorConfig = {
			contentRawCss: Models.DefaultRichContentRawCSS,
			plugins: 'placeholders',
			useDefaultEmailPlaceholders: true,
		};

		return (
			<div
				className={css(
					styleSheet.readonlyTemplateContent,
					hasActionsHeader ? styleSheet.readonlyTemplateContentWithActionsHeader : null
				)}
			>
				<div className={css(styleSheet.readonlyTemplateContentHeader)}>
					<div className={css(baseStyleSheet.truncateText)}>
						<div>Subject</div>
						<div className={css(baseStyleSheet.truncateText, styleSheet.subject)}>{subject}</div>
					</div>
				</div>
				<div className={css(styleSheet.readonlyTemplateContentScroller)}>
					<div className={css(styleSheet.readonlyTemplateContentBody)}>
						<RichContentDocumentEditor
							config={editorConfig}
							contentState={emailBodyEditorState}
							onExecuteCommand={this.onReadonlyEditorExecuteCommand}
							readOnly={true}
							readonlyChildren={
								levPlaceholderPopover ? (
									<Popover
										anchor={
											<span
												style={{
													left: levPlaceholderPopover.coordinate.x,
													position: 'absolute',
													top: levPlaceholderPopover.coordinate.y,
												}}
											/>
										}
										dismissOnClickOutside={true}
										isOpen={true}
										preferredPlacement='right'
										onRequestClose={this.onDismissLevPopover}
										type={PopoverType.white}
									>
										<MergeFieldPopOverMessage
											dismiss={this.onDismissLevPopover}
											emailComposer={emailComposer}
											individualRecipient={
												emailComposer?.selectedCustomEmail ? emailComposer?.selectedCustomEmailContact : null
											}
											popoverProps={levPlaceholderPopover}
										/>
									</Popover>
								) : undefined
							}
							styles={[styleSheet.readonlyTemplateEditor]}
						/>
					</div>
					<div className={css(styleSheet.readonlyTemplateContentFooter)}>
						{signatureTemplate && (
							<RichContentDocumentEditor
								config={{ contentRawCss: Models.DefaultRichContentRawCSS }}
								contentState={convertRawRichTextContentStateToRichContentEditorState(signatureTemplate.content)}
								readOnly={true}
								styles={[styleSheet.readonlyTemplateEditor]}
							/>
						)}
						{unsubscribeSignatureTemplate && emailComposer?.recipients?.length > 4 && !this.keyDateKind && (
							<div className={css(styleSheet.readonlyTemplateContentFooterUnsubscribe)}>
								<RichContentDocumentEditor
									config={{ contentRawCss: Models.DefaultRichContentRawCSS }}
									contentState={convertRawRichTextContentStateToRichContentEditorState(
										unsubscribeSignatureTemplate.content
									)}
									readOnly={true}
									styles={[styleSheet.readonlyTemplateEditor]}
								/>
							</div>
						)}
					</div>
					{(emailComposer?.emailMessage?.savedAttachments?.length > 0 ||
						emailComposer?.emailMessage?.attachments?.attachments?.length > 0) && (
						<div className={css(styleSheet.attachmentContainer)}>
							<Attachments
								attachments={emailComposer?.emailMessage?.savedAttachments?.map(
									x => new Api.FileAttachmentViewModel(userSession, '', x)
								)}
								newAttachments={emailComposer?.emailMessage?.attachments}
								disableOnClick={true}
								readonly={true}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}

	@computed
	private get sendingOnBehalf() {
		const { emailComposer } = this.props;
		return emailComposer?.emailMessage?.isSendFromOption(
			Api.SendEmailFrom.ContactOwner || Api.SendEmailFrom.SelectedUser
		);
	}

	@computed
	private get canShowAlternateHeader(): boolean {
		const { emailComposer } = this.props;
		const { automationTemplatesForResourceSelectorId } = this.state;

		const isFromTemplate = !!emailComposer?.emailMessage?.templateReference?.templateId;
		/**
		 * @NOTE - This list doesnt take in to account the default template option. This is because
		 * the default endpoint returns a template for the resourceSelectorId, which is
		 * not necessarily the same as the template that is in the dropdown for the
		 * emailComposer. So when we have only two templates in the list, the
		 * selected template (ie: the default) may be one of them and thus when we filter the list we
		 * may still have a template option that is not being shown.
		 */
		const filteredTemplates = emailComposer?.alternateKeyDateTemplates?.filter(
			x => x.id !== emailComposer.emailMessage.templateReference.templateId
		);
		// alternate list has templates besides the currently selected one.
		const hasAlternateTemplates = isFromTemplate
			? filteredTemplates?.length
			: emailComposer?.alternateKeyDateTemplates?.length > 0;
		const hasAutomationTemplates = automationTemplatesForResourceSelectorId?.length > 0;
		const hasValidOptions = hasAutomationTemplates || (hasAlternateTemplates && isFromTemplate);
		const isFromKeyDate = !!this.keyDateKind;
		const selectedCustomEmail = emailComposer?.selectedCustomEmail;

		return isFromKeyDate && hasValidOptions && !selectedCustomEmail;
	}

	// #region Header
	private renderHeader() {
		const { emailComposer, userSession, impersonationContext, emailContentGenerationContext } = this.props;
		const { automationTemplatesForResourceSelectorId, emailBodyTemplate, translatedEmailBodyTemplate } = this.state;
		// intentionally disabling social media indicator while under impersonation context until we have compliance figured out
		const isSocialMedia =
			!impersonationContext?.isValid &&
			(emailBodyTemplate?.associatedTemplates?.find(x => x.templateType === Api.TemplateType.SocialMediaPost) ||
				emailComposer?.suggestion?.template?.associatedTemplates?.find(
					x => x.templateType === Api.TemplateType.SocialMediaPost
				));
		const isSocialMediaEnabled = userSession.account?.features?.socialMedia?.enabled;
		const formattedExpirationDate = emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate
			? new Date(emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate)
			: new Date(emailBodyTemplate?.schedule?.expirationDate);

		const canShowTranslationOption =
			((impersonationContext?.isValid ? impersonationContext.account : null) || userSession.account)?.features
				?.translation?.enabled &&
			this.action !== 'automation' &&
			!emailComposer?.selectedCustomEmailContact &&
			(emailBodyTemplate?.scope === Api.TemplateScope.System ||
				emailBodyTemplate?.scope === Api.TemplateScope.Industry);

		const shouldRenderStartAutomationsBanner =
			userSession.account.features?.automation?.enabled &&
			!this.mAutomationTemplates?.isLoadingTemplatesByResourceId &&
			(automationTemplatesForResourceSelectorId || '').length === 0 &&
			this.action !== 'automation' &&
			!(this.action === 'edit' && !!emailComposer?.selectedCustomEmailContact);

		return (
			<div className={css(styleSheet.templateContainerHeader)}>
				<div className={css(styleSheet.templateContainerHeaderRow)}>
					<div>
						{this.canShowAlternateHeader ? this.renderAlternateKeyDatesHeader() : this.renderNormalHeader()}
						{shouldRenderStartAutomationsBanner
							? this.renderSendFromDropdown(styleSheet.sendFromDropdownContainerWithAutomationsBanner)
							: (emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate ||
									emailBodyTemplate?.schedule?.expirationDate) && (
									<div className={css(styleSheet.expirationDateContainer)}>
										<ExpiryIcon />
										<span className={css(styleSheet.expirationDate)}>
											{`This email automatically stops sending after ${formattedExpirationDate.toLocaleDateString()}.`}
										</span>
									</div>
								)}
					</div>
					{this.keyDateKind === Api.KeyDateKind.Birthday ? (
						<div>
							<div
								className={css(styleSheet.hbdCard)}
								onClick={() => this.setState({ showHwcTemplateChooserModal: true })}
							>
								<img src={hbdImageGraphic} alt='' className={css(styleSheet.hbdCardGraphic)} />
								<p className={css(styleSheet.hbdCardText)}>Send a handwritten bday card</p>
							</div>
						</div>
					) : null}
					{shouldRenderStartAutomationsBanner || (isSocialMedia && isSocialMediaEnabled) ? (
						<div>
							{shouldRenderStartAutomationsBanner ? this.renderStartAutomationsBanner() : null}
							{isSocialMedia && isSocialMediaEnabled ? this.renderCreateSocialMediaPostBanner() : null}
						</div>
					) : null}
				</div>
				<div className={css(styleSheet.templateContainerHeaderRow)}>
					<div>
						{!shouldRenderStartAutomationsBanner
							? this.renderSendFromDropdown()
							: (emailComposer?.emailMessage?.options?.scheduledSend?.expirationDate ||
									emailBodyTemplate?.schedule?.expirationDate) && (
									<div className={css(styleSheet.expirationDateContainer)}>
										<ExpiryIcon />
										<span className={css(styleSheet.expirationDate)}>
											{`This email automatically stops sending after ${formattedExpirationDate.toLocaleDateString()}.`}
										</span>
									</div>
								)}
					</div>
					{canShowTranslationOption ? (
						<SystemTemplateTranslationSelector
							disabled={emailContentGenerationContext?.contentGenerationStatus === AIContentGenerationStatus.Generating}
							onTranslatedTemplateSelected={this.onTranslatedSystemTemplateSelected}
							originalTemplate={emailBodyTemplate}
							selectedTemplate={translatedEmailBodyTemplate}
						/>
					) : null}
				</div>
			</div>
		);
	}

	private renderAlternateKeyDatesHeader() {
		const { emailBodyTemplate, automationTemplatesForResourceSelectorId, selectedAutomationTemplate } = this.state;
		const { emailComposer, initialEmailBodyTemplate } = this.props;
		const selector = (emailComposer as ComposeResourceEmailViewModel).resourceSelector;
		let emailTemplate: Api.ITemplate = initialEmailBodyTemplate;
		if (selector?.startsWith('Custom') || selector?.includes('XX')) {
			emailTemplate = { content: null, name: 'New Email' };
		}

		return (
			<AlternateKeyDatesHeader
				alternateKeyDateTemplates={emailComposer?.alternateKeyDateTemplates}
				automationTemplates={automationTemplatesForResourceSelectorId}
				emailMessage={emailComposer?.emailMessage}
				initialEmailBodyTemplate={emailTemplate}
				onSetTemplate={this.setTemplate}
				selectedTemplate={this.action === 'automation' ? selectedAutomationTemplate : emailBodyTemplate}
			/>
		);
	}

	private renderNormalHeader() {
		const { emailComposer, match, location } = this.props;
		const { emailBodyTemplate, isNewEmail } = this.state;

		return (
			<div>
				<div className={css(baseStyleSheet.truncateText)}>
					{isNewEmail ? (
						emailComposer?.isFollowUp ? (
							'Follow-Up Campaign'
						) : (
							'New Email'
						)
					) : (
						<div>
							<div className={css(styleSheet.nameContainer)}>
								<div className={css(baseStyleSheet.truncateText)}>
									{emailBodyTemplate?.name || emailComposer?.emailMessage?.subject}
								</div>
								{this.isFromHtmlNewsletterTemplate && (
									<TemplateTypeTag styles={[styleSheet.templateTypeTag]} template={emailBodyTemplate} />
								)}
							</div>
							{emailComposer?.selectedCustomEmailContact && (
								<div className={css(styleSheet.selectedContactHeader)}>
									<Avatar
										className={css(styleSheet.selectedContactHeaderAvatar)}
										entityVm={emailComposer?.selectedCustomEmailContact}
									/>
									<div className={css(styleSheet.selectedContactHeaderDetails)}>
										<div>{emailComposer?.selectedCustomEmailContact.name}</div>
										<div className={css(baseStyleSheet.truncateText, styleSheet.selectedContactHeaderDetailsMessage)}>
											{`You are currently customizing the email for just ${Api.VmUtils.getDisplayName(
												emailComposer?.selectedCustomEmailContact?.toJs(),
												true
											)}`}
										</div>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
				{isNewEmail && `${match.url}/tags` === location?.pathname && (
					<div className={css(styleSheet.templateContainerHeaderNewEmailMessage)}>
						Start by writing your email content below, then select the recipients you want by selecting one or multiple
						tags.
					</div>
				)}
			</div>
		);
	}

	private renderSendFromDropdown(styleDeclaration?: StyleDeclarationValue) {
		const { onRenderSendFromOptions, emailComposer, connectionTypes } = this.props;
		if (onRenderSendFromOptions) {
			const options = onRenderSendFromOptions();

			if (options) {
				return (
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.sendFromDropdownContainer, styleDeclaration)}>
						<span>Send from:</span>
						{options}
					</div>
				);
			}
		}

		if (emailComposer && emailComposer.canSendOnBehalf && !emailComposer.isSuggestion) {
			const { sendFromDropdownIsOpen } = this.state;

			const anchorText = emailComposer.sendAsConnectionType
				? `${
						connectionTypes.find(connectionType => connectionType.value === emailComposer.sendAsConnectionType).label
					} for the recipient`
				: !emailComposer.emailMessage?.options?.sendEmailFrom
					? this.sendOnBehalfOptions[Api.SendEmailFrom.CurrentUser].text
					: emailComposer.emailMessage?.isSendFromOption(Api.SendEmailFrom.SelectedUser) &&
							emailComposer.emailMessage.options.sendEmailFromUser
						? getDisplayName(emailComposer.emailMessage.options.sendEmailFromUser)
						: this.sendOnBehalfOptions[emailComposer.emailMessage.options.sendEmailFrom].text;

			let email = '';
			const options = emailComposer?.emailMessage?.options;
			if (options?.sendEmailFrom === 'SelectedUser') {
				email = !options?.sendEmailFromUser?.email
					? null
					: emailComposer?.emailMessage?.options?.sendEmailFromUser.email;
			} else if (emailComposer?.emailMessage?.options?.sendEmailFrom === 'CurrentUser') {
				email = emailComposer?.emailMessage?.userSession?.user?.email;
			} else {
				email = null;
			}

			return (
				<div className={css(styleSheet.sendFromDropdownContainer, styleDeclaration)}>
					<span>Send from:</span>
					<Dropdown
						anchor={
							<div className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchor)}>
								<span title={email} className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchorText)}>
									{anchorText}
								</span>
								<DisclosureIcon className={css(styleSheet.dropdownIcon)} />
							</div>
						}
						disabled={emailComposer?.sendOnBehalfFromSelection}
						className={css(styleSheet.dropdownInline, styleSheet.sendFromDropdown)}
						contentClassName={css(styleSheet.dropdownMenu)}
						contentPositionY='bottom'
						isOpen={sendFromDropdownIsOpen}
						onOpenChanged={this.onSendFromOpenChanged}
						openOnClick={true}
					>
						<div>
							{Object.values(this.sendOnBehalfOptions).map((x, i) => {
								return (
									<div
										className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
										key={i}
										onClick={x.onClick}
									>
										{x.text}
									</div>
								);
							})}
						</div>
					</Dropdown>
				</div>
			);
		}

		return null;
	}

	private renderCreateSocialMediaPostBanner() {
		const { emailBodyTemplate } = this.state;
		return (
			<div className={css(styleSheet.socialMediaBannerContainer)}>
				<button
					onClick={
						emailBodyTemplate?.associatedTemplates?.length > 0
							? this.onEditSocialMediaPostClicked
							: this.onCreateSocialMediaPostClicked
					}
				>
					<SocialMediaBannerGraphic />
					<span className={css(styleSheet.bannerText)}>Available for social media!</span>
				</button>
			</div>
		);
	}

	private onTranslatedSystemTemplateSelected = (template: Api.ITemplate) => {
		const { emailComposer } = this.props;
		const { emailBodyTemplate } = this.state;
		if (template) {
			const { emailMessage } = emailComposer;
			emailMessage.subject = template.subject ?? emailMessage.subject;
			emailMessage.templateReference = {
				isCustomized: false,
				isSystemTemplate: template.scope === Api.TemplateScope.System || template.scope === Api.TemplateScope.Industry,
				templateId: template.id,
				name: template.name,
			};
			this.setState({
				emailBodyEditorState: convertRawRichTextContentStateToRichContentEditorState(template.content),
				subject: emailMessage.subject,
				translatedEmailBodyTemplate: emailBodyTemplate?.id === template?.id ? null : template,
			});
		}
	};

	private onAIContentGenerationAssistantButtonClicked = () => {
		const { history, match } = this.props;
		history?.push({
			pathname: `${match.url}/ai-content-generation-wizard`,
			state: history.location.state,
		});
	};

	private onEmailContentGenerated = (email: Api.IEmailMessageCompose, undo?: boolean) => {
		const { emailComposer, errorMessages, logApiError } = this.props;
		if (!emailComposer.selectedCustomEmail) {
			/**
			 * We only change the vm if we are not editing content for a specific recipient
			 *
			 * @NOTE - Api.IEmailMessageComposeContact doesn't support aiReference and only content will be saved
			 * when the user goes back to the recipient list
			 */
			emailComposer.emailMessage.content = email.content;
			emailComposer.emailMessage.aiReference = email.aiReference;
			if (email.subject) {
				emailComposer.emailMessage.subject = email.subject;
			}
		}

		this.setState(s => ({
			contentTemplatePriorToTranslation: null,
			emailBodyEditorState: convertRawRichTextContentStateToRichContentEditorState(email.content),
			selectedCustomEmailHasCustomContent: undo ? false : !!emailComposer.selectedCustomEmail,
			subject: email.subject || s.subject,
		}));

		if (this.showForApproval) {
			emailComposer.updateCampaign()?.catch(error => {
				logApiError('UpdateCampaign-Error', error);
				errorMessages.pushApiError(error);
			});
		}
	};

	private onCreateSocialMediaPostClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { history, onSocialMediaPostBannerClicked } = this.props;
		onSocialMediaPostBannerClicked?.(e);
		if (e.isDefaultPrevented()) {
			return;
		}
		history.replace({
			pathname: '/social-media/post/create',
		});
	};

	private onEditSocialMediaPostClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { history, onSocialMediaPostBannerClicked } = this.props;
		const { emailBodyTemplate } = this.state;
		onSocialMediaPostBannerClicked?.(e);
		if (e.isDefaultPrevented()) {
			return;
		}
		if (emailBodyTemplate?.associatedTemplates?.length > 0) {
			const socialMediaTemplateReference = emailBodyTemplate?.associatedTemplates?.find(
				x => x.templateType === Api.TemplateType.SocialMediaPost
			);
			const state: Models.ILocationState<void, Models.ICreateSocialMediaPostRequest<string>> = {
				model: {
					context: socialMediaTemplateReference?.id,
					type: 'TemplateId',
				},
			};
			history.replace({
				pathname: `/social-media/post/create/from-template`,
				state,
			});
			return;
		}
	};

	private onFollowUpContentSelected = (followUpType: Api.FollowUpType, template?: Api.ITemplate) => {
		const { emailComposer } = this.props;
		let bodyTemplate: Api.IRichContentEditorState = null;

		if (followUpType === Api.FollowUpType.New) {
			emailComposer.emailMessage.subject = null;
			emailComposer.emailMessage.content = null;
			bodyTemplate = getDefaultBulkMessagingBodyEditorState();
		} else if (followUpType === Api.FollowUpType.OriginalContent || followUpType === Api.FollowUpType.FromTemplate) {
			emailComposer.emailMessage.subject = template.subject;
			emailComposer.emailMessage.content = template.content;
			emailComposer.emailMessage.templateReference = template?.id
				? {
						isCustomized: false,
						isSystemTemplate:
							template.scope === Api.TemplateScope.System || template.scope === Api.TemplateScope.Industry,
						templateId: template.id,
						name: template.name,
					}
				: null;
			bodyTemplate = convertRawRichTextContentStateToRichContentEditorState(emailComposer.emailMessage.content);
		}
		this.setState({
			emailBodyEditorState: bodyTemplate,
			emailBodyTemplate: template,
			subject: emailComposer.emailMessage.subject,
		});
	};

	private setTemplate = async (template: Api.ITemplate | Api.AutomationTemplateViewModel) => {
		const { emailComposer } = this.props;

		if (template instanceof Api.AutomationTemplateViewModel) {
			// check that sendfrom isn't some random user... if it is, reset to contact owner and update recipients list
			if (emailComposer.emailMessage.options.sendEmailFrom === Api.SendEmailFrom.SelectedUser) {
				await this.sendOnBehalfOf(Api.SendEmailFrom.ContactOwner);
			}
			this.setState({
				selectedAutomationTemplate: template,
			});
		} else {
			emailComposer?.emailMessage?.setContentWithTemplate(template);
			if (emailComposer.emailMessage.options.sendEmailFrom === Api.SendEmailFrom.ContactOwner) {
				await this.sendOnBehalfOf(Api.SendEmailFrom.ContactOwner);
			}

			this.setState({
				emailBodyEditorState: convertRawRichTextContentStateToRichContentEditorState(template.content),
				emailBodyTemplate: template,
				selectedAutomationTemplate: null,
				subject: template.subject,
			});
		}
	};

	@computed
	private get sendOnBehalfOptions() {
		const { emailComposer, userSession, connectionTypes } = this.props;
		const { firstName, lastName } = userSession!.user!;

		const sendOnBehalfOptions: Partial<{
			[key in Api.ISendEmailFrom | string]: ISendOnBehalfOption;
		}> = {
			[Api.SendEmailFrom.CurrentUser]: {
				onClick: () => this.sendOnBehalfOf(Api.SendEmailFrom.CurrentUser),
				text: `${firstName} ${lastName}`,
			},
		};

		const isResourceSelectorEmail = (emailComposer as ComposeResourceEmailViewModel).resourceSelector;

		if (isResourceSelectorEmail || !emailComposer?.emailMessage?.contactsToAdd?.length) {
			sendOnBehalfOptions[Api.SendEmailFrom.ContactOwner] = {
				onClick: () => this.sendOnBehalfOf(Api.SendEmailFrom.ContactOwner),
				text: 'Owner of the recipient',
			};
		}

		if (connectionTypes?.length) {
			connectionTypes.forEach(connectionType => {
				sendOnBehalfOptions[`${Api.SendEmailFrom.ConnectionType}-${connectionType.label}`] = {
					onClick: () => this.sendOnBehalfOf(Api.SendEmailFrom.ConnectionType, connectionType.value),
					text: `${connectionType.label} for the recipient`,
				};
			});
		}

		/** @NOTE - Only allow 'select an employee' if the contact selection was not individually selected */
		if (!emailComposer?.sendOnBehalfFromSelection) {
			sendOnBehalfOptions[Api.SendEmailFrom.SelectedUser] = {
				onClick: this.sendOnBehalfSelectEmployee,
				text: 'Select an employee',
			};
		}

		return sendOnBehalfOptions;
	}

	private onSendFromOpenChanged = (isOpen: boolean) => {
		this.setState({
			sendFromDropdownIsOpen: isOpen,
		});
	};

	private sendOnBehalfOf = async (option: Api.ISendEmailFrom, param?: string) => {
		const { emailComposer, logEvent, userSession, toaster } = this.props;

		if (emailComposer?.emailMessage?.options?.sendEmailFrom) {
			emailComposer.emailMessage.options.sendEmailFrom = option;
			emailComposer.emailMessage.setSendEmailFromUser(undefined);

			this.setState({
				sendFromDropdownIsOpen: false,
				tempSelectedEmployee: null,
			});

			const sorted = Api.VmUtils.sortContactFilterCriteria(
				emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest
					?.criteria as Api.IContactFilterCriteria[]
			);
			const ownedByFilter = sorted.filters.find(x => x.property === Api.ContactFilterCriteriaProperty.OwnedBy);
			// remove the filter
			const filterRequest: Api.IContactsFilterRequest = !ownedByFilter
				? (emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest as Api.IContactsFilterRequest)
				: {
						criteria: [
							...(sorted.filters || []).filter(x => x.property !== Api.ContactFilterCriteriaProperty.OwnedBy),
							...sorted.searches,
							...sorted.compound,
						],
					};

			switch (option) {
				case Api.SendEmailFrom.CurrentUser: {
					this.mTemplates = new Api.TemplatesViewModel(userSession as Api.UserSessionContext, {
						userId: emailComposer?.campaign?.creator?.id,
					});
					if (filterRequest && ownedByFilter && ownedByFilter.value !== userSession?.user?.id) {
						emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
					}

					this.loadDefaultSignatureTemplate(true);
					this.resetAndReloadContactsList();
					if (emailComposer.isCustomAutomation(this.action)) {
						emailComposer.sendOnBehalf = false;
						emailComposer.sendAsEmployee = userSession!.user!.id!;
						emailComposer.sendAsConnectionType = undefined;
					}
					break;
				}
				case Api.SendEmailFrom.ContactOwner:
					if (emailComposer.isCustomAutomation(this.action)) {
						emailComposer.sendOnBehalf = true;
						emailComposer.sendAsEmployee = undefined;
						emailComposer.sendAsConnectionType = undefined;
					}
					if (filterRequest) {
						emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
					}
					this.setState({ signatureTemplate: null }, this.resetAndReloadContactsList);
					break;
				case Api.SendEmailFrom.ConnectionType:
					emailComposer.sendAsConnectionType = param;
					emailComposer.emailMessage.options.sendFromConnectionType = param;
					if (emailComposer.isCustomAutomation(this.action)) {
						emailComposer.sendOnBehalf = true;
						emailComposer.sendAsEmployee = undefined;
					}
					if (filterRequest) {
						emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
					}
					this.setState({ signatureTemplate: null }, this.resetAndReloadContactsList);
					break;
				default:
					break;
			}
		} else {
			logEvent?.('FailedToSetSendOnBehalfOption');
			toaster?.push({
				message: `Failed to set send on behalf option`,
				type: 'errorMessage',
			});
		}
	};

	private sendOnBehalfSelectEmployee = () => {
		const { emailComposer, logEvent, toaster } = this.props;

		if (emailComposer?.emailMessage?.options?.sendEmailFrom) {
			emailComposer.emailMessage.options.sendEmailFrom = Api.SendEmailFrom.SelectedUser;

			const nextState: IState = {
				selectAnEmployeeModalIsOpen: true,
				sendFromDropdownIsOpen: false,
			};

			if (emailComposer.emailMessage.options.sendEmailFromUser) {
				nextState.tempSelectedEmployee = null;
			}

			this.setState(nextState);
		} else {
			logEvent('FailedToSetSendOnBehalfSelectEmployee');
			toaster.push({
				message: `Failed to set send on behalf option to Select Employee`,
				type: 'errorMessage',
			});
		}
	};

	private onSelectAnEmployeeClose = (_: void, cancelled?: boolean) => {
		const { emailComposer } = this.props;
		const nextState: IState = { selectAnEmployeeModalIsOpen: false };

		if (cancelled) {
			nextState.tempSelectedEmployee = null;
		}

		if (emailComposer?.emailMessage?.options && !emailComposer.emailMessage.options.sendEmailFromUser) {
			emailComposer.emailMessage.options.sendEmailFrom = Api.SendEmailFrom.CurrentUser;
			emailComposer.emailMessage?.setSendEmailFromUser(undefined);
		}

		this.setState(nextState);
	};
	// #endregion

	// #region Automations
	private renderStartAutomationsBanner() {
		const { emailComposer, userSession } = this.props;
		return (
			<div>
				<StartAutomationBanner
					account={emailComposer?.impersonationContext?.account || userSession.account.toJs()}
					resourceSelectorId={this.resourceEmailComposer?.resourceSelector}
					onClick={this.onStartAutomationBannerClicked}
				/>
			</div>
		);
	}

	private renderAutomationPreview() {
		const { selectedAutomationTemplate } = this.state;
		return (
			<div className={css(styleSheet.automationPreview)}>
				<div className={css(styleSheet.automationPreviewActions)}>
					<button
						className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.automationPreviewEditAutomationButton)}
						onClick={this.onEditAutomationClicked}
					>
						<span>Exit and edit automation</span>
					</button>
				</div>
				<AutomationPreview template={selectedAutomationTemplate} />
			</div>
		);
	}

	private onEditAutomationClicked = () => {
		this.setState({
			navigateAway: () => {
				const { history } = this.props;
				const { selectedAutomationTemplate } = this.state;
				history.push({
					pathname: `/automations/${selectedAutomationTemplate.id}`,
				});
			},
			showNavigateConfirmation: true,
		});
	};
	// #endregion

	@computed
	private get resourceEmailComposer() {
		const { emailComposer } = this.props;
		const result = emailComposer as ComposeResourceEmailViewModel;
		return result?.resourceSelector ? result : null;
	}

	private onCreateAutoCompleteViewModel = (
		_: Api.ResourceAutoCompleteViewModelType,
		suggestedViewModel: Api.ResourceAutoCompleteViewModel
	) => {
		suggestedViewModel.addParam({ includeDeactivated: false });
		return suggestedViewModel;
	};

	private renderEmailContent() {
		const { emailBodyEditorState, emailBodyTemplate, subject, templates, isPriorFollowUp, isNewEmail } = this.state;
		const {
			canImpersonateOnTemplateSave,
			disableCampaignEdits,
			emailComposer,
			isQueuedCampaign,
			impersonationContext,
			userSession,
		} = this.props;

		const content = emailBodyTemplate?.content || emailComposer.emailMessage.content;
		const shouldRenderActions = !emailComposer?.selectedCustomEmail;
		const isFollowUp = emailComposer.isFollowUp;
		const isEmailFollowUp = emailComposer.followUpSource instanceof Api.CampaignViewModel;
		if (content?.sourceFormat === Api.ContentSourceFormat.UnlayerHtmlNewsletter) {
			return (
				<React.Suspense fallback={this.onRenderHtmlNewsletterCampaignComposerLoading()}>
					<LazyHtmlNewsletterCampaignComposer
						onEditEmailClicked={this.onEditHtmlNewsletterTemplateClicked}
						onSubjectChanged={this.onSubjectChanged}
						readonly={emailComposer.campaign?.id && !isFollowUp}
						subject={subject}
						template={emailBodyTemplate}
					/>
				</React.Suspense>
			);
		}

		const canShowAiContentAssistant =
			this.canEdit &&
			((impersonationContext?.isValid ? impersonationContext.account : null) || userSession.account)?.features
				?.contentGeneration?.enabled &&
			this.action !== 'automation';

		return (
			<div className={css(styleSheet.emailContentContainer)}>
				<div className={css(styleSheet.emailHeaderContainer)}>
					<div className={css(styleSheet.emailHeaderContainerLeft)}>
						{canShowAiContentAssistant ? (
							<AIAssistantButton
								onClick={this.onAIContentGenerationAssistantButtonClicked}
								styleDeclarationValues={[styleSheet.aiAssistantButton]}
							>
								{isNewEmail ? 'Create this email with AI' : 'Rewrite this email using our AI assistant'}
							</AIAssistantButton>
						) : null}
					</div>
					<div className={css(styleSheet.emailHeaderContainerRight)}>
						{shouldRenderActions && isFollowUp && !isPriorFollowUp && isEmailFollowUp && (
							<FollowUpContentSelector
								emailComposer={emailComposer}
								onFollowUpContentSelected={this.onFollowUpContentSelected}
							/>
						)}
						{shouldRenderActions && (
							<EmailMessageTemplateButtons
								canDelete={isPriorFollowUp ? false : this.canDelete()}
								canImpersonateOnTemplateSave={canImpersonateOnTemplateSave}
								disableEditing={isPriorFollowUp ? true : disableCampaignEdits}
								editorState={emailBodyEditorState}
								emailMessage={emailComposer?.emailMessage}
								isEditing={this.shouldShowComposer}
								isQueuedCampaign={isQueuedCampaign}
								onDeleteClicked={this.onDeleteClick}
								onEditClicked={this.startEditing}
								onPreviewClicked={this.onFinishEditingContentClicked}
								onTemplateSaved={this.onBodyTemplateChanged}
								styles={[styleSheet.emailContentActionsHeader]}
								subject={subject}
								template={emailBodyTemplate}
								templates={templates}
							/>
						)}
					</div>
				</div>
				{this.shouldShowComposer ? this.renderEmailComposer() : this.renderReadOnlyContent(shouldRenderActions)}
			</div>
		);
	}

	private onRenderHtmlNewsletterCampaignComposerLoading = () => {
		return <LoadingSpinner type='large' />;
	};

	@computed
	private get shouldShowComposer() {
		const { editing, isPriorFollowUp } = this.state;
		const { readonlyContent } = this.props;
		return editing && !readonlyContent && !isPriorFollowUp;
	}

	@computed
	private get showForApproval() {
		return this.props.emailComposer?.campaign?.status === Api.EmailSendStatus.WaitingForApproval;
	}

	@computed
	private get canEdit() {
		const { isPriorFollowUp } = this.state;
		const { readonlyContent, disableCampaignEdits } = this.props;
		return !readonlyContent && !isPriorFollowUp && !disableCampaignEdits;
	}

	@computed
	private get sendRequiresCompliance() {
		const { emailComposer } = this.props;
		const resourceEmailComposer = emailComposer as ComposeResourceEmailViewModel;

		// If we're coming from a ResourceSelector at all, we will not require compliance.
		if (resourceEmailComposer && resourceEmailComposer.resourceSelector) {
			return false;
		}

		return !this.showForApproval && accountRequiresCompliance(this.props.userSession, this.props.impersonationContext);
	}

	private onEditHtmlNewsletterTemplateClicked = () => {
		const { emailBodyTemplate } = this.state;
		const { history, emailComposer } = this.props;
		const templateId = emailBodyTemplate?.id || emailComposer.emailMessage.templateReference?.templateId;
		if (templateId) {
			// nav back to template editor
			const locationState: Models.ILocationState<any, Api.ITemplate> = {
				model: emailBodyTemplate,
			};
			history?.push({
				pathname: `/campaigns/htmlnewsletter/${templateId}`,
				state: locationState,
			});
		}
	};

	private onEmployeeSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IUser>) => {
		if (e.target) {
			e.target.setSearchQuery(Api.VmUtils.getDisplayName(e.selection));
		}
		this.setState({
			tempSelectedEmployee: e.selection,
		});
	};

	private onSelectAnEmployeeChange = (
		e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>
	) => {
		if (e.sourceEvent && e.sourceEvent.keyCode !== 13) {
			this.clearSelectedEmployee();
		}
	};

	private onSaveSelectAnEmployee = async () => {
		const { tempSelectedEmployee } = this.state;
		const { emailComposer, userSession } = this.props;

		const nextState: IState = {
			selectAnEmployeeModalIsOpen: false,
		};

		this.mTemplates.signatureTemplates.reset();
		if (tempSelectedEmployee) {
			this.mTemplates = new Api.TemplatesViewModel(userSession, { userId: tempSelectedEmployee.id });
			this.loadDefaultSignatureTemplate(true);

			if (emailComposer) {
				emailComposer.emailMessage?.setSendEmailFromUser(tempSelectedEmployee);
				emailComposer.sendAsEmployee = tempSelectedEmployee.id;
			}
			if (emailComposer.isCustomAutomation(this.action)) {
				emailComposer.sendAsEmployee = tempSelectedEmployee.id;
				emailComposer.sendOnBehalf = false;
			}
			nextState.tempSelectedEmployee = null;
		}

		this.setState(nextState, this.resetAndReloadContactsList);
	};

	private resetAndReloadContactsList = () => {
		const { emailComposer, logEvent, logApiError } = this.props;
		emailComposer?.resetRecipientsResultsList();

		if (this.resourceEmailComposer) {
			const promise = this.resourceEmailComposer.getNextBatchOfRecipients();
			if (promise) {
				logEvent('ContactsLoad');
				promise.catch((error: Api.IOperationResultNoValue) => {
					logApiError('ContactsLoad-Error', error);
				});
			}

			const approxPromise = this.resourceEmailComposer.resourceSelector
				? this.resourceEmailComposer.getEmailApproximationWithResource()
				: emailComposer.emailMessage.getEmailApproximation();

			approxPromise?.catch((error: Api.IOperationResultNoValue) => {
				logApiError('HasEmailApproximationLoad-Error', error);
			});
		}
	};

	private clearSelectedEmployee = () => {
		this.setState({
			tempSelectedEmployee: null,
		});
	};

	@computed
	private get isFromHtmlNewsletterTemplate() {
		const { emailBodyTemplate } = this.state;
		const { emailComposer } = this.props;
		const content =
			(typeof emailBodyTemplate === 'object' && emailBodyTemplate?.content) || emailComposer.emailMessage?.content;
		return content?.sourceFormat === Api.ContentSourceFormat.UnlayerHtmlNewsletter;
	}

	private canDelete() {
		const { emailBodyTemplate } = this.state;
		return (
			emailBodyTemplate?.scope === Api.TemplateScope.User || emailBodyTemplate?.scope === Api.TemplateScope.Account
		);
	}

	private onDeleteTemplate = async (shouldDelete: boolean) => {
		const { userSession } = this.props;
		const { emailBodyTemplate } = this.state;
		const nextState: IState = {
			showDeleteConfirmation: false,
		};
		if (shouldDelete) {
			if (emailBodyTemplate.creator?.id === userSession.user.id || adminRegExp.test(userSession.user.role)) {
				await this.mTemplates.delete(emailBodyTemplate);
				// TODO: handle the possible error, do not redirect and close the confirmation.
			} else {
				hideDefaultMessageTemplate(emailBodyTemplate.id);
			}
			nextState.redirectTo = '/campaigns';
		}
		this.setState(nextState);
	};

	private onDeleteClick = async () => {
		this.setState({ showDeleteConfirmation: true });
	};

	private onGoBackFromEditMode = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const { hasEdited } = this.state;
		e.preventDefault();
		if (hasEdited) {
			this.setState({ showNavigateConfirmation: true });
		} else {
			this.props.fullscreenModal?.dismissModal();
		}
	};

	private onGoBackFromPreviewMode = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const { fullscreenModal, isQueuedCampaign } = this.props;
		const { hasEdited } = this.state;
		e.preventDefault();
		if (!isQueuedCampaign && hasEdited) {
			this.setState({ showNavigateConfirmation: true });
		} else {
			fullscreenModal.history.goBack();
		}
	};

	private cleanupUnusedRecipientAttachmentsAsync = () => {
		const { emailComposer } = this.props;
		if (!emailComposer.campaign?.id) {
			const recentlyAddedAttachments = emailComposer.emailMessage
				?.getAllRecipientSpecificAttachments()
				.filter(x => Boolean((x as Api.IInProgressFileAttachment).uploadTaskId)) as Api.IInProgressFileAttachment[];
			if (recentlyAddedAttachments?.length) {
				return Promise.all(
					recentlyAddedAttachments.map(x =>
						emailComposer.emailMessage.deleteSavedEmailAttachmentAsync(x as Api.IFileAttachment)
					)
				);
			}
		}
	};

	private onNavigateConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		const { emailComposer, onShowOmitConfirmation, fullscreenModal } = this.props;
		const { hasEdited, navigateAway } = this.state;
		if (result && !canceled && result.isDestructive) {
			if (navigateAway && emailComposer?.emailMessage?.contactsToOmit?.length === 0) {
				this.cleanupUnusedRecipientAttachmentsAsync();
				navigateAway();
			} else if (emailComposer?.emailMessage?.contactsToOmit?.length > 0 && onShowOmitConfirmation) {
				onShowOmitConfirmation(undefined, hasEdited);
			} else {
				this.cleanupUnusedRecipientAttachmentsAsync();
				fullscreenModal.dismissModal();
			}
		}
		this.setState({ navigateAway: null, showNavigateConfirmation: false });
	};

	private onCloseButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { emailComposer, onShowOmitConfirmation } = this.props;
		const { hasEdited } = this.state;

		if (emailComposer?.emailMessage?.contactsToOmit?.length > 0 && onShowOmitConfirmation) {
			e.preventDefault();
			onShowOmitConfirmation(e, hasEdited);
		} else {
			if (emailComposer?.emailMessage?.contactsToOmit?.length > 0) {
				emailComposer?.emailMessage?.contactsToOmit?.clear();
				if (emailComposer?.emailMessage?.options?.followUp?.excludeContactIds) {
					emailComposer.emailMessage.options.followUp.excludeContactIds = [];
				}
			}
			this.onGoBackFromPreviewMode(e);
		}
	};

	private onHeaderBackButtonClicked = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const { emailComposer, onShowOmitConfirmation } = this.props;
		if (this.action !== 'automation' && emailComposer?.selectedCustomEmail) {
			// must prevent default explicitly otherwise the FS modal will close
			e.preventDefault();

			return this.returnToRecipientsList();
		}

		if (this.shouldShowComposer) {
			if (emailComposer?.emailMessage?.contactsToOmit?.length > 0 && onShowOmitConfirmation) {
				e.preventDefault();
				onShowOmitConfirmation(e);
			} else {
				this.onGoBackFromEditMode(e);
			}
		} else {
			if (emailComposer?.emailMessage?.contactsToOmit?.length > 0 && onShowOmitConfirmation) {
				e.preventDefault();
				onShowOmitConfirmation(e);
			} else {
				this.onGoBackFromPreviewMode(e);
			}
		}
	};

	private renderEmailComposer() {
		const { canShowTemplateSelect, emailComposer, userSession, emailContentGenerationContext } = this.props;
		const {
			bodyError,
			emailBodyEditorState,
			emailBodyTemplate,
			hideTemplateSaveButton,
			signatureTemplate,
			subject,
			subjectError,
			templates,
		} = this.state;
		const showTemplateSaveButton = !hideTemplateSaveButton && !emailComposer?.selectedCustomEmail;
		const generatingContent =
			emailContentGenerationContext?.contentGenerationStatus === AIContentGenerationStatus.Generating;
		const hideBodyTemplateSelectorButton =
			!canShowTemplateSelect &&
			!customRegExp.test(emailComposer?.resourceSelectorId) &&
			!TurningXXRexExp.test(emailComposer?.resourceSelectorId) &&
			!emailComposer?.isFollowUp;

		const hideEditSignature =
			emailComposer?.campaign?.creator && userSession.user.id !== emailComposer.campaign.creator?.id;
		return (
			<div
				className={css(
					styleSheet.bodyEditorContainer,
					showTemplateSaveButton ? styleSheet.bodyEditorContainerWithTemplateSaveButton : null
				)}
			>
				<SimpleEmailComposer
					bodyContentFooter={this.renderContentCreationTipsPopover()}
					bodyEditorState={emailBodyEditorState}
					bodyError={bodyError}
					bodyFooter={this.renderEmailComposerFooter()}
					bodyTemplate={emailBodyTemplate}
					className={css(styleSheet.bodyEditor)}
					emailComposer={emailComposer}
					emailMessage={emailComposer?.emailMessage}
					hideBodyTemplateSelectorButton={hideBodyTemplateSelectorButton}
					hideEditSignature={hideEditSignature}
					onBodyEditorStateChanged={this.onBodyEditorStateChanged}
					onBodyTemplateChanged={this.onBodyTemplateChanged}
					onRequestRemoveSavedEmailAttachment={this.onRequestRemoveSavedEmailAttachment}
					onSignatureTemplateChanged={this.onSignatureTemplateChanged}
					onSubjectChanged={this.onSubjectChanged}
					onCustomFileChanged={this.onCustomFileChanged}
					readonly={generatingContent}
					savedAttachments={
						emailComposer?.selectedCustomEmail
							? (emailComposer?.selectedCustomEmail?.attachments?.filter(
									x => !(x as Api.IInProgressFileAttachment).uploadTaskId
								) as Api.IFileAttachment[])
							: emailComposer?.emailMessage?.savedAttachments
					}
					signatureTemplate={signatureTemplate}
					subject={subject}
					subjectError={subjectError}
					templates={templates}
				/>
				{generatingContent ? (
					<LoadingSpinner type='large' className={css(styleSheet.translationgLoadingSpinner)} />
				) : null}
				{emailComposer?.selectedCustomEmailContact ? (
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.bodyEditorFooter)}>
						<button className={css(baseStyleSheet.ctaButton)} onClick={this.onFinishEditingContentClicked}>
							<span>{`${emailComposer?.selectedCustomEmail ? 'Save and go ' : 'Go '}back to all recipients`}</span>
						</button>
					</div>
				) : null}
				{this.renderKeyDateMessage()}
			</div>
		);
	}

	private onRequestRemoveSavedEmailAttachment = (savedAttachment: Api.IFileAttachment) => {
		const { emailComposer } = this.props;
		const promise = emailComposer?.emailMessage.deleteSavedEmailAttachmentAsync(savedAttachment);
		promise?.then(() => {
			if (emailComposer?.selectedCustomEmail) {
				emailComposer.selectedCustomEmail.attachments = emailComposer.selectedCustomEmail?.attachments.filter(
					x => x.id !== savedAttachment.id
				);
			}
		});
		return promise;
	};

	@computed
	private get willAddTemplateToUser() {
		const { emailComposer, userSession, initialEmailBodyTemplate } = this.props;
		const { hasEdited } = this.state;

		const emailBodyTemplateIsUndefinedOrSystemTemplate =
			!Api.TemplatesViewModel.userHasTemplateForKeyDateKind(userSession, this.keyDateKind) ||
			(initialEmailBodyTemplate &&
				emailComposer?.emailMessage?.templateReference?.templateId === initialEmailBodyTemplate.id &&
				initialEmailBodyTemplate.scope !== Api.TemplateScope.User);
		return (
			this.keyDateKind &&
			!emailComposer?.selectedCustomEmail &&
			emailBodyTemplateIsUndefinedOrSystemTemplate &&
			hasEdited
		);
	}

	private renderEmailComposerFooter() {
		const { emailComposer } = this.props;
		if (emailComposer?.selectedCustomEmail) {
			return null;
		} else {
			const message = `${FirstNamePlaceholder.symbol} is a placeholder for recipient names; actual names will be populated.`;
			return (
				<span className={css(baseStyleSheet.truncateText, styleSheet.emailComposerFooter)} title={message}>
					{message}
				</span>
			);
		}
	}

	private onRenderSurveyFollowUpRecipientsOptions = () => {
		return <EditCampaignSurveyFollowUpRecipientOptions />;
	};

	private onRenderSurveyFollowUpRecipients = () => {
		return (
			<EditCampaignSurveyFollowUpRecipients onContextFinishedEditingClicked={this.onContextFinishedEditingClicked} />
		);
	};

	private onRenderRecipientsFollowUpSelect = () => {
		const { contextFinishedEditingCtaText } = this.props;
		const { isPriorFollowUp } = this.state;
		return (
			<EditCampaignEmailFollowUpRecipients
				contextFinishedEditingCtaText={contextFinishedEditingCtaText}
				isPriorFollowUp={isPriorFollowUp}
				onContextFinishedEditingClicked={this.onContextFinishedEditingClicked}
				onFinishEditingContent={this.finishEditingContent}
			/>
		);
	};

	private renderGlobalSearch = () => {
		return (
			<Search
				onNextClick={explicitelyPreventContactFilterRequest => {
					if (explicitelyPreventContactFilterRequest) {
						this.props.emailComposer?.setAllowContactFilterTagSearchChangesOverride(false);
					}
					this.showRecipients();
				}}
			/>
		);
	};

	private onReadonlyEditorExecuteCommand = (
		e: React.ChangeEvent<any> & {
			command: string;
			type: string;
			ui?: any;
			value?: any;
		}
	) => {
		const target = e.value?.sourceEvent?.target as HTMLElement;

		if (target && e.command === Models.levPlaceholderPopoverCommand) {
			this.setState({
				levPlaceholderPopover: {
					coordinate: {
						x: target.offsetLeft + target.getBoundingClientRect().width,
						y: target.offsetTop + target.getBoundingClientRect().height / 2.0,
					},
					placeholderValue: e.value?.placeholderValue,
				},
			});
		} else if (e.command === Models.levPlaceholderPopoverCloseCommand) {
			this.onDismissLevPopover();
		}
	};

	private onDismissLevPopover = () => {
		this.setState({
			levPlaceholderPopover: null,
		});
	};

	private onRenderRecipientsSelect = () => {
		const { contextFinishedEditingCtaText, emailComposer, disableContactEdits, impersonationContext } = this.props;
		emailComposer.canCustomizeIndividualEmails = this.action !== 'automation';

		return (
			<Observer>
				{() => {
					const nextButtonText =
						contextFinishedEditingCtaText ||
						(this.action === 'automation' && this.keyDateKind !== 'Renewal'
							? `Schedule Automation${this.resourceEmailComposer?.recipients?.length > 1 ? 's' : ''}`
							: `Next: ${
									this.showForApproval
										? 'Approve Send Time'
										: this.sendRequiresCompliance
											? 'Compliance Options'
											: emailComposer.isSuggestion
												? 'Okay'
												: 'Send Options'
								}`);

					return (
						<EditCampaignRecipients onContactEditButtonClicked={this.onContactEditButtonClicked}>
							<TagEmailRecipientsList
								disableContactAdding={
									!!emailComposer?.campaign?.id || impersonationContext?.isValid || !!this.keyDateKind
								}
								disableContactEdits={
									disableContactEdits ||
									this.action === 'automation' ||
									(emailComposer?.canSendOnBehalf &&
										(emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.ContactOwner) ||
											emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.SelectedUser))) ||
									this.state.isPriorFollowUp
								}
								disableContactRemoval={this.state.isPriorFollowUp}
								emailComposer={emailComposer}
								key='recipients'
								onContactSelected={this.onContactSelected}
								onScrollerRef={this.onRecipientsListScrollerRef}
								resetOnMount={!this.mMounted}
								disableDropdown={emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.ContactOwner)}
								styles={[styleSheet.recipientsList]}
								isAutomation={this.action === 'automation'}
								ctaButtonText={nextButtonText}
								onCtaClick={this.onContextFinishedEditingClicked}
							/>
							{this.renderApprovalContextFooter()}
						</EditCampaignRecipients>
					);
				}}
			</Observer>
		);
	};

	private onRenderRecipientCustomContentContext = () => {
		const { emailComposer } = this.props;
		if (!emailComposer?.selectedCustomEmailContact) {
			return null;
		}
		return (
			<EditCampaignRecipients
				initialEdit={!(emailComposer.selectedCustomEmailContact?.emailAddresses?.length > 0)}
				onContactEditButtonClicked={this.onContactEditButtonClicked}
				styles={this.showForApproval ? [styleSheet.recipientsApproval] : undefined}
			>
				{this.renderApprovalContextFooter()}
			</EditCampaignRecipients>
		);
	};

	private renderApprovalContextFooter() {
		const { onRejectClicked, impersonationContext } = this.props;
		return (
			this.showForApproval && (
				<div className={css(styleSheet.recipientsApprovalFooter)}>
					<button className={css(baseStyleSheet.linkDestructive)} onClick={onRejectClicked}>
						<span>{`${impersonationContext?.isValid ? 'Cancel' : 'Reject'} Campaign`}</span>
					</button>
				</div>
			)
		);
	}

	private renderContentCreationTipsPopover() {
		return this.state.canShowContentCreationTips ? <EditCampaignContentCreationTips /> : null;
	}

	private renderKeyDateMessage() {
		const kind = this.keyDateKind;
		if (kind && !this.willAddTemplateToUser) {
			return null;
		}

		let kindDescription: string = null;
		switch (kind) {
			case Api.KeyDateKind.Anniversary: {
				kindDescription = 'anniversaries';
				break;
			}
			case Api.KeyDateKind.Birthday: {
				kindDescription = 'birthdays';
				break;
			}
			case Api.KeyDateKind.Renewal: {
				kindDescription = 'renewals';
				break;
			}
			default: {
				kindDescription = null;
				break;
			}
		}
		if (!kindDescription) {
			return null;
		}
		return (
			<span className={css(styleSheet.keyDateMessage)}>
				{this.willAddTemplateToUser && `We'll use this template for ${kindDescription} going forward!`}
			</span>
		);
	}

	private onStartAutomationBannerClicked = async () => {
		this.setState({
			navigateAway: () => {
				const { history } = this.props;
				// navigate to the automation flow
				history.push({
					pathname: `/automations/new`,
				});
			},
			showNavigateConfirmation: true,
		});
	};

	private get action(): 'edit' | 'automation' {
		return this.state.selectedAutomationTemplate ? 'automation' : 'edit';
	}

	private onRecipientsListScrollerRef = (ref?: HTMLElement) => {
		this.mRecipientListScrollerRef = ref;
	};

	@action
	private onContactSelected = (contact: Api.ContactViewModel) => {
		const { emailComposer, history } = this.props;
		const { subject: storedSubject, emailBodyEditorState: storedEmailBodyEditorState } = this.state;

		const nextState: IState = {
			bodyError: null,
			editing: true,
			subjectError: null,
		};
		if (
			this.sendingOnBehalf &&
			!emailComposer.campaign?.categories?.includes(Api.EmailCategories.ResourceSelector) &&
			!this.resourceEmailComposer?.resourceSelector
		) {
			// only show selected contact info in context container instead of
			// allowing edits to individual content
			emailComposer.selectedCustomEmailContact = contact;
		} else {
			if (this.action === 'automation') {
				emailComposer?.selectCustomEmailForContact(contact, true);
			} else {
				nextState.selectedCustomEmailHasCustomContent =
					!!emailComposer?.emailMessage?.getCustomMessageForContact(contact)?.content;
				emailComposer?.emailMessage?.contactsToOmit?.removeItems([contact]);
				// save the base content and subject
				if (emailComposer?.emailMessage) {
					emailComposer.emailMessage.content = storedEmailBodyEditorState?.getRawRichTextContent();
					emailComposer.emailMessage.subject = storedSubject;
				}

				// try to load customized email content, if any
				const selectedCustomEmail = emailComposer?.selectCustomEmailForContact(contact, true);
				nextState.emailBodyEditorState = convertRawRichTextContentStateToRichContentEditorState(
					selectedCustomEmail?.content
						? selectedCustomEmail.content
						: storedEmailBodyEditorState?.getRawRichTextContent()
				);
				nextState.subject = selectedCustomEmail.subject || storedSubject;
			}
		}

		// save scroll offset
		if (this.mRecipientListScrollerRef) {
			nextState.lastContactListScrollOffset = this.mRecipientListScrollerRef.scrollTop;
		}

		this.setState(nextState, () => {
			history?.push({
				pathname: `${history.location.pathname}/${contact.id}`,
				state: history.location.state,
			});
		});
	};

	private onContactEditButtonClicked = (contact: Api.ContactViewModel) => {
		this.setState({ previousContactFirstName: contact.firstName });
	};

	@action
	private showRecipients = () => {
		const { history, match, emailComposer, userSession } = this.props;

		if (
			emailComposer?.emailMessage &&
			!emailComposer.emailMessage?.contactsFilterRequest &&
			emailComposer.emailMessage?.contactsToAdd?.length === 0
		) {
			emailComposer.emailMessage.contactsFilterRequest = {
				contactFilterRequest: applyDefaultExcludedTagsToContactsFilterRequest(emailComposer, {
					criteria: [
						{
							property: Api.ContactFilterCriteriaProperty.OwnedBy,
							value: userSession?.user.id,
						},
					],
				}),
			};
		}

		history?.push(`${match.url}/recipients`);
	};

	private startEditing = () => {
		const { emailComposer } = this.props;
		emailComposer.editModeIsActive = true;
		this.setState({
			editing: true,
		});
	};

	private onContextFinishedEditingClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { history, match, onContextFinishedEditingClicked, emailComposer } = this.props;
		const { selectedAutomationTemplate } = this.state;

		if (this.action === 'automation') {
			emailComposer.selectedAutomationTemplate = selectedAutomationTemplate;
			if (selectedAutomationTemplate) {
				history?.push(`${match.url}/send-options`);
			}
		} else {
			emailComposer.selectedAutomationTemplate = null;
			this.finishEditingContent();
			if (!this.validateBaseEmailBeforeSending()) {
				return;
			}
			onContextFinishedEditingClicked?.(e);
			if (!e.isDefaultPrevented() && !this.showForApproval) {
				if (this.sendRequiresCompliance) {
					history?.push(`${match.url}/compliance-options`);
				} else {
					history?.push(`${match.url}/send-options`);
				}
			}
		}
	};

	private onFinishEditingContentClicked = (e: React.MouseEvent<HTMLElement>) => {
		this.finishEditingContent();
		this.props.onContentFinishedEditingClicked?.(e);
	};

	public validateBaseEmailBeforeSending = () => {
		const { emailBodyEditorState, subject, emailBodyTemplate } = this.state;
		const { emailComposer, errorMessages } = this.props;
		if (!subject) {
			this.showEmailError(ComposeEmailError.NoSubject);
			return false;
		}

		const content = emailBodyTemplate?.content || emailComposer.emailMessage.content;
		if (content?.sourceFormat !== Api.ContentSourceFormat.UnlayerHtmlNewsletter) {
			if (!emailBodyEditorState || !emailBodyEditorState.hasContent()) {
				this.showEmailError(ComposeEmailError.NoContent);
				return false;
			}

			const rawContent = emailBodyEditorState.getRawRichTextContent();
			if (!Token.isFirstNameTokenFormattedCorrectlyLoose(rawContent)) {
				errorMessages.push({
					messages: [
						`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
					],
				});
				return false;
			}
		}

		/**
		 * @todo - Move this to VM?
		 *
		 * @todo - Add logic for survey followup checks
		 */

		if (emailComposer?.isFollowUp) {
			if (
				emailComposer.followUpSource instanceof Api.CampaignEmailViewModel &&
				!emailComposer?.emailMessage?.options?.followUp?.statuses
			) {
				errorMessages?.push({
					messages: ['Please select at least one recipient group.'],
				});
				return false;
			}
		} else if (emailComposer?.recipients?.length === 0) {
			errorMessages?.push({
				messages: ['Please select at least one recipient.'],
			});
			return false;
		}

		const emailAttachments = emailComposer?.emailMessage?.attachments;
		const savedAttachments = emailComposer?.emailMessage?.savedAttachments?.filter(x => !x.embedded);
		const hasExceededMaxByteCount =
			emailAttachments?.hasExceededMaxByteCount ||
			emailAttachments?.byteCount + (savedAttachments || []).reduce((count, x) => count + x.fileSize, 0) >
				emailAttachments?.maxByteCount;
		if (hasExceededMaxByteCount) {
			errorMessages?.push({
				messages: [
					`You've exceeded the attachment size limit of ${getFileSizeStringValue(emailAttachments.maxByteCount)}.`,
				],
			});
			return false;
		}

		return true;
	};

	public finishEditingContent = () => {
		const { emailComposer } = this.props;
		const { subject, emailBodyEditorState, hasEdited, selectedCustomEmailHasCustomContent } = this.state;
		if (emailComposer?.selectedCustomEmailContact) {
			if (!this.sendingOnBehalf || this.resourceEmailComposer?.resourceSelector) {
				if (emailComposer?.selectedCustomEmail && selectedCustomEmailHasCustomContent && emailBodyEditorState) {
					this.saveToEmail(emailComposer.selectedCustomEmail, emailComposer.selectedCustomEmailContact);
				}
			}
			this.returnToRecipientsList();
			return;
		}
		runInAction(() => {
			if (emailComposer?.emailMessage) {
				emailComposer.emailMessage.subject = subject;
				emailComposer.emailMessage.content = emailBodyEditorState?.getRawRichTextContent();

				if (this.state.emailBodyTemplate?.schedule?.expirationDate != null) {
					emailComposer.emailMessage.options.scheduledSend.expirationDate =
						this.state.emailBodyTemplate?.schedule?.expirationDate;
				}

				if (emailComposer.emailMessage.templateReference) {
					emailComposer.emailMessage.templateReference.isCustomized = hasEdited;
				}
			}
			emailComposer.editModeIsActive = false;
			this.setState({
				editing: false,
			});
		});
	};

	private onSubjectChanged = (subject: string) => {
		const { emailComposer } = this.props;
		const { selectedCustomEmailHasCustomContent } = this.state;
		const nextState: IState = {
			bodyError: null,
			subject,
			subjectError: null,
		};
		if (emailComposer?.selectedCustomEmail) {
			if (!selectedCustomEmailHasCustomContent && emailComposer.selectedCustomEmail.subject !== subject) {
				// user changed subject... this is now a custom email message
				nextState.selectedCustomEmailHasCustomContent = true;
			}
		} else {
			if (emailComposer?.emailMessage) {
				emailComposer.emailMessage.subject = subject;
			}
			nextState.hasEdited = true;
		}

		this.setState(nextState);
	};

	private onCustomFileChanged = () => {
		const { emailComposer } = this.props;
		const nextState: IState = {
			bodyError: null,
			subjectError: null,
		};
		nextState.selectedCustomEmailHasCustomContent = true;
		nextState.hasEdited = true;

		emailComposer.emailMessage?.setCustomMessageForContact(emailComposer.selectedCustomEmailContact, {
			...emailComposer.selectedCustomEmail,
			attachments:
				emailComposer.selectedCustomEmail?.attachments?.length > 0
					? emailComposer.selectedCustomEmail?.attachments
					: null,
		});

		this.setState(nextState);
	};

	private onBodyEditorStateChanged = (emailBodyEditorState: Api.IRichContentEditorState) => {
		const { emailComposer } = this.props;
		const { hideTemplateSaveButton, selectedCustomEmailHasCustomContent, canShowContentCreationTips } = this.state;
		const nextState: IState = {
			bodyError: null,
			emailBodyEditorState,
			subjectError: null,
		};
		if (emailComposer?.selectedCustomEmail && !selectedCustomEmailHasCustomContent) {
			// flag it as having custom content... when the user hits the back button, we'll save the editor state as raw content to the vm... no need to do it now
			nextState.selectedCustomEmailHasCustomContent =
				emailBodyEditorState.getRawRichTextContent() !== this.state.emailBodyEditorState.getRawRichTextContent();
		}
		// note: once the text has changed (not just selection), we don't need to compare plain text
		if (
			canShowContentCreationTips ||
			(hideTemplateSaveButton && emailBodyEditorState && this.state.emailBodyEditorState)
		) {
			const hide =
				emailBodyEditorState.getRawRichTextContent() === this.state.emailBodyEditorState.getRawRichTextContent();
			nextState.hideTemplateSaveButton = hide;
			nextState.canShowContentCreationTips = hide;
		}
		nextState.hasEdited = true;

		this.setState(nextState);
	};

	private returnToRecipientsList = () => {
		const { emailComposer, history } = this.props;
		const { lastContactListScrollOffset } = this.state;

		const nextState: IState = {
			selectedCustomEmailHasCustomContent: false,
		};

		if (this.sendingOnBehalf && !this.resourceEmailComposer?.resourceSelector) {
			// just clear the selected contact
			if (emailComposer) {
				emailComposer.selectedCustomEmailContact = null;
			}
		} else {
			// restore the base content and subject
			nextState.emailBodyEditorState =
				convertRawRichTextContentStateToRichContentEditorState(emailComposer?.emailMessage?.content) ||
				getDefaultBulkMessagingBodyEditorState();
			nextState.subject = emailComposer?.emailMessage?.subject;

			emailComposer?.selectCustomEmailForContact(null);
		}

		this.setState(nextState, () => {
			history?.goBack();
			if (
				lastContactListScrollOffset !== null &&
				lastContactListScrollOffset !== undefined &&
				this.mRecipientListScrollerRef
			) {
				this.mRecipientListScrollerRef.scrollTop = lastContactListScrollOffset;
				this.setState({
					lastContactListScrollOffset: null,
				});
			}
		});
	};

	@action
	private saveToEmail = (email: Api.IEmailMessageComposeContact, recipient?: Api.ContactViewModel) => {
		const { emailComposer } = this.props;
		const { emailBodyEditorState, subject } = this.state;
		if (email) {
			email.content = emailBodyEditorState?.getRawRichTextContent();
			email.subject = subject;
			emailComposer?.emailMessage?.setCustomMessageForContact(recipient, email);
		}
	};

	private onBodyTemplateChanged = (template: Api.ITemplate) => {
		const { emailComposer } = this.props;
		if (template) {
			const { emailMessage } = emailComposer;
			emailMessage.subject = template.subject ?? emailMessage.subject;
			emailMessage.setSavedAttachments(template.attachments);
			this.setState({
				emailBodyEditorState: convertRawRichTextContentStateToRichContentEditorState(template.content),
				emailBodyTemplate: template,
				subject: emailMessage.subject,
				translatedEmailBodyTemplate: null,
				hasEdited: false,
			});
		}

		if (this.keyDateKind) {
			emailComposer?.loadAlternateKeyDateTemplates()?.catch((err: Api.IOperationResultNoValue) => {
				const { logApiError } = this.props;
				logApiError('LoadAlternateKeyDateTemplates', err);
			});
		}
	};

	private onSignatureTemplateChanged = (signatureTemplate: Api.ITemplate) => {
		const { emailComposer } = this.props;
		if (emailComposer?.emailMessage) {
			emailComposer.emailMessage.signatureTemplate = signatureTemplate;
		}
		this.setState({
			signatureTemplate,
		});
	};

	private loadDefaultSignatureTemplate = (resetToDefault?: boolean) => {
		const { logApiError, logEvent, emailComposer } = this.props;
		if (this.mTemplates?.impersonationContext && !this.mTemplates.impersonationContext.user?.id) {
			// Impersonating, but we don't have a user. Cannot load a signature template
			return Promise.resolve<Api.ITemplate[]>([]);
		}
		this.mTemplates.signatureTemplates.reset();
		const promise = this.mTemplates.signatureTemplates.getNext();

		if (promise) {
			logEvent('SignatureTemplatesLoad');
			promise
				.then(() => {
					if (emailComposer?.emailMessage?.signatureTemplate && !resetToDefault) {
						if (
							emailComposer?.campaign?.creator?.id &&
							emailComposer?.emailMessage?.signatureTemplate?.creator?.id &&
							emailComposer.campaign.creator.id !== emailComposer.emailMessage.signatureTemplate.creator.id
						) {
							// if looking at existing campaign and the creator of the signature template !== the creator of the capaign, don't show the signature.
							this.setState({ signatureTemplate: null });
							return;
						}
						this.setState({
							signatureTemplate: emailComposer.emailMessage.signatureTemplate,
						});
					} else {
						let signatureTemplate = this.mTemplates.signatureTemplates.fetchResults.find(x => !!x.defaultTemplate);
						if (
							emailComposer?.campaign?.creator?.id &&
							signatureTemplate?.creator?.id &&
							emailComposer.campaign.creator.id !== signatureTemplate.creator.id
						) {
							// if looking at existing campaign and the creator of the defualt signature template !== the creator of the capaign, don't show the signature.
							signatureTemplate = null;
						}

						if (signatureTemplate) {
							this.setState({
								signatureTemplate,
							});
							if (
								emailComposer?.emailMessage &&
								(!emailComposer.emailMessage.signatureTemplate || !emailComposer?.campaign)
							) {
								emailComposer.emailMessage.signatureTemplate = signatureTemplate;
							}
						} else {
							this.setState({ signatureTemplate: null });
						}
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('SignatureTemplatesLoad-Error', error);
				});
			return promise;
		}
	};

	private async loadUnsubscribeSignatureTemplate() {
		const { logApiError, logEvent } = this.props;
		try {
			logEvent('LoadUnsubscribeTemplate');
			await this.mUnsubscribeTemplate.loadUserTemplate();
			if (this.mUnsubscribeTemplate.userTemplate) {
				this.setState({
					unsubscribeSignatureTemplate: this.mUnsubscribeTemplate.userTemplate,
				});
			} else {
				await this.mUnsubscribeTemplate.loadAccountTemplate();
				this.setState({
					unsubscribeSignatureTemplate: this.mUnsubscribeTemplate.accountTemplate,
				});
			}
		} catch (error) {
			logApiError('LoadUnsubscribeTemplate-Error', error);
		}
	}

	private loadAutomationTemplates = async () => {
		const { logApiError, logEvent, userSession } = this.props;
		if (
			this.mAutomationTemplates &&
			this.resourceEmailComposer?.resourceSelector &&
			userSession.account.features?.automation?.enabled
		) {
			try {
				logEvent('AutomationTemplatesLoad', {
					resourceSelectorId: this.resourceEmailComposer.resourceSelector,
				});
				const templates = await this.mAutomationTemplates.getTemplatesByResourceId(
					this.resourceEmailComposer.resourceSelector
				);
				this.setState({
					automationTemplatesForResourceSelectorId: templates,
				});
			} catch (error) {
				logApiError('AutomationTemplatesLoad-Error', error);
			}
		}
	};

	private loadInitialBodyTemplate = () => {
		const { logApiError, logEvent, errorMessages, initialEmailBodyTemplate, emailComposer } = this.props;
		const { automationTemplatesForResourceSelectorId } = this.state;

		// becomes the selected template in the UI if it exists
		const firstAutomationTemplate = automationTemplatesForResourceSelectorId?.[0];

		if (initialEmailBodyTemplate?.id && !initialEmailBodyTemplate?.content) {
			logEvent('EmailBodyTemplateLoad');
			this.mTemplates
				.getById(initialEmailBodyTemplate.id)
				.then(emailBodyTemplate => {
					this.setState(
						{
							emailBodyTemplate,
							selectedAutomationTemplate: firstAutomationTemplate,
						},
						() => {
							if (emailBodyTemplate.templateType === Api.TemplateType.HtmlNewsletter) {
								emailComposer.canCustomizeIndividualEmails = false;
							}
						}
					);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					logApiError('EmailBodyTemplateLoad-Error', error);
					errorMessages.pushApiError(error);
				});
		} else {
			this.setState({
				selectedAutomationTemplate: firstAutomationTemplate,
			});
		}
	};

	private showEmailError = (error: ComposeEmailError) => {
		let hasError = false;
		const nextState: IState = {
			bodyError: null,
			subjectError: null,
		};

		if (error !== ComposeEmailError.NoError) {
			if (error === ComposeEmailError.NoSubject) {
				nextState.subjectError = 'A subject is required';
			}
			if (error === ComposeEmailError.NoContent) {
				nextState.bodyError = 'Message content is required';
			}

			hasError = true;
			nextState.editing = true;
		}

		if (hasError) {
			this.setState(nextState);
		}
	};
}

const EditCampaignAsObserver = observer(_EditCampaign);
const EditCampaignWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey,
	Models.ImpersonationContextKey,
	FullScreenModalViewModelKey
)(EditCampaignAsObserver);
const EditCampaignWithRouter = withRouter(EditCampaignWithContext);
const EditCampaignWithConnectionTypes = withConnectionTypes(EditCampaignWithRouter, { refetchOnWindowFocus: false });
export const EditCampaign = withEmailContentGenerationContextProvider(
	withEventLogging(EditCampaignWithConnectionTypes, 'EditCampaign')
);
