import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../../../models';
import { useEmailComposerContext } from '../../../../../../models/Email';
import { useUserSession } from '../../../../../../models/hooks/appStateHooks';
import {
	EditSendOptionsCompleteBy,
	SendOptionCompleteByOption,
} from '../../../../templates/EditSendOptions/presentation';
import { styleSheet } from './styles';

export const MultiEmailSchedulerSendOptions = inject(ImpersonationContextKey)(
	observer(function MultiEmailSchedulerSendOptions({ impersonationContext }: IImpersonationContextComponentProps) {
		const { emailComposer } = useEmailComposerContext();
		const userSession = useUserSession();

		const sendEstimate = React.useMemo(() => {
			let estTotal = emailComposer.emailMessage.contactEmailApproximation?.hasEmail;
			if (!estTotal && emailComposer.emailMessage.contactsToAdd?.length > 0) {
				estTotal = emailComposer.emailMessage.contactsToAdd.length;
			}
			return !isNaN(estTotal) ? estTotal : 0;
		}, [
			emailComposer.emailMessage.contactEmailApproximation?.hasEmail,
			emailComposer.emailMessage.contactsToAdd.length,
		]);

		const maxEmailsPerDay = React.useMemo(() => {
			const isImpersonating = impersonationContext?.isValid && !!impersonationContext?.account;
			const account = isImpersonating ? impersonationContext.account : userSession.account.toJs();
			return account
				? account.emailProviderConfigurations.reduce<number>((currentValue, x) => {
						const dailyLimit = Api.VmUtils.getEmailSendLimitFromProviderConfig(x, 1440);
						if (!dailyLimit) {
							if (!currentValue) {
								return 100;
							}
							return currentValue;
						}
						if (!currentValue) {
							return dailyLimit.count;
						}
						return Math.min(currentValue, dailyLimit.count);
					}, null)
				: 100;
		}, [userSession, impersonationContext]);

		const onSendOptionChanged = (option: SendOptionCompleteByOption) => {
			if (option === SendOptionCompleteByOption.SPREAD) {
				emailComposer.emailMessage.setMinimumDurationInDays(1);
				return;
			}
			emailComposer.emailMessage.setMinimumDurationInDays(null);
		};
		const onSendOptionCompleteByChange = (minimumDays?: number) => {
			emailComposer.emailMessage.setMinimumDurationInDays(minimumDays);
		};

		if (!sendEstimate) {
			return null;
		}

		return (
			<section className={css(styleSheet.sendOptionsContainer)}>
				<h4>Send Options</h4>
				<h6>How would you like your content delivered when it&apos;s time to send?</h6>
				<EditSendOptionsCompleteBy
					maxEmailsPerDay={maxEmailsPerDay}
					onOptionChange={onSendOptionChanged}
					onSendOptionCompleteByChange={onSendOptionCompleteByChange}
					recipients={sendEstimate}
					styles={[styleSheet.sendOptionsCompleteBy]}
				/>
			</section>
		);
	})
);
