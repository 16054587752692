import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useEventLogging } from '../../../../models/Logging';
import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { Button } from '../../../components/Button';
import { ScheduleSendIcon } from '../../../components/svgs/icons/ScheduleSendIcon';
import { SuccessCheckIcon } from '../../../components/svgs/icons/SuccessCheckIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onClose: () => void;
	post: Api.SocialMediaPostViewModel;
	styles?: StyleDeclarationValue[];
}

export const SocialMediaPostScheduleComplete: React.FC<IProps> = ({ className, onClose, styles = [], post }) => {
	const { logInput } = useEventLogging('SocialMediaPostScheduleComplete');
	const onReportingLinkClicked = React.useCallback(() => {
		logInput('ReportingLink', 'Click');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSendNow = post?.dueMoment && moment(post.dueMoment).isSame(moment(), 'hour');
	return (
		<div className={`${css(styleSheet.container, ...styles)} social-media-post-schedule-complete ${className || ''}`}>
			<figure className={css(styleSheet.iconContainer)}>
				<ScheduleSendIcon />
				<SuccessCheckIcon className={css(styleSheet.successIcon)} />
			</figure>
			<p className={css(styleSheet.titleMessage)}>
				{isSendNow ? (
					<span>Your post will be delivered in the next 5 minutes.</span>
				) : (
					<span>
						<>
							{!post.dueMoment
								? 'Your post has been saved as a draft.'
								: `Your post will be delivered on ${getDefaultDateStringValue(post.dueMoment)}`}{' '}
						</>
					</span>
				)}
			</p>
			<p className={css(styleSheet.detailMessage)}>
				You can view the queued up post in &nbsp;
				<NavLink
					className={css(baseStyleSheet.brandLink, styleSheet.link)}
					onClick={onReportingLinkClicked}
					to='/reporting/social-media-posts'
				>
					<span>Social Media Reports</span>
				</NavLink>
				.
			</p>
			<Button className={css(styleSheet.closeButton)} label='Close Window' onClick={onClose} />
		</div>
	);
};
