import * as Api from '@ViewModels';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { CampaignDateTimePicker } from '../../../components/campaigns/CampaignDateTimePicker';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { Popover, PopoverType } from '../../../components/Popover';
import { CheckmarkIcon } from '../../../components/svgs/icons/CheckmarkIcon';
import { EditItemPenIcon } from '../../../components/svgs/icons/EditItemPenIcon';
import { TrashIcon } from '../../../components/svgs/icons/TrashIcon';
import { brandPrimary, nameCircles, success } from '../../../styles/colors';
import { bs } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addDateButton: {
		color: brandPrimary,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		flex: '1 1 0%',
	},
	recommendedDateContainer: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 12,
		gap: 4,
	},
	templateTitle: {
		fontWeight: 600,
		fontSize: 12,
	},
	headerRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	MultiCampaignTemplateItem: {
		display: 'flex',
		alignItems: 'center',
		gap: 20,
	},
});

export function MultiCampaignTemplateItem({
	name,
	scheduleDate,
	expirationDate,
	onUpdateDate,
	onRemove,
	children,
	showSuccess,
	successFillColor = success,
	sendFrom,
	sendFromUserId,
}: React.PropsWithChildren<{
	name: string;
	onRemove: () => void;
	onUpdateDate: (newDate: Date) => void;
	scheduleDate?: Date;
	expirationDate?: Date;
	styles?: StyleDeclarationValue[];
	showSuccess?: boolean;
	successFillColor?: string;
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId?: string;
}>) {
	const [isShowingCalendar, setIsShowingCalendar] = React.useState(false);
	const [isRemoving, setIsRemoving] = React.useState(false);

	const onEditDate = () => {
		setIsShowingCalendar(true);
	};

	const onDateSave = (newValue: Date) => {
		onUpdateDate(newValue);
		setIsShowingCalendar(false);
	};

	const onDateCancel = () => {
		setIsShowingCalendar(false);
	};

	const onDeleteConfirmationFinished = (shouldDelete: boolean) => {
		if (shouldDelete) {
			onRemove();
		}
		setIsRemoving(false);
	};

	const showRemoveConfirmation = () => {
		setIsRemoving(true);
	};

	return (
		<div className={css(styleSheet.MultiCampaignTemplateItem)}>
			<div className={css(styleSheet.contentContainer)}>
				<header className={css(styleSheet.headerRow)}>
					<div className={css(styleSheet.templateTitle)}>{name}</div>
					<div className={css(styleSheet.recommendedDateContainer)}>
						{scheduleDate ? (
							<>
								<span className={css(bs.textTitles)}>Send date: </span>
								<time
									className={css(bs.textTitles, bs.fontBold)}
									dateTime={moment(scheduleDate).format('dddd MMMM Do YYYY hh:mm a')}
								>
									{moment(scheduleDate).format('dddd MMMM Do hh:mm a')}
								</time>
							</>
						) : null}
						{!showSuccess ? (
							<Popover
								anchor={
									!scheduleDate ? (
										<button type='button' className={css(styleSheet.addDateButton)} onClick={onEditDate}>
											+ Add Send Date
										</button>
									) : (
										<button onClick={onEditDate}>
											<EditItemPenIcon fillColor={success} />
										</button>
									)
								}
								dismissOnClickOutside
								isOpen={isShowingCalendar}
								onRequestClose={onDateCancel}
								preferredPlacement='left'
								type={PopoverType.white}
							>
								<CampaignDateTimePicker
									initialValue={scheduleDate || moment().toDate()}
									onSave={onDateSave}
									onCancel={onDateCancel}
									expirationDate={expirationDate}
									sendFrom={sendFrom}
									sendFromUserId={sendFromUserId}
								/>
							</Popover>
						) : null}
					</div>
				</header>
				{children}
			</div>
			{showSuccess ? (
				<CheckmarkIcon type='bold' fillColor={successFillColor ?? success} />
			) : onRemove ? (
				<button onClick={showRemoveConfirmation}>
					<TrashIcon fillColor={nameCircles} />
				</button>
			) : null}
			{isRemoving ? (
				<DeleteConfirmation
					bodyText={`Are you sure you'd like to remove ${name} from selected suggestions?`}
					destructiveButtonText='Remove'
					isOpen={isRemoving}
					onFinish={onDeleteConfirmationFinished}
				/>
			) : null}
		</div>
	);
}
